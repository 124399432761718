import { toast } from 'react-toastify';

export const returnBtnVal = (privacy_status, followed_status, is_follow_action = false) => {
  if (privacy_status == '0' || privacy_status == '2') {
    if (followed_status == 3) {
      is_follow_action && toast.success('You are now following the channel');

      return 'Unfollow';
    } else {
      is_follow_action && toast.warn('You have unfollowed the channel');
      return 'Follow';
    }
  } else {
    if (followed_status == 1) {
      is_follow_action &&
        toast.success(
          'Your request has been recieved. Please wait till we process your follow request'
        );
      return 'Access Requested';
    } else if (followed_status == 3) {
      return 'Unfollow';
    } else {
      is_follow_action && toast.warn('You have unfollowed the channel');
      return 'Request Access';
    }
  }
};
export const removeHtmlTags = (strInputCode) => {
  if (strInputCode) {
    let cleanText = '';
    cleanText = strInputCode.replace(/<[^<>]+>|&[^&;]+;/g, '');
    return cleanText;
  } else {
    return '';
  }
};
