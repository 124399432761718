import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearOpixData } from '../Store/actions/tracking.action';
import { removeSessionStorage } from '../common/common';
import { useParams } from 'react-router-dom';

function OpixWrapper({ children }) {
  const dispatch = useDispatch();
  const opixTrackingDataRef = useRef([]);
  const opixTrackingData = useSelector((state) => state.tracking.opix_data);
  let visibilityChange = typeof document.hidden
    ? 'visibilitychange'
    : typeof document.msHidden
    ? 'msvisibilitychange'
    : typeof document.webkitHidden
    ? 'webkitvisibilitychange'
    : 'visibilitychange';

  const { id } = useParams();

  const current_menu = useSelector((state) => state.common.current_menu);
  function getScrollPercentage() {
    const scrollTop = window.scrollY;
    const documentHeight = document.documentElement.scrollHeight;
    const windowHeight = window.innerHeight;

    // Calculate the total scrollable area
    const scrollableHeight = documentHeight - windowHeight;

    // Prevent division by zero
    if (scrollableHeight === 0) return 0;

    // Calculate the scroll percentage (0 to 1)
    const scrollPercent = scrollTop / scrollableHeight;

    // Round and return the percentage as a value between 0 and 100
    return Math.round(scrollPercent * 100);
  }

  function sendOpixReportData() {
    const opix = window && window['opix'];
    const payload = [
      ...opixTrackingDataRef.current,
      {
        content_id: id || 0,
        content_type: 'PAGE_LIVE',
        page: current_menu || 'login',
        pageId: id || 0,
        scroll_percentage: getScrollPercentage()
      }
    ];

    if (payload?.length > 0) {
      opix('event', ``, payload);
      dispatch(clearOpixData());
      opixTrackingDataRef.current = [];
      removeSessionStorage('analytics_sent');
    }
  }

  useEffect(() => {
    opixTrackingDataRef.current = opixTrackingData;
    if (opixTrackingDataRef.current.length > 5) {
      sendOpixReportData();
    }
  }, [opixTrackingData]);

  useEffect(() => {
    const reportingInterVal = setInterval(() => {
      sendOpixReportData();
    }, parseInt(process.env.REACT_APP_REPORTING_INTERVAL));

    return () => {
      clearInterval(reportingInterVal);
    };
  }, []);

  useEffect(() => {
    document.addEventListener(visibilityChange, sendOpixReportData);
    document.addEventListener('pagehide', sendOpixReportData);
    return () => {
      setTimeout(() => {
        sendOpixReportData();
      }, 200);

      document.removeEventListener(visibilityChange, sendOpixReportData);
      document.removeEventListener('pagehide', sendOpixReportData);
    };
  }, []);

  return children;
}

export default OpixWrapper;
