import React, { memo } from 'react';
import { RouterProvider } from 'react-router-dom';
import { useDefaultRouter } from './defaultRouter';

function IndexRouter() {
  const router = useDefaultRouter();

  const onRefresh = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
        window.location.reload();
      }, 10);
    });
  };

  return (
    <>
      <RouterProvider router={router} />
      {/* if app build  */}
      {/* {isMobileApp() ? (
        <PullToRefresh
          pullDownContent={<PullDownContent />}
          releaseContent={<ReleaseContent />}
          refreshContent={<RefreshContent />}
          pullDownThreshold={200}
          onRefresh={onRefresh}
          triggerHeight={50}
          backgroundColor="#fff"
          startInvisible={true}
        >
          <RouterProvider router={router} />
        </PullToRefresh>
      ) : (
        <RouterProvider router={router} />
      )} */}
    </>
  );
}

export default memo(IndexRouter);
