import React from 'react';
import { routeNames } from '../constants';
import Loadable from '../Loadable';

const ClinicalVideosShare = Loadable({
  loader: () => import('../../../src/pages/share/ClinicalVideosShare')
});

const GrShare = Loadable({
  loader: () => import('../../../src/pages/share/GrShare')
});

const MedwikiShare = Loadable({
  loader: () => import('../../../src/pages/share/MedwikiShare')
});

const TrainingShare = Loadable({
  loader: () => import('../../../src/pages/share/TrainingShare')
});

const PollShare = Loadable({
  loader: () => import('../../../src/pages/share/PollShare')
});

const QuizShare = Loadable({
  loader: () => import('../../../src/pages/share/QuizShare')
});

const SessionShare = Loadable({
  loader: () => import('../../../src/pages/share/SessionShare')
});

const ChannelShare = Loadable({
  loader: () => import('../../../src/pages/share/channelShare')
});

const EbookShare = Loadable({
  loader: () => import('../../../src/pages/share/ebookShare')
});
const ElectionShare = Loadable({
  loader: () => import('../../../src/pages/share/ElectionShare')
});

const DnrShare = Loadable({
  loader: () => import('../../../src/pages/share/DnrShare')
});

const DoctubeShare = Loadable({
  loader: () => import('../../../src/pages/share/DoctubeShare')
});

const ProfileShare = Loadable({
  loader: () => import('../../../src/pages/share/profileShare')
});

const DifferentialDiagnosisShare = Loadable({
  loader: () => import('../../../src/pages/share/DiagnosisShare')
});
const DDIShare = Loadable({
  loader: () => import('../../../src/pages/share/DDIShare')
});
const Ddx = Loadable({
  loader: () => import('../../../src/pages/askClirnet/DiagnosisHelp')
});

const ObservershipShare = Loadable({
  loader: () => import('../../../src/pages/share/ObservershipShare')
});
const MentorShare = Loadable({
  loader: () => import('../../../src/pages/share/MentorShare')
});
const ClaimMentorship = Loadable({
  loader: () => import('../../../src/pages/share/ClaimMentorship')
});
export const shareRoutes = [
  {
    path: '/share/gr/:id',
    component: <GrShare />,
    subPath: ['', ':utm'],
    ShareRoute: true,
    redirectTypeRoute: routeNames.grandRound.detail
  },
  {
    path: '/share/training/:id',
    component: <TrainingShare />,
    subPath: [':utm', ''],
    ShareRoute: true,
    redirectTypeRoute: routeNames.course.cover
  },
  {
    path: '/share/differential_diagnosis/:id',
    component: <DifferentialDiagnosisShare />,
    subPath: ['', ':utm'],
    redirectTypeRoute: routeNames.differentialDiagnosis.landing,
    ShareRoute: true
  },
  {
    path: '/share/ddi/:id',
    component: <DDIShare />,
    subPath: ['', ':utm'],
    redirectTypeRoute: routeNames.ddi.landing
  },
  {
    path: '/share/openddx/:id',
    component: <Ddx />,
    subPath: ['', ':utm'],
    redirectTypeRoute: routeNames.openddx.landing,
    ShareRoute: true
  },
  {
    path: '/share/medwiki/:id',
    component: <MedwikiShare />,
    subPath: ['', ':utm'],
    redirectTypeRoute: routeNames.medwiki.detail,
    ShareRoute: true
  },
  {
    path: '/share/feed/:id',
    component: <MedwikiShare />,
    subPath: ['', ':utm'],
    redirectTypeRoute: 'MedwikiDetails',
    ShareRoute: true
  },
  {
    path: '/share/Poll/:id',
    redirectTypeRoute: routeNames.spq.poll,
    ShareRoute: true,
    component: <PollShare />,
    subPath: ['', ':utm']
  },
  {
    path: '/share/survey/:id',
    redirectTypeRoute: routeNames.spq.detail,
    ShareRoute: true,
    component: <QuizShare />,
    subPath: ['', ':utm']
  },
  {
    path: '/share/session/:id',
    redirectTypeRoute: routeNames.sessions.detail,
    ShareRoute: true,
    component: <SessionShare />,
    subPath: ['', ':utm']
  },
  {
    path: '/share/doctorVoice/:id',
    redirectTypeRoute: routeNames.dashboard.landing,
    ShareRoute: true,
    component: <PollShare />,
    subPath: ['', ':utm']
  },
  {
    path: '/share/channel/:id',
    redirectTypeRoute: routeNames.channel.detail,
    ShareRoute: true,
    component: <ChannelShare />,
    subPath: ['', ':utm']
  },
  {
    path: '/share/epub/:id',
    redirectTypeRoute: routeNames.epaper.detail,
    ShareRoute: true,
    component: <EbookShare />,
    subPath: ['', ':utm']
  },
  {
    path: '/share/election/:election_id',
    redirectTypeRoute: routeNames.election.landing,
    ShareRoute: true,
    component: <ElectionShare />,
    subPath: ['', ':utm']
  },
  {
    path: 'share/Dnr',
    redirectTypeRoute: routeNames.discussRefer.landing,
    ShareRoute: true,
    component: <DnrShare />,
    subPath: ['', ':utm']
  },
  {
    path: '/share/doctube',
    component: <DoctubeShare />,
    ShareRoute: true,
    redirectTypeRoute: routeNames.doctube.landing,
    subPath: ['', ':utm']
  },
  {
    path: '/share/doctube_fst',
    component: <DoctubeShare />,
    ShareRoute: true,
    redirectTypeRoute: routeNames.doctube.landing,
    subPath: ['', ':utm']
  },
  {
    path: '/share/Profile',
    component: <ProfileShare />,
    ShareRoute: true,
    redirectTypeRoute: routeNames.userProfile.landing,
    subPath: ['', ':utm']
  },
  {
    path: '/share/archived_video/:id',
    component: <ClinicalVideosShare />,
    subPath: ['', ':utm'],
    ShareRoute: true,
    redirectTypeRoute: routeNames.clinicalVideo.detail
  },
  {
    path: '/share/observership/:id',
    redirectTypeRoute: routeNames.observership.observershipDetails,
    ShareRoute: true,
    component: <ObservershipShare />,
    subPath: ['', ':utm']
  },
  {
    path: '/share/mentor/:id',
    redirectTypeRoute: routeNames.observership.profile,
    ShareRoute: true,
    component: <MentorShare />,
    subPath: ['', ':utm']
  },
  {
    path: '/share/claim_mentorship/',
    redirectTypeRoute: routeNames.observership.registration,
    ShareRoute: true,
    component: <ClaimMentorship />,
    subPath: ['', '0', '0/:utm']
  },
  {
    path: '/share/video_archive/:id',
    component: <ClinicalVideosShare />,
    subPath: ['', ':utm'],
    ShareRoute: true,
    redirectTypeRoute: routeNames.clinicalVideo.detail
  }
];
