import React, { memo } from 'react';
import ReactGA from 'react-ga4';
import { Outlet } from 'react-router-dom';
import { isMobileApp } from '../common/common';
import MobileNativeEventsListener from '../capacitor/AppUrlListener';

// ReactGA.initialize('G-MMRQERMH4E');
ReactGA.initialize(process.env.REACT_APP_GA_ID);

function RootLayout() {
  // const [MobileNativeEventsListener, setMobileNativeEventsListener] = React.useState();
  // React.useEffect(() => {
  //   if (isMobileApp()) {
  //     import('../capacitor/AppUrlListener')
  //       .then((data) => setMobileNativeEventsListener(data.default))
  //       .catch((err) => console.log(err));
  //   }
  // }, []);
  return (
    <>
      {isMobileApp() &&
        <MobileNativeEventsListener></MobileNativeEventsListener>
      }
      <Outlet />
    </>
  );
}

export default memo(RootLayout);
