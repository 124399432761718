import React, { memo, useEffect, useState } from 'react';
import SwiperCore from 'swiper';
import { Pagination, Navigation, Mousewheel } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import './css/desktopHeader.scss';
SwiperCore.use([Pagination, Navigation, Mousewheel]);

import { Nav, NavDropdown, Navbar } from 'react-bootstrap';
import Marquee from 'react-fast-marquee';
import {
  AiOutlineLogout,
  AiOutlineQuestionCircle,
  BiMapPin,
  BsBell,
  HiOutlineBookmark
} from '../../components/icons';
import { ClirnetFullLogo, ClirnetIconLogo } from '../../components/icons/menuIconComponents';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { renderHeaderOnce, resetStore } from '../../Store/actions';
import { getMarquee } from '../../Store/actions/dashboard.action';
import { logoutUser } from '../../Store/actions/login.action';
import {
  DEMO_USER_PICTURE,
  changeUrl,
  convertAllMenu,
  deleteCaches,
  getLocalStorage,
  reactHtmlParser,
  setSessionStorage
} from '../../common/common';
import JoyRide from '../reactJoyRide/JoyRide';

import { openModalStatus } from '../../Store/actions';

import { fetchUnreadCount, loginToCrm } from '../../Store/actions/notification.action';
import { DEFAULT_MENU } from '../../Store/constants/app.constants';
import LazyImage from '../CustomLibraries/LazyImage';
import DeleteConfirmation from '../modals/DeleteConfirmation';
import HeaderSearchBar from './HeaderSearchBar';
import { routeNames } from '../../router/constants';
import LoaderLine from '../LoaderLine';
import useRedirect from '../customHooks/useRedirect';
function HeaderDesktop({ autoauthName = '' }) {
  const current_menu = useSelector((state) => state.common.current_menu);

  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({});
  // const [modalOpen, setModelOpen] = useState(true);
  const [currentMenu, setCurrentMenu] = useState('');
  const [isHover, setIsHover] = useState(false);
  const [vault, setVault] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const points = useSelector((state) => state.common.points);
  const point_api_called = useSelector((state) => state.common.point_api_called);
  const marquee = useSelector((state) => state.common.marquee);
  const marquee_called = useSelector((state) => state.common.marquee_called);
  const renderHeader = useSelector((state) => state.common.renderHeader);
  const [showTourButton, setShowTourButton] = useState(false);
  let app_tour_data = useSelector((state) => state.appTour.app_tour_data);
  let app_tour_modal_status = useSelector((state) => state.appTour.app_tour_modal_status);
  const notifications = useSelector((state) => state.notification);
  const [{ deletionType, deletionModalShow }, setDeletionConfirmation] = useState({
    deletionType: '',
    deletionModalShow: false
  });
  const [autoauthNameHeader, setAutoauthName] = useState(false);
  const userInfo = useSelector((state) => state.login.userDetails);
  const userDetails = useSelector((state) => state.common.user_details);
  let [onclickTourView, setOnclickTourView] = useState(0);
  const { redirectTo } = useRedirect();
  useEffect(() => {
    if (userInfo) {
      setUser(userInfo);
      setVault(getLocalStorage('vaultCount', 0));
    } else {
      if (getLocalStorage('user', false)) {
        setUser(getLocalStorage('user', {}));
      }
      if (getLocalStorage('vaultCount', 0)) {
        setVault(getLocalStorage('vaultCount', 0));
      }
    }
    if (getLocalStorage('user', false) && JSON.parse(getLocalStorage('user', {})?.menu)) {
      const menu = JSON.parse(getLocalStorage('user', {})?.menu);
      if (menu) {
        if ('menu' in menu) {
          // let menu = JSON.parse(userDetails.menu);
          setMenuJson({
            ...menu
            // static_menu: convertAllMenu(menu?.static_menu || []),
            // all_menu: convertAllMenu(menu?.all_menu || [])
          });
        } else setMenuJson(DEFAULT_MENU);
      }
    }
  }, [userInfo]);

  const getUnreadCount = () => {
    dispatch(
      loginToCrm((res) => {
        const { access_token } = res;
        dispatch(fetchUnreadCount(access_token, (res) => {}));
      })
    );
  };
  useEffect(() => {
    document.body.classList.toggle('menuShow', open);
  }, [open]);

  useEffect(() => {
    marquee_called == false && dispatch(getMarquee((res) => console.log(res)));
    console.log('marquee', marquee_called);
  }, [marquee_called]);

  useEffect(() => {
    if (notifications?.count_called == false) {
      if (notifications?.accessToken) {
        dispatch(fetchUnreadCount(notifications?.accessToken, (res) => {}));
      } else {
        getUnreadCount();
      }
    }
  }, [notifications?.count_called]);

  const logout = (type) => {
    if (type == 'logout') {
      // navigate('/');
      window.location.href = '/';
      dispatch(logoutUser());
      dispatch(resetStore());
      deleteCaches();
    }
  };
  useEffect(() => {
    // console.log('APP_TOUR_DATA', app_tour_data);
    if (app_tour_data) {
      app_tour_data?.data &&
        app_tour_data?.data?.maxtoursession &&
        app_tour_data?.data?.maxtoursession != '0' &&
        setShowTourButton(true);
    } else {
      setShowTourButton(false);
    }
  }, [app_tour_data]);

  const onTakeTourClick = () => {
    dispatch(openModalStatus(true));
    setOnclickTourView(1);
    setSessionStorage('sessionTourVal', 1);
  };
  const onNotificationClick = () => {
    !location?.pathname?.toLowerCase()?.includes('notification') &&
      redirectTo(routeNames.notification.landing);
  };
  // console.log('session val',getSessionStorage('sessionViewedStatus',0));
  const addHoverClass = () => setIsHover(true);
  const removeHoverClass = () => setIsHover(false);

  const [menuJson, setMenuJson] = useState();
  // getLocalStorage('user', false) ? JSON.parse(getLocalStorage('user', {})?.menu) : ''

  // useEffect(() => {
  //   if (userDetails && JSON.parse(userDetails?.menu)) {
  //     if(userDetails.menu.main === 'dnr')
  //     {
  //       const dnr = userDetails.menu.all_menu.filter(elem=>elem["short-name"]==="D&R")[0]
  //       const home = userDetails.menu.all_menu.filter(elem=>elem["short-name"]==="Home")[0]
  //       const remainingMenu = userDetails.menu.all_menu.filter(elem=>(elem["short-name"]!=="Home" && elem["short-name"]!=="D&R"))
  //       const newArray = [dnr,...remainingMenu,home]
  //       setMenuJson({
  //         main: userDetails.menu.main,
  //         all_menu:newArray
  //       })

  //     }
  //     else
  //     setMenuJson(JSON.parse(userDetails?.menu));
  //   }
  // }, [userDetails]);
  // console.group('menuJson');
  // console.log('menuJsonlocal', JSON.parse(getLocalStorage('user', {})?.menu));
  // console.log('menuJson', menuJson);
  // console.log('user', menuJson.all_menu);
  // console.groupEnd('menuJson');

  useEffect(() => {
    if (current_menu?.toLowerCase()?.includes('dashboard')) {
      setCurrentMenu('dashboard');
    } else if (current_menu?.toLowerCase()?.includes('session')) {
      setCurrentMenu('session');
    } else if (current_menu?.toLowerCase()?.includes('medwiki')) {
      setCurrentMenu('medwiki');
    } else if (current_menu?.toLowerCase()?.includes('spq')) {
      setCurrentMenu('spq');
    } else if (current_menu?.toLowerCase()?.includes('clinical')) {
      setCurrentMenu('clinical');
    } else if (current_menu?.toLowerCase()?.includes('gr')) {
      setCurrentMenu('grandround');
    } else if (
      current_menu?.toLowerCase()?.includes('ebook') ||
      current_menu?.toLowerCase()?.includes('epaper')
    ) {
      setCurrentMenu('ebook');
    } else if (current_menu?.toLowerCase()?.includes('channel')) {
      setCurrentMenu('channel');
    } else if (current_menu?.toLowerCase()?.includes('telemed')) {
      setCurrentMenu('telemed');
    } else if (current_menu?.toLowerCase()?.includes('discussrefer')) {
      setCurrentMenu('discussrefer');
    } else if (
      current_menu?.toLowerCase()?.includes('training') ||
      current_menu?.toLowerCase()?.includes('course')
    ) {
      setCurrentMenu('training');
    } else if (current_menu?.toLowerCase()?.includes('doctube')) {
      setCurrentMenu('doctube');
    } else if (current_menu?.toLowerCase()?.includes('speciality')) {
      setCurrentMenu('speciality');
    } else if (
      current_menu?.toLowerCase()?.includes('diagnosis') ||
      current_menu?.toLowerCase()?.includes('ddx')
    ) {
      setCurrentMenu('diagnosis');
    } else if (current_menu?.toLowerCase()?.includes('observership')) {
      setCurrentMenu('observership');
    }
  }, [current_menu]);

  function getActiveClass(path) {
    if (currentMenu !== '') {
      if (
        currentMenu.includes(path?.toLowerCase()) ||
        path?.toLowerCase().includes(currentMenu?.toLowerCase())
      ) {
        return 'active';
      }
    }
  }

  return (
    <>
      <Navbar expand="false" className="deskHeader p-0 bg-white fixed-top">
        <LoaderLine />
        <JoyRide />
        {marquee?.length > 0 && (
          <div className="text-white fst-italic fw-medium fs-5 dskMarquee bg-black position-fixed top-0 start-0 text-nowrap z-1 w-100 lh-lg d-flex">
            <Marquee>
              {marquee?.map((_m) => (
                <span className="me-5" key={_m.id}>
                  {_m.text}
                </span>
              ))}
            </Marquee>
          </div>
        )}
        <div
          id="headerDesktopNavBar"
          className="w-100 deskHeaderTop position-relative shadow pt-20 topHeader"
        >
          <div className="w-100 deskMainHeader bg-white px-20 d-flex justify-content-between align-items-center">
            <div className="deskTpNav_left d-flex justify-content-between align-items-center">
              <button
                type="button"
                className={`deskMenuToggle position-relative p-0 bg-transparent border-0 navbar-toggler ${
                  !open ? 'collapsed' : ''
                }`}
                onClick={() => {
                  setOpen(!open);
                }}
                aria-label="Nav Toggle"
              >
                <span className="navbar-toggler-icon bg-black position-absolute top-50 start-50"></span>
              </button>
            </div>
            <div className="deskTpNav_Right d-inline-flex align-items-center">
              <HeaderSearchBar currentMenu={currentMenu} />
              <Nav className="deskNavRight flex-row align-items-center ms-5">
                <Nav.Link
                  onClick={onNotificationClick}
                  className="dskNotificationBtn fw-medium text-black position-relative me-20 gtm_cl_header_notification"
                  id="header_desktop_notification_anchor_text"
                >
                  <span
                    className="position-relative gtm_cl_header_notification"
                    id="header_desktop_notification_icon_span"
                  >
                    <BsBell
                      gtmTagVariable="gtm_cl_header_notification"
                      customClass="fs-30 text-black"
                      id="header_desktop_notification_icon"
                    />
                    {notifications?.count > 0 && (
                      <span
                        className="notificationCount text-white bg-primary rounded-circle position-absolute d-flex justify-content-center align-items-center fs-6 gtm_cl_header_notification"
                        id="header_desktop_notification_count_text"
                      >
                        {notifications?.count ?? 0}
                      </span>
                    )}
                  </span>
                </Nav.Link>
                {showTourButton && (
                  <Nav.Link
                    onClick={onTakeTourClick}
                    className="dskTakeTourBtn shineEffect fw-medium text-black me-4 rounded-5 py-3 px-4 gtm_cl_header_takeTour"
                    id={'header_desktop_takeTour_anchor_text'}
                  >
                    <span id="header_desktop_takeTour_text" className="gtm_cl_header_takeTour">
                      <BiMapPin
                        gtmTagVariable="gtm_cl_header_takeTour"
                        customClass="fs-30 text-black header_desktop_takeTour_icon"
                        id={'header_desktop_takeTour_icon'}
                      />
                      Take Tour
                    </span>
                  </Nav.Link>
                )}
                <Nav.Link
                  onClick={() => {
                    redirectTo(routeNames.userProfile.landing);
                  }}
                  className="deskNavProfile text-left d-inline-flex align-items-center gtm_cl_header_userprofile"
                  // id="user_profile"
                  id="header_desktop_user_profile_anchor_text"
                >
                  <div
                    onClick={() => {
                      redirectTo(routeNames.userProfile.landing);
                    }}
                    className="rounded-circle deskNavProfilePic overflow-hidden position-relative d-flex justify-content-center align-items-center me-3 gtm_cl_header_userprofile"
                    id="header_desktop_user_profile_section"
                  >
                    {autoauthNameHeader ? (
                      <h5
                        className="fw-semibold text-white fs-1 text-uppercase gtm_cl_header_userprofile"
                        id="header_desktop_user_profile_autoNameHeader"
                      >
                        {autoauthNameHeader ? autoauthName?.charAt(0) : user?.first_name?.charAt(0)}
                      </h5>
                    ) : user?.profile_image ? (
                      <LazyImage
                        src={user?.profile_image ?? DEMO_USER_PICTURE}
                        className="object-fit-cover w-100 h-100 float-start gtm_cl_header_userprofile"
                        alt="profile"
                        fallback_image={DEMO_USER_PICTURE}
                        id="header_desktop_user_profile_image"
                        logo
                      />
                    ) : (
                      <h5
                        className="fw-semibold text-white fs-1 text-uppercase gtm_cl_header_userprofile"
                        id="header_desktop_user_profile_autoNameHeader"
                      >
                        {autoauthNameHeader ? autoauthName?.charAt(0) : user?.first_name?.charAt(0)}
                      </h5>
                    )}
                  </div>
                </Nav.Link>
                {/* <Nav.Link
                className="rounded bg-primary text-white headerPoints text-start d-flex justify-content-center flex-column"
                onClick={() => navigate('/UserPoint')}
              >
              <h5 className="fs-6 fw-medium">Points</h5>
              <h2 className="fs-3 fw-bold d-flex align-items-center justify-content-between">
              {points}
              <MdOutlineArrowRightAlt customClass="fs-2 text-white ms-4" />
                </h2>
              </Nav.Link> */}

                <NavDropdown
                  title={
                    <i className="flaticon-more-1 fs-4"></i>
                    // <svg
                    //   stroke="currentColor"
                    //   fill="currentColor"
                    //   strokeWidth="0"
                    //   viewBox="0 0 16 16"
                    //   className="fs-2"
                    //   xmlns="http://www.w3.org/2000/svg"
                    // >
                    //   <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"></path>
                    // </svg>
                  }
                  className="deskNavDots"
                  onClick={() => dispatch(renderHeaderOnce())}
                >
                  {/* position-absolute top-50 translate-middle-y */}
                  <NavDropdown.Item
                    className="position-relative fs-4 p-3 gtm_cl_header_helpsupport d-flex gap-3"
                    onClick={() => {
                      redirectTo(routeNames.helpAndSupport.landing);
                    }}
                    id="header_desktop_helpSupport_anchor_text"
                  >
                    <AiOutlineQuestionCircle
                      gtmTagVariable="gtm_cl_header_helpsupport"
                      customClass="fs-4"
                      id="header_desktop_helpSupport_icon"
                    />{' '}
                    Help &amp; Support
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="deskNavVault position-relative fs-4 p-3 justify-content-between align-items-center gtm_cl_header_vault d-flex gap-3"
                    onClick={() => {
                      redirectTo(routeNames.speciality.vault);
                    }}
                    id="header_desktop_vault_anchor_text"
                  >
                    <span className="d-flex align-items-center gap-3">
                      <HiOutlineBookmark
                        gtmTagVariable="gtm_cl_header_vault"
                        customClass="fs-4"
                        id="header_desktop_vault_icon"
                      />
                      Saved{' '}
                    </span>

                    {vault > 0 ? (
                      <span
                        className="rounded-circle fs-5 bg-primary text-white p-0 text-center lh-lg gtm_cl_header_vault"
                        id="header_desktop_vault_text"
                      >
                        {vault}
                      </span>
                    ) : null}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() =>
                      setDeletionConfirmation({ deletionType: 'logout', deletionModalShow: true })
                    }
                    id="header_desktop_logout_anchor_text"
                    className="position-relative fs-4 p-3 gtm_cl_header_logout d-flex gap-3"
                  >
                    <AiOutlineLogout
                      gtmTagVariable="gtm_cl_header_logout"
                      customClass="fs-4"
                      id="header_desktop_logout_icon"
                    />{' '}
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </div>
          </div>
        </div>
        <Navbar.Collapse
          className={`deskLeftmenuBar position-fixed start-0 bg-transparent d-block pt-70 ${
            open ? 'fullMenuShow' : ''
          }`}
        >
          {/* "fullMenuShow" className should be added when the collapsed menu open */}
          <Navbar.Brand
            className="position-absolute top-0 start-0 p-0 m-0 d-block w-100"
            href="javascript:void(0)"
            onClick={() => {
              if (getLocalStorage('main_menu', '') === 'dnr') {
                setCurrentMenu('discussRefer');
                redirectTo(routeNames.discussRefer.landing);
              } else {
                setCurrentMenu('dashboard');
                redirectTo(routeNames.dashboard.landing);
              }
            }}
          >
            <div
              className="topLogo position-absolute top-50 start-50 translate-middle bg-white rounded-circle gtm_cl_header_clirnet_logo"
              id="header_desktop_clirnet_logo_span"
            >
              <ClirnetIconLogo
                gtmTagVariable="gtm_cl_header_clirnet_logo"
                className={'topLogo1 position-absolute top-50 start-50 w-auto'}
                id="header_desktop_clirnet_icon_logo"
              />
              <ClirnetFullLogo
                gtmTagVariable="gtm_cl_header_clirnet_logo"
                className={'topLogo2 position-absolute top-50 start-50 w-100'}
                id="header_desktop_clirnet_full_logo"
              />
            </div>
          </Navbar.Brand>
          <Nav
            className="fs-4 fw-normal deskNavMenu h-100 bg-white d-block shadow"
            activeKey={pathname}
            defaultActiveKey={'/dashboard'}
            // style={{ width: '8rem' }}
          >
            <Swiper
              className={`deskNavMenuSlider cmnLndngRowSlide h-100 pt-3 pb-5 ${
                isHover ? 'deskMunuLinkHover' : ''
              }`}
              direction={'vertical'}
              // autoHeight={'true'}
              mousewheel={true}
              spaceBetween={10}
              slidesPerView={12}
              loop={false}
              navigation={{ clickable: true }}
              breakpoints={{
                600: {
                  slidesPerView: 11,
                  spaceBetween: 10
                },
                768: {
                  slidesPerView: 16,
                  spaceBetween: 12
                },
                1024: {
                  slidesPerView: 10,
                  spaceBetween: 10
                },
                1150: {
                  slidesPerView: 11,
                  spaceBetween: 10
                },
                1200: {
                  slidesPerView: 11,
                  spaceBetween: 10
                },
                1280: {
                  slidesPerView: 11,
                  spaceBetween: 10
                },
                1366: {
                  slidesPerView: 10,
                  spaceBetween: 10
                },
                1400: {
                  slidesPerView: 12,
                  spaceBetween: 10
                },
                1600: {
                  slidesPerView: 12,
                  spaceBetween: 12
                },
                1920: {
                  slidesPerView: 12,
                  spaceBetween: 15
                },
                2200: {
                  slidesPerView: 13,
                  spaceBetween: 15
                },
                2400: {
                  slidesPerView: 14,
                  spaceBetween: 15
                },
                2600: {
                  slidesPerView: 15,
                  spaceBetween: 15
                },
                2800: {
                  slidesPerView: 16,
                  spaceBetween: 15
                }
              }}
            >
              {menuJson &&
                menuJson.menu?.length > 0 &&
                menuJson?.menu?.map((key, ind) =>
                  'sub_menu' in key && key.sub_menu?.length > 0 ? (
                    key.sub_menu?.map((elem, ind) => (
                      <SwiperSlide
                        key={ind + 1}
                        className="deskNavMenuSingle"
                        onMouseEnter={addHoverClass}
                        onMouseLeave={removeHoverClass}
                      >
                        <Nav.Link
                          className={`deskMunuLink d-flex align-items-center position-relative text-dark mb-2 ${getActiveClass(
                            elem?.url
                          )} gtm_cl_header_${elem.title}`}
                          href="javascript:void(0)"
                          onClick={() => {
                            setCurrentMenu(elem.title);
                            redirectTo(changeUrl(elem.url));
                          }}
                          id={`header_desktop_${elem.title}_anchor_text`}
                          key={ind}
                        >
                          {elem.displayIcon ? (
                            <div className="oldUser--premium__tag position-absolute shineEffect icon-size-20 rounded-circle mt-1 ms-1 top-0 start-0 d-flex align-items-center justify-content-center">
                              <i className="flaticon-premium fs-5 text-black"></i>
                            </div>
                          ) : (
                            <></>
                          )}
                          <span
                            className={`deskMunuLinkIcon z-1 position-absolute start-0 gtm_cl_header_${elem.title}`}
                            id={`header_desktop_${elem.title}_icon_span`}
                          >
                            {elem.icon?.includes('https') ? (
                              <img
                                src={elem.icon}
                                gtmTagVariable={'gtm_cl_header_' + elem.title}
                                className={
                                  'fs-2 deskTopmenuSVG position-absolute top-50 start-50 translate-middle'
                                }
                                id={'header_desktop_' + elem.title + '_icon'}
                              />
                            ) : (
                              <div
                                dangerouslySetInnerHTML={{ __html: elem.icon }}
                                gtmTagVariable={'gtm_cl_header_' + elem.title}
                                className={
                                  'fs-2 deskTopmenuSVG position-absolute top-50 start-50 translate-middle text-center'
                                }
                                id={'header_desktop_' + elem.title + '_icon'}
                              ></div>
                            )}
                          </span>
                          <span
                            className={`deskMunuLinkTxt2 z-1 position-relative text-nowrap ms-n3 gtm_cl_header_${elem.title}`}
                            id={`header_desktop_${elem.title}_text`}
                          >
                            {reactHtmlParser(elem.title)}
                          </span>

                          <span
                            className={`deskMunuLinkTxt z-1 position-absolute start-0 text-nowrap text-center gtm_cl_header_${elem.title}`}
                          >
                            {reactHtmlParser(elem['short-name'])}
                          </span>
                        </Nav.Link>
                      </SwiperSlide>
                    ))
                  ) : (
                    <SwiperSlide
                      key={ind + 1}
                      className="deskNavMenuSingle"
                      onMouseEnter={addHoverClass}
                      onMouseLeave={removeHoverClass}
                    >
                      <Nav.Link
                        className={`deskMunuLink d-flex align-items-center position-relative text-dark mb-2 ${getActiveClass(
                          key?.url
                        )} gtm_cl_header_${key.title}`}
                        href="javascript:void(0)"
                        onClick={() => {
                          setCurrentMenu(key.title);
                          redirectTo(changeUrl(key.url));
                        }}
                        id={`header_desktop_${key.title}_anchor_text`}
                      >
                        <span
                          className={`deskMunuLinkIcon z-1 position-absolute start-0 gtm_cl_header_${key.title}`}
                          id={'header_desktop_' + key.title + '_icon_span'}
                        >
                          {key.displayIcon && (
                            <div className="oldUser--premium__tag position-absolute shineEffect icon-size-20 rounded-circle  ms-1 top-0 start-0 d-flex align-items-center justify-content-center">
                              <i className="flaticon-premium fs-5 text-black"></i>
                            </div>
                          )}
                          {key.icon?.includes('https') ? (
                            <img
                              src={key.icon}
                              gtmTagVariable={'gtm_cl_header_' + key.title}
                              className={
                                'fs-2 deskTopmenuSVG position-absolute top-50 start-50 translate-middle'
                              }
                              id={`header_desktop_${key.title}_icon`}
                            />
                          ) : (
                            <div
                              dangerouslySetInnerHTML={{ __html: key.icon }}
                              gtmTagVariable={'gtm_cl_header_' + key.title}
                              className={
                                'fs-2 deskTopmenuSVG position-absolute top-50 start-50 translate-middle text-center'
                              }
                              id={`header_desktop_${key.title}_icon`}
                            ></div>
                          )}
                        </span>
                        <span
                          className={`deskMunuLinkTxt2 z-1 position-relative text-nowrap ms-n3 gtm_cl_header_${key.title}`}
                          id={`header_desktop_${key.title}_text`}
                        >
                          {reactHtmlParser(key.title)}
                        </span>

                        <span
                          className={`deskMunuLinkTxt z-1 position-absolute start-0 text-nowrap text-center gtm_cl_header_${key.title}`}
                        >
                          {reactHtmlParser(key['short-name'])}
                        </span>
                      </Nav.Link>
                    </SwiperSlide>
                  )
                )}
            </Swiper>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {deletionType && (
        <DeleteConfirmation
          title={`Are you sure to log out ?`}
          type={deletionType}
          confirm={deletionModalShow}
          handleClose={() =>
            setDeletionConfirmation({ deletionType: '', deletionModalShow: false })
          }
          confirmDeletion={logout}
        />
      )}
    </>
  );
}

export default memo(HeaderDesktop);
