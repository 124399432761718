import {
  SAVE_FILE_CONSENT,
  SAVE_FILE_OBJECT,
  SAVE_FILE_PRESCRIPTION
} from '../constants/app.constants';

const initialState = {
  file: null,
  fileConsent: null,
  filePrescription: null
};
export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_FILE_OBJECT:
      return {
        ...state,
        file: action.payload
      };
    case SAVE_FILE_CONSENT:
      return {
        ...state,
        fileConsent: action.payload
      };
    case SAVE_FILE_PRESCRIPTION:
      return {
        ...state,
        filePrescription: action.payload
      };
    default:
      return state;
  }
};
