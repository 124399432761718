import axios from 'axios';
import { trackingConstant } from '../constants/tracking.constant';
import axiosInstance from '../helper/axiosInstance';

export const recordEvent = (event, data) => {
  return {
    type: trackingConstant.TRACK_EVENT,
    payload: data
  };
};

export const recordOpixEvent = (event, data) => {
  return {
    type: trackingConstant.OPIX_EVENT,
    payload: data
  };
};

export const recordTrackingData = (config_data) => {
  return {
    type: trackingConstant.ADD_TRACKING_DATA,
    payload: config_data
  };
};

export const clearTrackingData = (config_data) => {
  return {
    type: trackingConstant.REMOVE_TRACKING_DATA
  };
};

export const clearOpixData = (config_data) => {
  return {
    type: trackingConstant.REMOVE_OPIX_DATA
  };
};

export const sendTrackingData = (report, callback) => {
  return async (dispatch) => {
    //tracking/data
    axiosInstance
      .post('tracking/data', report)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        callback('error');
      });
  };
};

export const saveToFirebase = (report, callback) => {
  console.log('firestoreEndPoints', process.env.REACT_APP_FIRESTORE);
  return async (dispatch) => {
    axios
      .post(`${process.env.REACT_APP_FIRESTORE}cloud-analytics/add`, report)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
};

export const saveToFirebaseWithToken = (report, callback) => {
  console.log('firestoreEndPoints', process.env.REACT_APP_FIRESTORE);
  return async (dispatch) => {
    axios
      .post(`${process.env.REACT_APP_FIRESTORE}cloud-analytics/add-with-token`, report)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
};

export const saveToFirebaseWithoutToken = (report, callback) => {
  console.log('firestoreEndPoints', process.env.REACT_APP_FIRESTORE);
  return async (dispatch) => {
    axios
      .post(`${process.env.REACT_APP_FIRESTORE}cloud-analytics/add-with-out-token`, report)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
};
