import React from 'react';
import { isMobile } from 'react-device-detect';
import {
  BsUpload,
  CertificateBigIcon,
  CgArrowLongRight,
  ClinicalVideoLogo,
  FaRegUser,
  HiMenuAlt2,
  LiveCmeLogo,
  MedwikiLogo,
  RiBarChart2Line,
  RiSearchLine
} from '../icons';
{
  /* .text-primary  .colorPurple   .colorYellow   .text-secondary   .text-secondary    */
}
export const stepsDb = {
  Dashboard: [
    {
      disableBeacon: true,
      placement: 'auto',
      target: '.deskMenuToggle',
      spotlightPadding: 10,
      content: (
        <div className="tourContent_WithIcon">
          <div className="pulseBorder appTourIcon d-flex align-items-center justify-content-center text-secondary">
            <HiMenuAlt2 customClass="text-white fs-2" />
          </div>
          Search for any case,medical issues. Filter by speciality to get specific search results.
        </div>
      )
    },
    {
      disableBeacon: true,
      placement: isMobile ? 'bottom-end' : 'auto',
      target: '.search',
      content: (
        <div className="tourContent_WithIcon">
          <div className="pulseBorder appTourIcon d-flex align-items-center justify-content-center colorYellow">
            <RiSearchLine customClass="text-white fs-2" />
          </div>
          Search for any case,medical issues. Filter by speciality to get specific search results.
        </div>
      )
    },
    {
      disableBeacon: true,
      placement: isMobile ? 'bottom-end' : 'auto',
      target: '.deskNavProfilePic',
      spotlightPadding: 5,
      content: (
        <div className="tourContent_WithIcon">
          <div className="pulseBorder appTourIcon d-flex align-items-center justify-content-center colorYellow">
            <FaRegUser customClass="text-white fs-2" />
          </div>
          Search for any case,medical issues. Filter by speciality to get specific search results.
        </div>
      )
    },
    {
      disableBeacon: true,
      placement: 'auto',
      isFixed: false,
      target: '.doctorVoice',
      content: (
        <div className="tourContent_WithIcon">
          <div className="pulseBorder appTourIcon d-flex align-items-center justify-content-center text-secondary">
            <RiBarChart2Line customClass="text-white fs-2" />
          </div>
          Take the poll and know what other 200k doctor’s opinions on new subjects everyday
        </div>
      )
    },

    {
      disableBeacon: true,
      placement: isMobile ? 'bottom-start' : 'auto',

      target: '.cerificateBoxDb',
      content: (
        <div className="tourContent_WithIcon">
          <div className="pulseBorder appTourIcon d-flex align-items-center justify-content-center text-purple">
            <CertificateBigIcon customClass="text-white fs-2" />
          </div>
          Your certificate locker! View, download and share freely your earnt certificates.
        </div>
      )
    },
    {
      disableBeacon: true,
      placement: 'auto',
      spotlightBorderRadius: 50,
      target: '.cerificateBoxDbPlus',
      content: (
        <div className="tourContent_WithIcon">
          <div className="pulseBorder appTourIcon d-flex align-items-center justify-content-center text-purple">
            <BsUpload customClass="text-white fs-2" />
          </div>
          Upload your certificates to your certificate locker.
        </div>
      )
    },
    {
      disableBeacon: true,
      placement: isMobile ? 'bottom-end' : 'auto',
      spotlightBorderRadius: 50,
      target: '.reservedCmeDb',
      content: (
        <div className="tourContent_WithIcon">
          <div className="pulseBorder appTourIcon d-flex align-items-center justify-content-center text-primary">
            <LiveCmeLogo className="text-white fs-2" />
          </div>
          Track and join incredible CMEs that have interested you!
        </div>
      )
    },
    {
      disableBeacon: true,
      placement: 'auto',
      target: '.profile',
      content: (
        <div className="tourContent_WithIcon">
          <div className="pulseBorder appTourIcon d-flex align-items-center justify-content-center text-secondary">
            <FaRegUser customClass="text-white fs-2" />
          </div>
          Add here your profile details so that we can show you relevant contents in future
        </div>
      )
    },
    {
      disableBeacon: true,
      placement: 'auto',
      target: '.trendingCme',
      content: (
        <div className="tourContent_WithIcon">
          <div className="pulseBorder appTourIcon d-flex align-items-center justify-content-center text-secondary">
            <LiveCmeLogo className="text-white fs-2" />
          </div>
          Reserve a popular live session by clicking on ‘Attend’.
        </div>
      )
    },

    {
      disableBeacon: true,
      placement: isMobile ? 'bottom-end' : 'auto',
      spotlightPadding: 10,

      target: '.exploreMoreTour',
      content: (
        <div className="tourContent_WithIcon">
          <div className="pulseBorder appTourIcon d-flex align-items-center justify-content-center text-primary">
            <CgArrowLongRight customClass="text-white fs-2" />
          </div>
          Didn’t find any content relevant for you, ‘Explore More’ to view more
        </div>
      )
    },
    {
      disableBeacon: true,
      placement: 'auto',
      target: '.dbV2ClVideos',
      content: (
        <div className="tourContent_WithIcon">
          <div className="pulseBorder appTourIcon d-flex align-items-center justify-content-center text-secondary">
            <ClinicalVideoLogo className="text-white fs-2" />
          </div>
          Search for any case,medical issues. Filter by speciality to get specific search results.
        </div>
      )
    },
    {
      disableBeacon: true,
      placement: 'auto',
      spotlightPadding: 10,
      target: '.medwikiDbV2AppTour',
      content: (
        <div className="tourContent_WithIcon">
          <div className="pulseBorder appTourIcon d-flex align-items-center justify-content-center text-secondary">
            <MedwikiLogo className="text-white fs-2" />
          </div>
          Curated reading materials to upgrade your knowledge in 1-3 mins.
        </div>
      )
    }
  ],
  ClinicalVideoLanding: [
    {
      disableBeacon: true,
      target: '.clinicalFeature',
      placement: 'auto',
      content: `All featured sessions can be viewed here.
      Scroll right to view more.
      `
    },
    {
      disableBeacon: true,
      target: '.clinicalRecommended',
      placement: 'auto',
      content: `View all coming sessions here
      `
    },
    {
      disableBeacon: true,
      target: '.clinicalSpeciality',
      placement: 'auto',
      content: `Click on a speciality and view all contents from that speciality.
      `
    }
  ],
  DiscussRefer: [
    {
      disableBeacon: true,
      placement: 'auto',
      target: '.hospitlFeaturedSlide',
      content: 'Go to the hospital page. Discuss a case or refer a patient.'
    },
    {
      disableBeacon: true,
      placement: 'auto',
      target: '.hospitlLandingActvtBoxArea',
      content: 'Track the status of your case from here.'
    },
    {
      disableBeacon: true,
      placement: 'auto',
      target: '.mcExcellence',
      content: 'View all center of excellence from here.'
    }
  ],
  Medwiki: [
    {
      disableBeacon: true,
      placement: 'auto',
      target: '.medwikiFeature',
      content: `All featured MedWiki can be viewed here.
      Scroll right to view more
      `
    },
    {
      disableBeacon: true,
      placement: 'auto',
      target: '.medwikiSpeciality',
      content: `Click on a speciality and view all contents from that speciality
      `
    }
  ],
  Sessions: [
    {
      disableBeacon: true,
      placement: 'auto',
      target: '.featureBanner',
      content: 'Our Banner!!'
    },
    {
      disableBeacon: true,
      placement: 'auto',
      target: '.upcoming',
      content: 'View all coming sessions here'
    },
    {
      disableBeacon: true,
      placement: 'auto',
      target: '.reserveSection',
      content: 'View all your reserved sessions here.'
    },
    {
      disableBeacon: true,
      placement: 'auto',
      target: '.connectOtO',
      content: `Discuss a case or ask your query from the experts one to one.
      Explore more to view more doctors
      `
    }
  ],
  SPQlanding: [
    {
      disableBeacon: true,
      placement: 'auto',
      target: '.spqFeatured',
      content: `Swipe right to view all your followed Channel. 
      Click on manage to view all Channels      
      `
    },
    {
      disableBeacon: true,
      placement: 'auto',
      target: '.spqLandingidePrnt',
      content: `Take the poll directly from here and grab points      
      `
    },
    {
      disableBeacon: true,
      placement: 'auto',
      target: '.spqSurvey',
      content: `Surveys help healthcare stakeholders get feedback and improve the solutions.      
      `
    },
    {
      disableBeacon: true,
      placement: 'auto',
      target: '.spqQuiz',
      content: `Test your knowledge in a subject today.      
      `
    },
    {
      disableBeacon: true,
      placement: 'auto',
      target: '.spqCompl',
      content: `Track your completed and pending Polls,surveys and Quizzes here.      
      `
    },
    {
      disableBeacon: true,
      placement: 'auto',
      target: '.spqRecent',
      content: `Find recently added polls and quizzes here.      
      `
    }
  ],
  UserProfile: [
    {
      disableBeacon: true,
      placement: 'auto',
      target: '.mandatoryProfileDetail',
      content: 'Verify your details to receive updates from your speciality.'
    },
    {
      disableBeacon: true,
      placement: 'auto',
      target: '.addCertificate',
      content: 'Showcase your academia by adding certificates here'
    },
    {
      disableBeacon: true,
      placement: 'auto',
      target: '.OtherDetails',
      content: 'Add more details on you education, achievements',
      scrollToFirstStep: true
    }
  ]
};
export const settings = {
  VIEW_PER_SESSION: 2
};
