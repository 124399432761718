import {
  certified_courses_static_image,
  clinical_Video_static_image,
  ebook_static_image,
  grand_rounds_static_image,
  medwiki_static_image,
  quizzes_static_image,
  surveys_static_image
} from './assets.common';
import { DEMO_USER_PICTURE } from './common';

//get a random number between 1,2,3
const random = Math.floor(Math.random() * 3) + 1;

const images = {
  medwiki: medwiki_static_image,
  clinical: clinical_Video_static_image,
  survey: surveys_static_image,
  session:
    'https://previews.123rf.com/images/varijanta/varijanta1604/varijanta160400086/55848266-modern-thin-line-design-concept-for-healthcare-website-banner-vector-illustration-concept-for-health.jpg',
  gr: grand_rounds_static_image,
  quiz: quizzes_static_image,
  training: certified_courses_static_image,
  epub: ebook_static_image,
  observership: 'https://assets.clirnet.com/defaultContentImage/images/observership-cover-4.png',
  observership1: 'https://assets.clirnet.com/defaultContentImage/images/observership-cover-1.png',
  observership2: 'https://assets.clirnet.com/defaultContentImage/images/observership-cover-2.png',
  observership3: 'https://assets.clirnet.com/defaultContentImage/images/observership-cover-3.png',

  user: DEMO_USER_PICTURE,
  mentor1: 'https://assets.clirnet.com/defaultContentImage/images/mentor-default-image-1.png',
  mentor2: 'https://assets.clirnet.com/defaultContentImage/images/mentor-default-image-2.png',
  mentor3: 'https://assets.clirnet.com/defaultContentImage/images/mentor-default-image-4.png'
};
export const default_images = {
  ...images,
  mentor: random === 1 ? images.mentor1 : random === 2 ? images.mentor2 : images.mentor3
};
