import { getLocalStorage, isMobileApp } from '../../common/common';
import packageJson from '../../../package.json';

const apiVersion = packageJson.version;
let token = getLocalStorage('refreshToken', true);
export function authHeader() {
  if (token) {
    return {
      Authorization: getLocalStorage('refreshToken', true),
      version: isMobileApp() ? `rjsa ${apiVersion}` : `rjsw ${apiVersion}`
    };
  } else {
    return {};
  }
}
