import { vaultConstants } from '../constants/vault.constant';

let initialState = {
  vaultList: [],
  error: ''
};

export const vaultReducer = (state = initialState, action) => {
  switch (action.type) {
    case vaultConstants.GET_VAULT_LIST_SUCCESS:
      if (action.payload.data) {
        return {
          ...state,
          vaultList: action.payload.data
        };
      } else {
        return {
          ...state,
          vaultList: []
        };
      }

    case vaultConstants.GET_VAULT_LIST_FAILURE:
      return {
        ...state,
        error: action?.payload?.message || ''
      };

    default:
      return state;
  }
};
