import React from 'react';
import Loadable from '../Loadable';

const Login = Loadable({
  loader: () => import('../../../src/pages/login/LoginNew')
});

// const Registration = Loadable({
//   loader: () => import('../../pages/registration/Registration')
// });

export const publicRoutes = [
  {
    path: '/',
    component: <Login key="login" />,
    module: 'generic'
  },
  {
    path: '/Login',
    component: <Login />,
    module: 'generic'
  },
  {
    path: '/reset-password',
    subPath: [':secretCode'],
    component: <Login key="resetPassword" />,
    module: 'generic'
  },
  {
    path: '/Login/:type/:data',
    component: <Login />,
    subPath: ['', ':utm'],
    module: 'generic'
  },
  // {
  //   path: '/registration',
  //   component: <Registration />,
  //   subPath: ['', ':email', ':email/:referal_code'],
  //   module: 'generic'
  // },
  {
    path: '/forget-password',
    component: <Login type={4} />,
    subPath: [''],
    module: 'generic'
  }
];
