import { sessionConstants } from '../constants';
import { generateUtmExt, log } from '../../common/common';
import { logoutUser } from './login.action';
import axiosInstance from '../helper/axiosInstance';

export const getUpcomingMasterSession = (limit = 0, callback) => {
  return async (dispatch) => {
    axiosInstance
      .get(`knwlgmastersessionnew/upcomingmastersessionslider?from=${limit}&to=10`)
      .then((response) => {
        callback(response.data);
        dispatch({
          type: sessionConstants.GET_UPCOMING_SESSION_DATA_SUCCESS,
          payload: response.data.data
        });
      })
      .catch((error) => {
        if (error == 'loginAgain') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
        dispatch({
          type: sessionConstants.GET_UPCOMING_SESSION_DATA_FALIURE,
          payload: error
        });
      });
  };
};

export const getRecentMasterSession = (limit = 0, callback) => {
  return async (dispatch) => {
    axiosInstance
      .get(`knwlgmastersessionnew/upcoming_mastersession_all?from=${limit}&to=10`)
      .then((response) => {
        callback(response.data.data);
        dispatch({
          type: sessionConstants.GET_RECENT_SESSION_DATA_SUCCESS,
          payload: response.data.data
        });
      })
      .catch((error) => {
        if (error == 'loginAgain') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
        dispatch({
          type: sessionConstants.GET_RECENT_SESSION_DATA_FALIURE,
          payload: error
        });
      });
  };
};

export const getFeaturedMasterSession = (limit = 0, callback) => {
  return async (dispatch) => {
    axiosInstance
      .get(`knwlgmastersessionnew/upcoming_mastersession_featured?from=${limit}&to=5`)
      .then((response) => {
        callback(response.data.data);
        dispatch({
          type: sessionConstants.GET_FEATURED_SESSION_DATA_SUCCESS,
          payload: response.data.data
        });
      })
      .catch((error) => {
        if (error == 'loginAgain') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
        dispatch({
          type: sessionConstants.GET_FEATURED_SESSION_DATA_FALIURE,
          payload: error
        });
      });
  };
};

export const getReservedMasterSession = (limit = 0, callback) => {
  return async (dispatch) => {
    axiosInstance
      .get(`knwlgmastersessionnew/bookedmastersession?from=${limit}&to=10`)
      .then((response) => {
        callback(response.data);
        dispatch({
          type: sessionConstants.GET_RESERVED_SESSION_DATA_SUCCESS,
          payload: response.data.data
        });
      })
      .catch((error) => {
        if (error == 'loginAgain') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
        dispatch({
          type: sessionConstants.GET_RESERVED_SESSION_DATA_FALIURE,
          payload: error
        });
      });
  };
};
//
export const getFeaturedMasterDoctor = (limit = 0, to = 5, callback) => {
  return async (dispatch) => {
    axiosInstance
      .get(`knwlgmastersessionnew/featuredDoctorsList?from=${limit}&to=${to}`)
      .then((response) => {
        callback(response.data);
        dispatch({
          type: sessionConstants.GET_FEATURED_MASTER_DOC_DATA_SUCCESS,
          payload: response.data.data
        });
      })
      .catch((error) => {
        if (error == 'loginAgain') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
        dispatch({
          type: sessionConstants.GET_FEATURED_MASTER_DOC_DATA_FALIURE,
          payload: error
        });
      });
  };
};
export const getMasterDoctorData = (doctorId, callback) => {
  return async (dispatch) => {
    axiosInstance
      .get(`sessionDoc/featuredDoctorsDetail/${doctorId}`)
      .then((response) => {
        callback(response.data);
        dispatch({
          type: sessionConstants.GET_DOCTOR_DATA_SUCCESS,
          payload: response.data.data
        });
      })
      .catch((error) => {
        if (error == 'loginAgain') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
        dispatch({
          type: sessionConstants.GET_DOCTOR_DATA_FALIURE,
          payload: error
        });
      });
  };
};
export const postQuery = (jsonObj, callback) => {
  return async (dispatch) => {
    axiosInstance
      .post('sessionDoc/submitrequestfeatureddoctor', jsonObj)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        if (error == 'loginAgain') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
      });
  };
};
export const getQueries = (id, callback) => {
  return async (dispatch) => {
    axiosInstance
      .get(`sessionDoc/get_question_featured_doctor?id=${id}`)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        if (error == 'loginAgain') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
      });
  };
};
//https://developer.clirnet.com/knowledge/rnv17/knwlgmastersessionnew/get_question_featured_doctor?id=0
//https://developer.clirnet.com/knowledge/rnv16/knwlgmastersessionnew/fsubmitrequestfeatureddoctor
export const getSessionDetail = (id = 0, callback) => {
  return async (dispatch) => {
    axiosInstance
      .get(`knwlgmastersessionnew/sessiondetail_new?session_id=${id}${generateUtmExt()}`)
      .then((response) => {
        callback(response.data.data);
        dispatch({
          type: sessionConstants.GET_SESSION_DETAILS_DATA_SUCCESS,
          payload: response.data.data
        });
      })
      .catch((error) => {
        if (error == 'loginAgain') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
        dispatch({
          type: sessionConstants.GET_SESSION_DETAILS_DATA_FALIURE,
          payload: error
        });
      });
  };
};

export const reserveSession = (data = '', type = '', callback) => {
  console.log(data);
  let post_url = '';
  if (type == 'submit') {
    post_url = 'knwlgmastersessionnew/submitquery';
  } else if (type == 'multidayEdit') {
    post_url = 'multidaysession/requestquestion';
  } else {
    post_url = 'knwlgmastersessionnew/editquery';
  }
  return async (dispatch) => {
    axiosInstance
      .post(post_url, JSON.stringify(data))
      .then((response) => {
        callback(response.data);
        dispatch({
          type: sessionConstants.RESERVE_SESSION_SUCCESS,
          payload: response.data
        });
      })
      .catch((error) => {
        if (error == 'loginAgain') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
        dispatch({
          type: sessionConstants.RESERVE_SESSION_FALIURE,
          payload: error
        });
      });
  };
};

export const cancelReservation = (data = '', callback) => {
  return async (dispatch) => {
    axiosInstance
      .post('knwlgmastersessionnew/cancelsession', JSON.stringify(data))
      .then((response) => {
        callback(response.data);
        dispatch({
          type: sessionConstants.CANCEL_SESSION_SUCCESS,
          payload: response.data
        });
      })
      .catch((error) => {
        if (error == 'loginAgain') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
        dispatch({
          type: sessionConstants.CANCEL_SESSION_FALIURE,
          payload: error
        });
      });
  };
};
export const requestRecording = (session_id, recording_type, callback) => {
  return async (dispatch) => {
    axiosInstance
      .post('knwlgmastersessionnew/submitrecordingrequest', {
        session_id,
        recording_type
      })
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        if (error == 'loginAgain') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
      });
  };
};
export const requestJoining = (session_id, callback) => {
  return async (dispatch) => {
    axiosInstance
      .post('knwlgmastersessionnew/submitjoinrequest', {
        session_id
      })
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        if (error == 'loginAgain') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
      });
  };
};
//https://developer.clirnet.com/knowledge/rnv16/knwlgmastersessionnew/submitjoinrequest
//https://developer.clirnet.com/knowledge/rnv16/knwlgmastersessionnew/submitrecordingrequest

export const updateSessionDetaisData = (_data) => {
  return async (dispatch) => {
    dispatch({
      type: sessionConstants.UPDATE_SESSION_DETAILS_DATA,
      payload: _data
    });
  };
};

export const submitReview = (data = {}, callback) => {
  return async (dispatch) => {
    axiosInstance
      .post('knwlgmastersessionnew/submitsessionreviews', JSON.stringify(data))
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        if (error == 'loginAgain') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
      });
  };
};

export const requestSearchedSession = (data = {}, callback) => {
  return async (dispatch) => {
    axiosInstance
      .post('knwlgmastersessionnew/filterSession', data)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        if (error == 'loginAgain') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
      });
  };
};
export const getReservedCount = (callback) => {
  return async (dispatch) => {
    axiosInstance
      .get('knwlgmastersessionnew/upcomingsessionreserved')
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        if (error == 'loginAgain') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
      });
  };
};
//knwlgmastersessionnew/filterSession

export const upcomingSessionsByDoctor = (doctor_id, from, callback) => {
  return async (dispatch) => {
    axiosInstance
      .get(`sessionDoc/upcoming_sessions?session_doc_id=${doctor_id}&from=${from}&to=10`)
      .then((response) => {
        callback(response.data);
        dispatch({
          type: sessionConstants.GET_UPCOMING_SESSIONS_BY_DOCTOR_SUCCESS,
          payload: response.data
        });
      })
      .catch((error) => {
        if (error == 'loginAgain') {
          dispatch(logoutUser());
        }
        dispatch({
          type: sessionConstants.GET_UPCOMING_SESSIONS_BY_DOCTOR_FALIURE,
          payload: error
        });
        log('error', error);
        callback('error');
      });
  };
};

export const closedSessionsByDoctor = (doctor_id, from, callback) => {
  return async (dispatch) => {
    axiosInstance
      .get(`sessionDoc/closed_sessions?session_doc_id=${doctor_id}&from=${from}&to=10`)
      .then((response) => {
        callback(response.data);
        dispatch({
          type: sessionConstants.GET_PREVIOUS_SESSION_BY_DOCTOR_SUCCESS,
          payload: response.data
        });
      })
      .catch((error) => {
        if (error == 'loginAgain') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
        dispatch({
          type: sessionConstants.GET_PREVIOUS_SESSION_BY_DOCTOR_FALIURE,
          payload: error
        });
      });
  };
};
export const getPollOfLiveCme = (survey_ids, callback) => {
  return async (dispatch) => {
    axiosInstance
      .get(`survey/listcmestreamingsurvey?survey_ids=${survey_ids}`)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        if (error == 'loginAgain') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
      });
  };
};

export const sendLiveSessionPageViewTime = (jsonObj, callback) => {
  return async (dispatch) => {
    axiosInstance
      .post('Knwlgmastersessionnew/tracklivesession', jsonObj)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        if (error == 'loginAgain') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
      });
  };
};
export const activateMultidaySchedule = (session_id, callback) => {
  return async (dispatch) => {
    let formdata = new FormData();
    formdata.append('session_id', session_id);
    let response = await axiosInstance.post('multidaysession/schedule', formdata);
    console.log(response);
    if (response) {
      callback(response?.data);
    }
  };
};
//upcomingsessionreserved
//https://developer.clirnet.com/knowledge/rnv22/survey/listcmestreamingsurvey?survey_ids=3,4
export const setRedirectUrl = (payload) => {
  return {
    type: sessionConstants.SET_REDIRECT_URL,
    payload
  };
};
