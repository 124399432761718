import React from 'react';
import { Modal } from 'react-bootstrap';
import { ClirnetFullLogo, ClirnetIconLogo } from '../icons';

const OtaUpdateModal = ({ title, message = '', fileUrl, handleClose, show }) => {
  console.log('title', title, message, fileUrl);
  return (
    <Modal className="" fullscreen={true} show={show}>
      {/* <Modal.Header className="position-relative border-0 p-4 mb-1">
        <Modal.Title className="fs-4 text-black fw-semibold">{title}</Modal.Title>
      </Modal.Header> */}
      <Modal.Body className="p-20 bg-extra-light">
        <div className="d-flex align-items-center h-100 ">
          <div className="d-flex flex-column w-100 gap-5 text-center">
            <div className=" d-flex flex-column align-items-center justify-content-center">
              <ClirnetIconLogo
                className="icon-size-80"
                gtmTagVariable="gtm_cl_header_clirnet_logo"
                id="header_desktop_clirnet_full_logo"
              />
              {<h4 className="fs-14 text-black fw-semibold mt-3">Updating...</h4>}
            </div>

            <div className="mt-20 rounded-3 overflow-hidden mx-auto" style={{ maxWidth: '280px' }}>
              {
                <img
                  src={
                    fileUrl
                      ? fileUrl
                      : 'https://img-cdn.clirnet.com/background-graphics/update_h85kzpV5Ah.jpeg'
                  }
                  alt=""
                  className="w-100 h-auto "
                />
              }
            </div>
            {message ? (
              <p>{message}...</p>
            ) : (
              <p>Spreading happiness while your App updates itself...</p>
            )}
            <div>
              <svg
                className="icon-size-80"
                version="1.1"
                id="L5"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                enableBackground="new 0 0 0 0"
                // xml:space="preserve"
              >
                <circle fill="#214180" stroke="none" cx="6" cy="50" r="6">
                  <animateTransform
                    attributeName="transform"
                    dur="1s"
                    type="translate"
                    values="0 15 ; 0 -15; 0 15"
                    repeatCount="indefinite"
                    begin="0.1"
                  />
                </circle>
                <circle fill="#214180" stroke="none" cx="30" cy="50" r="6">
                  <animateTransform
                    attributeName="transform"
                    dur="1s"
                    type="translate"
                    values="0 10 ; 0 -10; 0 10"
                    repeatCount="indefinite"
                    begin="0.2"
                  />
                </circle>
                <circle fill="#214180" stroke="none" cx="54" cy="50" r="6">
                  <animateTransform
                    attributeName="transform"
                    dur="1s"
                    type="translate"
                    values="0 5 ; 0 -5; 0 5"
                    repeatCount="indefinite"
                    begin="0.3"
                  />
                </circle>
              </svg>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default OtaUpdateModal;
