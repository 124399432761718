import axios from 'axios';
import dayjs from 'dayjs';
import { jwtDecode } from 'jwt-decode';
import packageJson from '../../../package.json';
import { deleteCaches, getLocalStorage, isMobileApp, setLocalStorage } from '../../common/common';
const baseURL = process.env.REACT_APP_API_URL;
const authService = process.env.REACT_APP_API_URL;
const apiVersion = packageJson.version;

let accessToken = '';
accessToken = getLocalStorage('doctube_access_token', '')
  ? getLocalStorage('doctube_access_token', '')
  : null;
const doctubeApiInstance = axios.create({
  baseURL,
  headers: {
    Authorization: getLocalStorage('doctube_access_token', ''),
    version: isMobileApp() ? `rjsa ${apiVersion}` : `rjsw ${apiVersion}`
  }
});
const clearLocalStorage = () => {
  deleteCaches();
  localStorage.clear();
  sessionStorage.clear();
};
const obtainNewAccessToken = async () => {
  try {
    const response = await axios.post(`${authService}authdoctube/getAccessToken`, {
      token: getLocalStorage('doctube_refresh_token', '')
    });
    if (response.data.status_code == 200) {
      setLocalStorage('doctube_access_token', response.data.data.token);
      return response.data.data.token;
    }
  } catch (error) {
    try {
      const response = await axios.post(`${authService}authdoctube/getRefreshToken`, {
        token: getLocalStorage('doctube_refresh_token', '')
      });
      if (response.data.status_code == 200) {
        setLocalStorage('doctube_access_token', response.data.data.token);
        setLocalStorage('doctube_refresh_token', response.data.data.refresh_token);
      }
      return response.data.data.token;
    } catch (error) {
      clearLocalStorage();
      return Promise.reject('loginAgain');
    }
  }
};
const refreshExpiredTokenClosure = () => {
  let isCalled = false;
  let runningPromise = undefined;
  return () => {
    if (isCalled) {
      return runningPromise;
    } else {
      isCalled = true;
      runningPromise = obtainNewAccessToken();
      return runningPromise;
    }
  };
};

// stores the function returned by refreshExpiredTokenClosure
const refreshExpiredToken = refreshExpiredTokenClosure();

doctubeApiInstance.interceptors.request.use(
  async (req) => {
    accessToken = getLocalStorage('doctube_access_token', '')
      ? getLocalStorage('doctube_access_token', '')
      : null;
    req.headers.Authorization = accessToken || '';

    let access = ''; //jwtDecode(accessToken);
    access = jwtDecode(accessToken);
    let isExpired = '';
    isExpired = dayjs.unix(access.exp).diff(dayjs()) < 1;
    if (isExpired) {
      const updatedToken = await refreshExpiredToken();
      req.headers['Authorization'] = updatedToken;
      return req;
    }

    return req;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

doctubeApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },

  async (error) => {
    const originalRequest = error.config;

    // logout user's session if refresh token api responds 401 UNAUTHORIZED
    if (
      error?.response?.status === 401 &&
      originalRequest.url === `${authService}authdoctube/getAccessToken`
    ) {
      clearLocalStorage();
      doctubeApiInstance.defaults.headers = {};
      window.location.href = '/';
      return Promise.reject(error);
    }

    // if request fails with 401 UNAUTHORIZED status and 'Token has expired' as response message
    // then it calls the api to generate new access token
    if (error?.response?.status === 401) {
      const updatedToken = await refreshExpiredToken();
      originalRequest.headers['Authorization'] = updatedToken;
      return doctubeApiInstance(originalRequest);
    }

    return Promise.reject(error);
  }
);
export default doctubeApiInstance;
