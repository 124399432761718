import { generateUtmExt, log } from '../../common/common';
import { spqConstants } from '../constants/spq.constants';
import { logoutUser } from './login.action';
import axiosInstance from '../helper/axiosInstance';
import { commonConstants } from '../constants/commonConstants';
export const getByCategory = (category, fetchFrom, to, callback, extra = '') => {
  let api_url = '';
  if (category == 'all') {
    api_url = `survey/list?from=${fetchFrom}&to=${to}${extra}`;
  } else {
    api_url = `survey/list?category=${category}&from=${fetchFrom}&to=${to}${extra}`;
  }

  return async (dispatch) => {
    axiosInstance
      .get(api_url)
      .then((response) => {
        if (response.data) {
          callback(response.data);
          switch (category) {
            case 'all':
              dispatch({
                type: spqConstants.GET_ALL_SUCCESS,
                payload: response.data
              });
              break;
            case 'poll':
              dispatch({
                type: spqConstants.GET_POLL_SUCCESS,
                payload: response.data
              });
              break;
            case 'quiz':
              dispatch({
                type: spqConstants.GET_QUIZ_SUCCESS,
                payload: response.data
              });
              break;
            case 'survey':
              dispatch({
                type: spqConstants.GET_SURVEY_SUCCESS,
                payload: response.data
              });
              break;

            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
        switch (category) {
          case 'all':
            dispatch({
              type: spqConstants.GET_ALL_FAILURE,
              payload: error
            });
            break;
          case 'poll':
            dispatch({
              type: spqConstants.GET_POLL_FAILURE,
              payload: error
            });
            break;
          case 'quiz':
            dispatch({
              type: spqConstants.GET_QUIZ_FAILURE,
              payload: error
            });
            break;
          case 'survey':
            dispatch({
              type: spqConstants.GET_SURVEY_FAILURE,
              payload: error
            });
            break;

          default:
            break;
        }
      });
  };
};
export const getCompletedByCategory = (category, callback) => {
  let api_url = '';
  if (category == 'all') {
    api_url = 'survey/user_survey_list';
  } else {
    api_url = `survey/user_survey_list?category=${category}`;
  }

  return async (dispatch) => {
    axiosInstance
      .get(api_url)
      .then((response) => {
        if (response.data) {
          callback(response.data);
          switch (category) {
            case 'all':
              dispatch({
                type: spqConstants.GET_COMPLETED_ALL_SUCCESS,
                payload: response.data
              });
              break;
            case 'poll':
              dispatch({
                type: spqConstants.GET_COMPLETED_POLL_SUCCESS,
                payload: response.data
              });
              break;
            case 'quiz':
              dispatch({
                type: spqConstants.GET_COMPLETED_QUIZ_SUCCESS,
                payload: response.data
              });
              break;
            case 'survey':
              dispatch({
                type: spqConstants.GET_COMPLETED_SURVEY_SUCCESS,
                payload: response.data
              });
              break;

            default:
              break;
          }
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
        switch (category) {
          case 'all':
            dispatch({
              type: spqConstants.GET_COMPLETED_ALL_FAILURE,
              payload: error
            });
            break;
          case 'poll':
            dispatch({
              type: spqConstants.GET_COMPLETED_POLL_FAILURE,
              payload: error
            });
            break;
          case 'quiz':
            dispatch({
              type: spqConstants.GET_COMPLETED_QUIZ_FAILURE,
              payload: error
            });
            break;
          case 'survey':
            dispatch({
              type: spqConstants.GET_COMPLETED_SURVEY_FAILURE,
              payload: error
            });
            break;

          default:
            break;
        }
      });
  };
};
export const getSpqDetails = (id, callback) => {
  return async (dispatch) => {
    axiosInstance
      .get(`survey/detail?id=${id}${generateUtmExt()}`)
      .then((response) => {
        if (response.data) {
          callback(response.data);
          dispatch({
            type: spqConstants.GET_SPQ_DETAILS_SUCCESS,
            payload: response.data
          });
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
        dispatch({
          type: spqConstants.GET_SPQ_DETAILS_FAILURE,
          payload: error
        });
      });
  };
};
export const getSpqRelated = (id, pagination, callback) => {
  return async (dispatch) => {
    axiosInstance
      .get(`survey/related?survey_id=${id}`)
      .then((response) => {
        if (response.data) {
          callback(response.data);
          dispatch({
            type: spqConstants.GET_SPQ_RELATED_SUCCESS,
            payload: response.data
          });
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
        dispatch({
          type: spqConstants.GET_SPQ_RELATED_FAILURE,
          payload: error
        });
      });
  };
};
// survey/quizAnswer
export const postQuizAnswer = (id, jsonData, points_on_approval, callback) => {
  return async (dispatch) => {
    axiosInstance
      .post('survey/quizAnswer', {
        answer_json: jsonData,
        survey_id: id,
        points_on_approval
      })
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
      });
  };
};
export const postSaveSurvey = (id, question, jsonData, callback) => {
  return async (dispatch) => {
    axiosInstance
      .post('survey/surveySave', {
        answer_json: jsonData,
        question_no: question,
        survey_id: id
      })
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        log('error', error);
        callback('error');
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
      });
  };
};
export const getDetailedAnswer = (id, callback) => {
  return async (dispatch) => {
    axiosInstance
      .get(`survey/detailAnswer?survey_id=${id}`)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        log('error', error);
        callback('error');
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
      });
  };
};
export const getCompletionDetails = (id, callback) => {
  return async (dispatch) => {
    axiosInstance
      .get(`survey/detailCompleted?id=${id}`)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        log('error', error);
        callback('error');
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
      });
  };
};
export const getPendingDetails = (id, callback) => {
  return async (dispatch) => {
    axiosInstance
      .get(`survey/surveyIncompGet?id=${id}`)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        log('error', error);
        callback('error');
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
      });
  };
};
export const getPendingList = (callback) => {
  return async (dispatch) => {
    axiosInstance
      .get('survey/surveyIncompList')
      .then((response) => {
        if (response.data) {
          callback(response.data);
          dispatch({
            type: spqConstants.GET_PENDING_SUCCESS,
            payload: response.data
          });
        }
      })
      .catch((error) => {
        log('error', error);
        callback('error');
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        dispatch({
          type: spqConstants.GET_PENDING_FAILURE,
          payload: error
        });
      });
  };
};
export const getCompInCompCount = (status, callback) => {
  return async (dispatch) => {
    axiosInstance
      .get(`survey/${status}_count`)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        log('error', error);
        callback('error');
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
      });
  };
};
export const getUserPoints = (callback) => {
  return async (dispatch) => {
    axiosInstance
      .get('survey/userpoint')
      .then((response) => {
        if (response.data) {
          callback(response.data);
          dispatch({
            type: commonConstants.GET_POINTS,
            payload: response?.data?.data ?? 0
          });
          dispatch({
            type: commonConstants.POINTS_API_CALLED
          });
        }
      })
      .catch((error) => {
        log('error', error);
        callback('error');
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
      });
  };
};
//survey/related?survey_id=933&from=0&to=5
//survey/detailAnswer?survey_id=815
//survey/detailCompleted?id=814
//surveyIncompGet?id=931
//survey/surveyIncompList
//survey/userpoint
