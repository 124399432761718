import { getLocalStorage, isMobileApp } from '../../common/common';
import packageJson from '../../../package.json';
const doctube_api_base = process.env.REACT_APP_API_URL;
const apiVersion = packageJson.version;
export const postDoctubeApiConfig = (url = '', data = {}) => {
  return {
    method: 'POST',
    url: doctube_api_base + url,
    headers: {
      Authorization: getLocalStorage('doctube_access_token', false)
        ? getLocalStorage('doctube_access_token', '')
        : '',
      version: isMobileApp() ? `rjsa ${apiVersion}` : `rjsw ${apiVersion}`
    },
    data: data
  };
};
export const getDoctubeApiConfig = (url = '') => {
  return {
    method: 'GET',
    url: doctube_api_base + url,
    headers: {
      Authorization: getLocalStorage('doctube_access_token', false)
        ? getLocalStorage('doctube_access_token', '')
        : ''
    },
    version: isMobileApp() ? `rjsa ${apiVersion}` : `rjsw ${apiVersion}`
  };
};
