import { getLocalStorage } from '../../common/common';
import { commonConstants } from '../constants/commonConstants';
import { dashboardConstants } from '../constants/dashboard.constant';
let initialState = {
  comments: [],
  replyComments: [],
  points: '',
  point_api_called: false,
  marquee: [],
  marquee_called: false,
  current_menu: '',
  cta_data: [],
  cta_loading: false,
  is_manual_cta_called: false,
  renderHeader: false,
  reference: false,
  isSearchOpen: false,
  isMoreMobileOpen: false,
  searched_data: [],
  related_videos: [],
  user_details: getLocalStorage('user', ''),
  user_data: getLocalStorage('user', ''),
  is_comment_focused: false,
  exploreKey: null,
  loadingBar: false,
  showComment: true
};

function parseJson(str) {
  if (!str) return {};
  try {
    let parsed_data = JSON.parse(str);
    return parsed_data;
  } catch (e) {
    return {};
  }
}
function filterResponses(apiResponses) {
  const positionsWithSurvey = {}; // Keep track of encountered positions with "survey"
  const filteredResponses = [];

  apiResponses.forEach((response) => {
    const position = response.position;
    const ctaType = response.cta_type;

    let isPoll = ctaType === 'survey' && response?.details[0]?.category === 'poll';

    if (isPoll) {
      if (!positionsWithSurvey[position]) {
        // If this is the first "survey" at this position, add it to the filteredResponses
        filteredResponses.push(response);
        positionsWithSurvey[position] = true;
      }
    } else {
      // For other cta_types, add them directly to the filteredResponses
      filteredResponses.push(response);
    }
  });

  return filteredResponses;
}

export const CommonReducer = (state = initialState, action) => {
  switch (action.type) {
    case commonConstants.GET_COMMENTS_SUCCESS:
      return {
        ...state,
        comments: [...state.comments, ...action.payload.data]
      };

    case commonConstants.GET_COMMENTS_FALIURE:
      return {
        ...state,
        error: action?.payload?.message || '',
        comments: []
      };

    case commonConstants.GET_REPLY_COMMENTS_SUCCESS:
      if (action.payload.data.length > 0) {
        return {
          ...state,
          replyComments: [...state.replyComments, ...action.payload.data]
        };
      } else return;

    case commonConstants.GET_REPLY_COMMENTS_FALIURE:
      return {
        ...state,
        error: action?.payload?.message || '',
        replyComments: []
      };
    case commonConstants.GET_POINTS:
      return {
        ...state,
        points: action.payload
      };
    case commonConstants.POINTS_API_CALLED:
      return {
        ...state,
        point_api_called: true
      };
    case commonConstants.UPDATE_CURRENT_MODULE:
      return {
        ...state,
        current_menu: action.payload
      };
    case dashboardConstants.MARQUEE_SUCCESS:
      return {
        ...state,
        marquee: action.payload.data
      };
    case dashboardConstants.MARQUEE_FAILURE:
      return {
        ...state,
        error: action?.payload?.message || ''
      };
    case dashboardConstants.MARQUEE_CALLED:
      return {
        ...state,
        marquee_called: true
      };
    case commonConstants.CTA_FETCH_LOADING:
      return {
        ...state,
        cta_loading: true
      };
    case commonConstants.CTA_FETCH_FAILED:
      return {
        ...state,
        cta_data: [],
        cta_loading: false,
        is_manual_cta_called: false
      };
    case commonConstants.CTA_FETCH_SUCCESS:
      let temp = action?.payload?.data?.length > 0 ? action?.payload?.data : [];
      let modifiedtemp = temp.map((x) => {
        return {
          ...x,
          settings: x?.settingsJson?.trim() ? parseJson(x?.settingsJson) : null,
          form: x?.formJson?.trim() ? parseJson(x?.formJson) : null
        };
      });

      let surveyFiltration = filterResponses(modifiedtemp);
      console.log('surveyFiltration', surveyFiltration);

      // there will not be more than one cta_type survey in each position

      return {
        ...state,
        cta_data: surveyFiltration ?? [],
        cta_loading: false,
        is_manual_cta_called: true
      };

    case commonConstants.ADD_TO_CTA:
      console.log('payload from ADD_TO_CTA', action.payload);
      return {
        ...state,
        cta_data: [...state.cta_data, ...action.payload]
      };

    case commonConstants.CTA_VIEWED:
      let updatedCtaData = state.cta_data?.map((item) => {
        if (item.id === action.payload) {
          return { ...item, viewed: true };
        }
        return item;
      });
      return {
        ...state,
        cta_data: updatedCtaData
      };
    case 'CLEAR_CTA':
      return {
        ...state,
        cta_data: [],
        is_manual_cta_called: false
      };
    case commonConstants.RENDER_HEADER:
      return {
        ...state,
        renderHeader: !state.renderHeader
      };
    case commonConstants.REFERENCE_HANDLER:
      return {
        ...state,
        reference: action.payload
      };
    case commonConstants.TOGGLE_SEARCH_OPEN:
      return {
        ...state,
        isSearchOpen: action.payload
      };
    case commonConstants.TOGGLE_MORE_MOBILE:
      return {
        ...state,
        isMoreMobileOpen: action.payload
      };
    case 'RECENT_SEARCHES':
      return {
        ...state,
        searched_data: action.payload
      };
    case commonConstants.RELATED_FETCH_SUCCESS:
      return {
        ...state,
        related_videos: action.payload
      };

    case commonConstants.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        user_details: action.payload,
        user_data: action.payload
      };

    case commonConstants.RESET_STORE:
      return initialState;

    case commonConstants.TOGGLE_COMMENT_FOCUS:
      return {
        ...state,
        is_comment_focused: action.payload
      };
    case commonConstants.TOGGLE_EXPLORE_KEY:
      return {
        ...state,
        exploreKey: action.payload
      };
    case commonConstants.TOGGLE_LOADING_BAR:
      return {
        ...state,
        loadingBar: action.payload
      };
    case commonConstants.SHOW_COMMENTS:
      return {
        ...state,
        showComment: action.payload
      };
    default:
      return state;
  }
};
