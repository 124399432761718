import { getLocalStorage } from '../../common/common';
const userDetails = () => getLocalStorage('user', {});
export const SpecialityArray = [1252, 5, 6, 12, 1254, 7, 19, 29, 1, 25];

export const PreDefSpec = () => [
  {
    master_specialities_id: 5,
    specialities_name: 'Obstetrics & Gynaecology'
  },
  {
    master_specialities_id: 6,
    specialities_name: 'Internal Medicine'
  },
  {
    master_specialities_id: 12,
    specialities_name: 'Paediatrics'
  },
  {
    master_specialities_id: 1254,
    specialities_name: 'COVID-19'
  },
  {
    master_specialities_id: 7,
    specialities_name: 'Pulmonology'
  },
  {
    master_specialities_id: 19,
    specialities_name: 'General Surgery'
  },
  {
    master_specialities_id: 29,
    specialities_name: 'General'
  },
  {
    master_specialities_id: 1,
    specialities_name: 'Diabetology'
  },
  {
    master_specialities_id: 25,
    specialities_name: 'Critical Care'
  }
];

const userSpecialities = () =>
  userDetails()?.speciality ? JSON.parse(userDetails()?.speciality ?? '[]') : [];
export const unionWidthDefinedSpecs = (topSpecialities, callback) => {
  if (userSpecialities()?.length > 0) {
    let userSpeciality = userSpecialities();
    for (let _spec of topSpecialities) {
      let isAlreadyPresent = userSpeciality?.some(
        (sp) => sp.master_specialities_id == _spec.specialities_id
      );
      if (!isAlreadyPresent) {
        userSpeciality.push({
          master_specialities_id: _spec.specialities_id,
          specialities_name: _spec.specialities_name
        });
      }
    }
    callback(userSpeciality);
  } else {
    callback(topSpecialities);
  }
};
