import { autoAuthConstants } from '../constants/autoAuthConstants';

export const AutoauthReducer = (state = {}, action) => {
  switch (action.type) {
    case autoAuthConstants.TOKEN_VALIDATE_SUCCESS:
    case autoAuthConstants.TOKEN_VALIDATE_FALIURE:
    case autoAuthConstants.AUTOAUTH_SIGNUP_SUCCESS:
    case autoAuthConstants.AUTOAUTH_SIGNUP_FALIURE:
      return {
        ...state
      };

    // case autoAuthConstants.TOKEN_VALIDATE_FALIURE:
    //   return {
    //     ...state
    //   };

    // case autoAuthConstants.AUTOAUTH_SIGNUP_SUCCESS:
    //   return {
    //     ...state
    //   };

    // case autoAuthConstants.AUTOAUTH_SIGNUP_FALIURE:
    //   return {
    //     ...state
    //   };

    default:
      return state;
  }
};
