// export const apifallback = [
//   {
//     authorisedURL: 'knwlg/feedDetail',
//     openAPIURL:"openapi/service?type=comp&type_id=100&utm_source=ANANT_DOCTUBE_AUTOAUTH&referal_code=&user_type=doctor_master&user_id=1"
//   }
// ];
import appConfig from '../../config/config';
const baseURL = appConfig.apiLoc;

export function generateApiFallback(authorisedURL) {
  if (authorisedURL) {
    if (authorisedURL.startsWith('knwlg/feedDetail')) {
      const url = new URL(authorisedURL, baseURL);
      return 'openapi/openapi_comp' + url.search;
    }
    return null;
  }
}
