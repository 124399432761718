import React, { memo } from 'react';
import { isMobile } from 'react-device-detect';
import { Outlet } from 'react-router-dom';
import MobileFooter from '../components/footer/MobileFooter';
import HeaderDesktop from '../components/header/HeaderDesktop';
import HeaderMobileDetails from '../components/header/HeaderMobileDetails';
import { useSelector } from 'react-redux';
import { isIosApp } from '../common/common';
// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 *
 */
const DetailsPageLayout = () => {
  const baseClass = useSelector((state) => state.settings.baseClass);
  return (
    <div className={baseClass}>
      {isIosApp() == true ? <div className="iosSpecial-safe-area-top w-100"></div> : <></>}
      {isMobile ? <HeaderMobileDetails /> : <HeaderDesktop />}
      <Outlet className={isMobile ? 'p-5' : ''} />
    </div>
  );
};

DetailsPageLayout.propTypes = propTypes;
// #endregion

export default memo(DetailsPageLayout);
