import React from 'react';
// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 *
 */

const fallback_container = {
  height: '100px',
  width: '100px',
  position: 'fixed',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%,-50%)'
};
const LoadingFallback = () => {
  return (
    <div style={fallback_container}>
      <svg
        version="1.1"
        id="L7"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        enableBackground="new 0 0 100 100"
        xmlSpace="preserve"
      >
        <path
          fill="#4FA69F"
          d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3
  c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="2s"
            from="0 50 50"
            to="360 50 50"
            repeatCount="indefinite"
          />
        </path>
        <pattern id="image" x="0%" y="0%" height="100%" width="100%" viewBox="0 0 100 100">
          <image
            x="0%"
            y="0%"
            width="100"
            height="100"
            xlinkHref="https://img-cdn.clirnet.com/medwiki/images/clirnet_logo.jpg"
          ></image>
        </pattern>
        <circle id="sd" className="medium" cx="50%" cy="50%" r="20%" fill="url(#image)" />
      </svg>
    </div>
  );
};

LoadingFallback.propTypes = propTypes;
// #endregion

export default LoadingFallback;
