import axios from 'axios';
import { autoAuthConstants } from '../constants/autoAuthConstants';
import packageJson from '../../../package.json';
import { logoutUser } from './login.action';
import { generateUtmExt, getLocalStorage, log } from '../../common/common';
import axiosInstance from '../helper/axiosInstance';
import axiosOpenInstance from '../helper/axiosOpenInstance';
const apiVersion = packageJson.version;

const url = process.env.REACT_APP_API_URL;

export const askQuestionTopicMl = (jsonObj, callback) => {
  return (dispatch) => {
    axiosInstance
      .post('bot/askQuestionGetTopicML', jsonObj)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('Error', error);
        callback('error');
      });
  };
};

export const askQuestionMl = (jsonObj, callback) => {
  return (dispatch) => {
    axiosInstance
      .post('bot/askQuestionML', jsonObj)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('Error', error);
        callback('error');
      });
  };
};

export const historyQaList = (callback) => {
  return (dispatch) => {
    axiosInstance
      .get(`bot/historyQNAlist${generateUtmExt()}`)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('Error', error);
        callback('error');
      });
  };
};

export const historyQNAbyTopic = (id, callback) => {
  return (dispatch) => {
    axiosInstance
      .get(`bot/historyQNAbyTopic?topic_id=${id}${generateUtmExt()}`)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('Error', error);
        callback('error');
      });
  };
};

export const askQuestionDiagnosisTopicMl = (jsonObj, callback, isPublic) => {
  return (dispatch) => {
    let postObj = { ...jsonObj, utm_source: getLocalStorage('utm_source', '') };
    let promise = isPublic
      ? axiosOpenInstance.post(url + 'openbot/askDiagnosisGetTopicML', postObj)
      : axiosInstance.post('bot/askDiagnosisGetTopicML', postObj);
    promise
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('Error', error);
        callback('error');
      });
  };
};

export const askQuestionDiagnosisMl = (jsonObj, callback, isPublic) => {
  return (dispatch) => {
    let postObj = { ...jsonObj, utm_source: getLocalStorage('utm_source', '') };
    let promise = isPublic
      ? axiosOpenInstance.post(url + 'openbot/askDiagnosisML', postObj)
      : axiosInstance.post('bot/askDiagnosisML', postObj);
    promise
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('Error', error);
        callback('error');
      });
  };
};
export const postBotAnswerLike = (jsonObj, callback) => {
  return (dispatch) => {
    axiosInstance
      .post('bot/answerDiagnosisLike', jsonObj)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('Error', error);
        callback('error');
      });
  };
};
//answerDiagnosisLike
