import React, { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { getLocalStorage, setLocalStorage } from '../common/common';
import { useDispatch, useSelector } from 'react-redux';
import { loginSuccess } from '../Store/actions/login.action';
import { saveUtm } from '../Store/actions/shareActions';
import { routeNames } from '../router/constants';
let params;
const ShareRoute = ({ children, redirectTypeRoute }) => {
  params = useParams();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.common.user_details);

  useEffect(() => {
    if (params?.utm) {
      dispatch(saveUtm(params.utm));
      setLocalStorage('utm_source', params.utm);
    }
    if (getLocalStorage('isLoggedIn', false)) {
      dispatch(loginSuccess());
    }
  }, []);
  return userDetails === '' ? (
    children
  ) : 'user_master_id' in userDetails && redirectTypeRoute == routeNames.election.landing ? (
    <Navigate
      to={`${redirectTypeRoute}/${params?.election_id}`}
      replace
      state={{ from_login: true }}
    />
  ) : (
    'user_master_id' in userDetails && (
      <Navigate
        to={`${redirectTypeRoute}${params.id ? `/${params?.id}` : ''}`}
        replace
        state={{ from_login: true }}
      />
    )
  );

  // return null;
};
export default ShareRoute;
