export const DOCTUBE_VIDEO_LISTING = 'DOCTUBE_VIDEO_LISTING';
export const DOCTUBE_DOCTOR_DETAILS = 'DOCTUBE_DOCTOR_DETAILS';
export const DOCTUBE_DOCTOR_VIDEO_COUNT = 'DOCTUBE_DOCTOR_VIDEO_COUNT';
export const DOCTUBE_CHANNEL_DETAILS = 'DOCTUBE_CHANNEL_DETAILS';
export const EDIT_CHANNEL = 'EDIT_CHANNEL';
export const DOCTUBE_CLINICS = 'DOCTUBE_CLINICS';
export const ADD_CLINIC = 'ADD_CLINIC';
export const EDIT_CLINIC = 'EDIT_CLINIC';
export const CATEGORY_LIST = 'CATEGORY_LIST';
export const ATTRIBUTE_LIST = 'ATTRIBUTE_LIST';
export const LANGUAGE_LIST = 'LANGUAGE_LIST';
export const DOCTUBE_DOCTOR_ANALYTICS_METRIX = 'DOCTUBE_DOCTOR_ANALYTICS_METRIX';
export const DOCTUBE_DOCTOR_INSHIGHT_DETAILS = 'DOCTUBE_DOCTOR_INSHIGHT_DETAILS';
export const DOCTUBE_DOCTOR_MOST_VIEWED = 'DOCTUBE_DOCTOR_MOST_VIEWED';
export const DOCTUBE_OPEN_TRENDING_CATEGORY = 'DOCTUBE_OPEN_TRENDING_CATEGORY';
export const DOCTUBE_MANAGE_PLAYLIST = 'DOCTUBE_MANAGE_PLAYLIST';
export const DOCTUBE_SEARCH_PLAYLIST = 'DOCTUBE_SEARCH_PLAYLIST';
export const DOCTUBE_MANAGE_PLAYLIST_DETAILS = 'DOCTUBE_MANAGE_PLAYLIST_DETAILS';
export const DOCTUBE_EDIT_PLAYLIST = 'DOCTUBE_EDIT_PLAYLIST';
