import { SET_SPQ_QUEUE_ITEM, CLEAN_SPQ_QUEUE_ITEM } from '../constants/videoPlayer.constants';

const initialState = {
  spqItem: null
};
export const videoPlayerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SPQ_QUEUE_ITEM:
      return {
        ...state,
        spqItem: action.payload
      };
    case CLEAN_SPQ_QUEUE_ITEM:
      return {
        ...state,
        spqItem: ''
      };
    default:
      return state;
  }
};
