import React from 'react';
import Loadable from '../Loadable';

const AutoAuth = Loadable({
  loader: () => import('../../../src/pages/Autoauth/autoauth')
});

export const autoAuthRoutes = [
  {
    path: '/autoauth/:content_type/:content_id/:utm_source',
    component: <AutoAuth />,
    module: 'generic'
  },
  {
    path: '/autoauth/:content_type/:content_id/:utm_source/:user_type/:user_id',
    component: <AutoAuth />,
    subPath: ['', ':token'],
    module: 'generic'
  }
];
