import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import axiosInstance from '../../Store/helper/axiosInstance';
import {
  MULTIDAY_NOTIFICATION_SESSIONS,
  MULTIDAY_SESSION_DETAILS,
  MULTIDAY_INFINITE,
  STREAM_DATA,
  MULTIDAY_SESSION_CERT_TEMPLATE,
  CHILD_SESSION_QUESTIONS,
  PHOTOBOOTH_TEMPLATE
} from '../QueryKeys/multiday.key';
import { generateUtmExt, log } from '../../common/common';
import { IS_MULTIDAY_SESSION } from '../QueryKeys/session.key';
import { isMobile } from 'react-device-detect';
// import { Ebooks_Details, Ebooks_Listing } from '../../../Queries/QueryKeys/ebook.key';
const staleTime = 300000;
const cacheTime = 600000;

export const useMultidaySessionDetails = (id = '', onSuccess, onError) => {
  return useQuery(
    [MULTIDAY_SESSION_DETAILS, id],
    () => axiosInstance.get(`knwlgmastersessionnew/sessiondetail_new?session_id=${id}`),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      onSuccess,
      onError,
      select: (response) => response.data?.data,

      cacheTime: cacheTime
    }
  );
};
export const useIsMultidaySession = (id = '', onSuccess, onError) => {
  return useQuery(
    [IS_MULTIDAY_SESSION, id],
    () => axiosInstance.get(`knwlgmastersessionnew/sessiondetail_new?session_id=${id}`),
    {
      refetchOnWindowFocus: false,
      onSuccess,
      onError,
      select: (response) => response.data?.data,
      staleTime: 600000,
      cacheTime: cacheTime,
      enabled: !!id
    }
  );
};
export const useMultidayInfinityScrollData = () => {
  return useInfiniteQuery(
    MULTIDAY_INFINITE,
    ({ pageParam = 0 }) => axiosInstance.get(`dashboard/trending?from=${pageParam}&to=5&type=all`),
    {
      refetchOnWindowFocus: false,
      // enabled: !!inView,
      staleTime: staleTime,
      cacheTime: cacheTime,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.data?.length > 0) {
          // console.log(allPages.length);
          return allPages.length * 5;
        } else {
          return undefined;
        }
      }
    }
  );
};

export const useMultidayNotificationSessions = (id = '', onSuccess, onError) => {
  return useQuery(
    [MULTIDAY_NOTIFICATION_SESSIONS, id],
    () => axiosInstance.get(`multidaysession/getnotification?session_id=${id}`),
    {
      refetchOnWindowFocus: false,
      onSuccess,
      onError,
      select: (response) => response.data?.data?.filter((_s) => _s.session_id != id),
      cacheTime: cacheTime
    }
  );
};
//multidaysession/notificationstatus
const reserveParentSession = (data) => {
  return axiosInstance.post('multidaysession/reserve', data);
};

export const useReserveParentSession = (session_id, onSuccess, onError) => {
  const queryClient = useQueryClient();

  return useMutation(reserveParentSession, {
    onMutate: async (obj) => {
      const queryKeysArr = [MULTIDAY_SESSION_DETAILS, session_id];
      const previousData = queryClient.getQueryData(queryKeysArr);

      queryClient.setQueryData(queryKeysArr, (oldQueryData) => {
        let temp_data = oldQueryData;
        temp_data.data.data.parent_session.is_booked = true;
        return temp_data;
      });
      return { previousData };
    },
    onError: (_err, _obj, context) => {
      // queryClient.setQueryData([CL_VIDEO_DETAILS, type_id], context.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries([MULTIDAY_SESSION_DETAILS, session_id]);
    },
    onSuccess
  });
};

const postNotificationStatus = (data) => {
  return axiosInstance.post('multidaysession/notificationstatus', data);
};

export const useChangeNotificationStatus = (session_id) => {
  const queryClient = useQueryClient();

  return useMutation(postNotificationStatus, {
    onMutate: async (obj) => {
      // console.log('change-notification', obj);
      const queryKeysArr = [MULTIDAY_NOTIFICATION_SESSIONS, session_id];
      const previousData = queryClient.getQueryData(queryKeysArr);
      queryClient.setQueryData(queryKeysArr, (oldQueryData) => {
        let temp_data = oldQueryData;
        let exactSession = temp_data.data.data.findIndex(
          (_s) => _s.session_id == obj.get('session_id')
        );

        temp_data.data.data[exactSession].status =
          temp_data.data.data[exactSession].status == 0 ? 1 : 0;
        return temp_data;
      });
      return { previousData };
    },
    onError: (_err, _obj, context) => {
      // queryClient.setQueryData([CL_VIDEO_DETAILS, type_id], context.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries([MULTIDAY_NOTIFICATION_SESSIONS, session_id]);
    }
  });
};
//multidaysession/reserve
//multidaysession/notificationstatus
export const useMultidayStreamData = (id, onSuccess, onError) => {
  //knwlgmastersessionnew/sessiondetail_new?session_id=${id}${generateUtmExt()}
  ///knwlgmastersessionnew/sessiondetail_new?session_id=9649
  //multidaysession/getcertificatetemplate?session_id=9649
  return useQuery(
    STREAM_DATA,
    () =>
      axiosInstance.get(
        `knwlgmastersessionnew/sessiondetail_new?session_id=${id}${generateUtmExt()}`
      ),
    {
      refetchOnWindowFocus: false,
      onSuccess,
      onError,
      select: (response) => {
        const dataMain = response?.data?.data;
        return dataMain;
      },
      cacheTime: cacheTime,
      staleTime: staleTime
    }
  );
};
export const useCertificateTemplate = (id, onSuccess, onError) => {
  return useQuery(
    [MULTIDAY_SESSION_CERT_TEMPLATE, id],
    () =>
      axiosInstance.get(
        `multidaysession/getcertificatetemplate?session_id=${id}${generateUtmExt()}`
      ),
    {
      refetchOnWindowFocus: false,
      onSuccess,
      onError,
      select: (response) => {
        return response?.data?.data;
      },
      cacheTime: cacheTime
    }
  );
};
//multidaysession/getquestion?session_id=9649
export const useChildSessionQuestions = (id, onSuccess, onError) => {
  return useQuery(
    [CHILD_SESSION_QUESTIONS, id],
    () => axiosInstance.get(`multidaysession/getquestion?session_id=${id}`),
    // () => axiosInstance.post(`multidaysession/requestquestion`),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      onSuccess,
      onError,
      select: (response) => {
        return response?.data?.data;
      },
      cacheTime: cacheTime
    }
  );
};
export const usePhotoboothTemplate = (id, onSuccess, onError) => {
  return useQuery(
    [PHOTOBOOTH_TEMPLATE, id],
    () =>
      axiosInstance.get(
        `multidaysession/getphotoboothtemplate?session_id=${id}&viewmode=${
          isMobile ? 'mobile' : 'desktop'
        }`
      ),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      onSuccess,
      onError,
      select: (response) => {
        return response?.data?.data;
      },
      cacheTime: cacheTime
    }
  );
};
///multidaysession/getphotoboothtemplate?session_id=9649
