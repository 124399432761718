import {
  browserName,
  fullBrowserVersion,
  isMobile,
  mobileModel,
  mobileVendor,
  osName,
  osVersion
} from 'react-device-detect';
import { automatedCtaConstants } from '../constants/automatedCta.constant';
import axiosInstance from '../helper/axiosInstance';
import { logoutUser } from './login.action';
import packageJson from '../../../package.json';
import { setLocalStorage, log } from '../../common/common';

const appVersion = packageJson.version;
const automatedCtaSuccess = (response_cta) => {
  return {
    type: automatedCtaConstants.AUTOMATED_CTA_SUCCESS,
    payload: response_cta?.length > 0 ? response_cta : []
  };
};
const settingsSuccess = (settings) => {
  return {
    type: automatedCtaConstants.SETTINGS,
    payload: settings
  };
};
const automatedCtaFailure = () => {
  return {
    type: automatedCtaConstants.AUTOMATED_CTA_FAILURE,
    payload: []
  };
};
const userActionList = (userActionList) => {
  return {
    type: automatedCtaConstants.USER_ACTION_LIST,
    payload: userActionList?.length > 0 ? userActionList : []
  };
};
export const fetchAutomatedCtaInfo = (callback) => {
  return async (dispatch) => {
    axiosInstance
      .get('automatedcta/getCta')
      .then((response) => {
        if (response.data) {
          axiosInstance
            .get('automatedcta/ctauseractionlist')
            .then((resp) => {
              console.log('USER_ACTION_LIST', resp.data?.data);
              resp?.data?.data && dispatch(userActionList(resp?.data?.data));
              let response_cta = response?.data?.data?.cta_list;
              let settings = response?.data?.data?.settings[0]?.json
                ? JSON.parse(response?.data?.data?.settings[0]?.json)
                : null;
              callback(response_cta?.length > 0 ? response_cta : []);
              dispatch(automatedCtaSuccess(response_cta));
              dispatch(settingsSuccess(settings));
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((error) => {
        callback('error');
        dispatch(automatedCtaFailure());
      });
  };
};
//https://dockerapidev.clirnet.com/rnv34/automatedcta/click_report
const getClientDeviceDetails = () => {
  return {
    platform: (osName ?? 'NOT_FOUND') + ' ' + (osVersion ?? 'NOT_FOUND'),
    version: appVersion || 'NOT_FOUND',
    browser: browserName || 'NOT_FOUND',
    browser_version: fullBrowserVersion || 'NOT_FOUND',
    mobilemodel: isMobile ? mobileVendor + ' ' + mobileModel : 'NOT_MOBILE'
  };
};
export const postAutomatedCtaUserAnalytics = (
  cta_id = '',
  cta_type_id = '',
  entity_id = '',
  entity_value = '',
  action = '',
  view_page_type = '',
  view_page_id = '',
  callback
) => {
  return async (dispatch) => {
    axiosInstance
      .post('automatedcta/click_report', {
        cta_id,
        cta_type_id,
        entity_id,
        entity_value,
        action,
        view_page_type,
        view_page_id,
        device_detail: getClientDeviceDetails()
      })
      .then((response) => {
        if (response.data) {
          callback(response.data);
          axiosInstance
            .get('automatedcta/ctauseractionlist')
            .then((resp) => {
              console.log('USER_ACTION_LIST', resp.data?.data);
              resp?.data?.data && dispatch(userActionList(resp?.data?.data));
            })
            .catch((e) => console.log(e));
        }
      })
      .catch((error) => {
        callback('error');
      });
  };
};
export const postAutomatedCtaViewReport = (cta_id = '', entity_value = '', callback) => {
  return async (dispatch) => {
    axiosInstance
      .post('automatedcta/ctaviewreport', {
        cta_id,
        entity_value,
        device: getClientDeviceDetails()?.mobilemodel,
        browser_version:
          getClientDeviceDetails()?.browser + ' ' + getClientDeviceDetails()?.browser_version,
        os: getClientDeviceDetails()?.platform,
        appversion: getClientDeviceDetails()?.version
      })
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        callback('error');
      });
  };
};
export const getPlatformSpecialities = (callback) => {
  return async (dispatch) => {
    axiosInstance
      .get('automatedcta/getspeciality')
      .then((response) => {
        callback(response.data);
        let speciality = response.data?.data;
        dispatch({
          type: automatedCtaConstants.GET_ALL_SPECIALITIES_SUCCESS,
          payload: speciality?.length > 0 ? speciality : []
        });
        axiosInstance
          .get('automatedcta/servicelist')
          .then((resp) => {
            if (resp?.data?.data?.length > 0) {
              setLocalStorage('services', resp?.data?.data);
            }
          })
          .catch((e) => console.log(e));
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback(error);
        dispatch({
          type: automatedCtaConstants.GET_ALL_SPECIALITIES_FAILURE,
          payload: null
        });
      });
  };
};
//automatedcta/certificatesession?from=0&to=10

export const getCertificateSessions = (callback) => {
  return async (dispatch) => {
    axiosInstance
      .get('automatedcta/certificatesession?from=0&to=10')
      .then((response) => {
        callback(response?.data?.data ?? []);
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        console.log('error', error);
        callback(error);
      });
  };
};
