import React, { memo } from 'react';
import { isMobile } from 'react-device-detect';
import { Outlet, useLocation } from 'react-router-dom';
import MobileFooter from '../components/footer/MobileFooter';
import HeaderDesktop from '../components/header/HeaderDesktop';
import HeaderMobileMain from '../components/header/HeaderMobileMain';
import { useSelector } from 'react-redux';
import { isIosApp } from '../common/common';
import { routeNames } from '../router/constants';
import LoaderLine from '../components/LoaderLine';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 *
 */
const DefaultLayout = () => {
  const baseClass = useSelector((state) => state.settings.baseClass);
  const { pathname } = useLocation();

  const pageName = pathname.slice(1).toLowerCase();
  const current_menu = useSelector((state) => state.common.current_menu);
  const isDetailsPage = current_menu.includes('details');

  // alert(baseClass)
  return (
    <div className={baseClass}>
      <LoaderLine />

      {isIosApp() == true ? <div className="iosSpecial-safe-area-top w-100"></div> : <></>}
      {isMobile ? <HeaderMobileMain /> : <HeaderDesktop />}
      <Outlet />
      {/* {isMobile ? <MobileFooter /> : <></>} */}

      {(pageName !== 'speciality' ||
        pageName !== 'TrainingContent' ||
        !pathname?.includes(routeNames.course.content)) &&
        (isMobile ? (
          pageName !== 'trainingcontent' &&
          pageName !== 'profilesteps' &&
          !isDetailsPage && <MobileFooter />
        ) : (
          <></>
        ))}
    </div>
  );
};

DefaultLayout.propTypes = propTypes;
// #endregion

export default memo(DefaultLayout);
