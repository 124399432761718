import OneSignalReact from 'react-onesignal';
import { useDispatch } from 'react-redux';
import { getClientDeviceDetails, isMobileApp, setLocalStorage } from '../../common/common';
import { postOneSignalPlayerId } from '../../Store/actions';

const useOneSignalPlayerId = () => {
  const dispatch = useDispatch();
  // const user_master_id = () => getLocalStorage('user', {})?.user_master_id ?? 0;
  return () => {
    if (!isMobileApp()) {
      OneSignalReact.isPushNotificationsEnabled(function (isEnabled) {
        // OneSignalReact.addListenerForNotificationOpened()
        console.log('IS ENABLED', isEnabled);
        if (isEnabled) {
          // user has subscribed
          OneSignalReact.getUserId(function (userId) {
            console.log('player_id of the subscribed user is : ' + userId);
            if (userId) {
              setLocalStorage('one_signal_player_id', userId);
              //user_master_id,onesignal,os,appversion,browser,browserversion
              let formData = new FormData();
              // formData.append('user_master_id', user_master_id());
              formData.append('onesignal', userId);
              formData.append('os', getClientDeviceDetails('')?.platform);
              formData.append('appversion', getClientDeviceDetails('')?.version);
              formData.append('browser', getClientDeviceDetails('')?.browser);
              formData.append('browserversion', getClientDeviceDetails('')?.browser_version);
              dispatch(
                postOneSignalPlayerId(formData, (res) => {
                  console.log('RESPONSE', res);
                })
              );
            }
            // Make a POST call to your server with the user ID
          });
        }
      });
    } else {
      console.warn("This is mobile app. use cordova plugin")
    }
  };
};

export default useOneSignalPlayerId;
