import axios from 'axios';
import axiosInstance from '../helper/axiosInstance';
import { getDoctubeApiConfig, postDoctubeApiConfig } from '../helper/utils';
import { logoutUser } from './login.action';
import packageJson from '../../../package.json';
import doctubeApiInstance from '../helper/doctubeApiInstance';
import { isMobileApp } from '../../common/common';
import axiosOpenInstance from '../helper/axiosOpenInstance';
const doctube_api_base = process.env.REACT_APP_API_URL;
const base_url = process.env.REACT_APP_API_URL;
const apiVersion = packageJson.version;
export const checkUserAlreadyExists = (callback) => {
  return async (dispatch) => {
    axiosInstance
      .get('doctube/checkuser')
      .then((response) => {
        response?.data?.data ? callback(response?.data?.data ?? null) : callback(null);
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        console.log('error', error);
        callback(null);
      });
  };
};
export const checkUserExistanceForDoctubeShare = (token, callback) => {
  return async (dispatch) => {
    axiosOpenInstance({
      method: 'GET',
      url: `${base_url}doctube/checkuser`,
      headers: {
        Authorization: token,
        version: isMobileApp() ? `rjsa ${apiVersion}` : `rjsw ${apiVersion}`
      }
    })
      .then((response) => {
        response?.data ? callback(response?.data ?? null) : callback(null);
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        console.log('error', error);
        callback(null);
      });
  };
};
export const fetchChannelProfileDetails = (token, callback) => {
  return async (dispatch) => {
    axiosOpenInstance({
      method: 'GET',
      url: `${doctube_api_base}channel/detail`,
      headers: {
        Authorization: token,
        version: isMobileApp() ? `rjsa ${apiVersion}` : `rjsw ${apiVersion}`
      }
    })
      .then((response) => {
        response?.data?.data ? callback(response?.data?.data ?? null) : callback(null);
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        console.log('error', error);
        callback(null);
      });
  };
};
//doctubeApiInstance.get('channel/detail')
export const loginToDoctube = (user_id, smtoken, callback) => {
  // let tempData = new FormData();
  // tempData.append('user_id', user_id);
  // tempData.append('smtoken', smtoken);
  return async (dispatch) => {
    axiosOpenInstance({
      url: doctube_api_base + 'login/verifylogin',
      method: 'POST',
      data: {
        smtoken
      },
      headers: {
        version: isMobileApp() ? `rjsa ${apiVersion}` : `rjsw ${apiVersion}`
      }
    })
      .then((response) => {
        if (response.data) {
          callback(response.data?.data);
        }
      })
      .catch((err) => {
        console.log('error', err);
        callback(null);
      });
  };
};
export const registerChannelToDoctube = (data, callback) => {
  return async (dispatch) => {
    let formdata = new FormData();
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const element = data[key];
        formdata.append(key, element);
      }
    }
    axiosOpenInstance({
      url: doctube_api_base + 'login/signup',
      method: 'POST',
      data: formdata,
      headers: {
        version: isMobileApp() ? `rjsa ${apiVersion}` : `rjsw ${apiVersion}`
      }
    })
      .then((response) => {
        if (response.data) {
          console.log('REGISTRATION RESPONSE', response.data?.data);
          callback(response.data);
        }
      })
      .catch((err) => {
        console.log('error', err);
        callback(null);
      });
  };
};
//login/signup
//attribute/search?to=10&from=0&key=10&value=men
export const searchAttributeOptions = (attribute_id, searchTerm, callback) => {
  return async (dispatch) => {
    doctubeApiInstance
      .get(`attributenew/search?key=${attribute_id}&value=${searchTerm}`)
      .then((response) => {
        response?.data?.data?.length > 0 ? callback(response?.data?.data ?? null) : callback(null);
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        console.log('error', error);
        callback(null);
      });
  };
};
export const addVideo = (data, callback) => {
  return async (dispatch) => {
    let formdata = new FormData();
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const element = data[key];
        formdata.append(key, element);
      }
    }
    doctubeApiInstance
      .post('video/add', formdata)
      .then((response) => {
        callback(response?.data?.data ?? null);
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        console.log('error', error);
        callback(null);
      });
  };
};
