export const registerConstants = {
  GET_ALL_SPECIALITY_SUCCESS: 'GET_ALL_SPECIALITY_SUCCESS',
  GET_ALL_SPECIALITY_FALIURE: 'GET_ALL_SPECIALITY_FALIURE',

  REGISTRATION_SUCCESS: 'REGISTRATION_SUCCESS',
  REGISTRATION_FAILURE: 'REGISTRATION_FAILURE',

  AUTHENTICATION_SUCCESS: 'AUTHENTICATION_SUCCESS',
  AUTHENTICATION_FAILURE: 'AUTHENTICATION_FAILURE'
};
