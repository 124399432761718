import OneSignalReact from 'react-onesignal';

import { isApp, isMobileApp } from './common';
const environment = process.env.NODE_ENV;
const getAppIdByEnvironment = () => {
  switch (environment) {
    case '4':
      return {
        appId: process.env.REACT_APP_UAT_ONE_SIGNAL_APP_ID,
        safari_web_id: process.env.REACT_APP_UAT_ONE_SIGNAL_WEB_SAFARI_ID
      };
    case '5':
    default:
      return {
        appId: process.env.REACT_APP_LIVE_ONE_SIGNAL_APP_ID,
        safari_web_id: process.env.REACT_APP_LIVE_ONE_SIGNAL_WEB_SAFARI_ID
      };
  }
};
export const runOneSignal = (cb) => {
  const sub_path =
    window.location.pathname != '/' && window.location.pathname != '/index.html'
      ? window.location.pathname.substring(1)
      : '';
  OneSignalReact.init({
    ...getAppIdByEnvironment(),
    welcomeNotification: {
      title: "Welcome to CLIRNET - India's largest digital LIVE CME platform",
      message: 'Thanks for subscribing. You will now get the latest updates from us',
      disable: false,
      url: process.env.REACT_APP_BASE_URL
    },
    serviceWorkerParam: { scope: sub_path ? `/${sub_path}push/` : '/push/' },
    serviceWorkerPath: sub_path
      ? `${sub_path}push/OneSignalSDKWorker.js`
      : 'push/OneSignalSDKWorker.js',
    // notificationClickHandlerMatch: 'origin',
    notificationClickHandlerAction: 'focus'
  })
    .then(() => {
      OneSignalReact.setDefaultNotificationUrl(process.env.REACT_APP_BASE_URL);
      cb && cb();
    })
    .catch((err) => {
      console.log('==>>', err);
    });
};
export const addEventListenerForSubscription = (cb) => {
  OneSignalReact.on('subscriptionChange', function (isSubscribed) {
    console.log("The user's subscription state is now:", isSubscribed);
    cb && cb(isSubscribed);
  });
};
export const addEventListenerForDisplay = (cb) => {
  OneSignalReact.on('notificationDisplay', function (event) {
    cb && cb(event);
  });
};
export const showOneSignalPrompt = () => {
  if (!isMobileApp()) {
    OneSignalReact.showSlidedownPrompt({ force: true });
  }
};
export const addListenerForOpenNotification = (cb) => {
  OneSignalReact.addListenerForNotificationOpened(function (data) {
    console.log('Received NotificationOpened:');
    console.log(data);
    cb && cb(data);
  });
};
export const getOneSignalPlayerId = () => {
  OneSignalReact.isPushNotificationsEnabled(function (isEnabled) {
    // OneSignalReact.addListenerForNotificationOpened()
    console.log('IS ENABLED', isEnabled);
    if (isEnabled) {
      // user has subscribed
      OneSignalReact.getUserId(function (userId) {
        console.log('player_id of the subscribed user is : ' + userId);
      });
    }
  });
};
