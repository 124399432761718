export const clinicalVideosConstant = {
  GET_ARCHIVE_VIDEO_LIST_SUCCESS: 'GET_ARCHIVE_VIDEO_LIST_SUCCESS',
  GET_ARCHIVE_VIDEO_LIST_FALIURE: 'GET_ARCHIVE_VIDEO_LIST_FALIURE',

  GET_ARCHIVE_VIDEO_WITH_SPECIALITIES_SUCCESS: 'GET_ARCHIVE_VIDEO_WITH_SPECIALITIES_SUCCESS',
  GET_ARCHIVE_VIDEO_WITH_SPECIALITIES_FALIURE: 'GET_ARCHIVE_VIDEO_WITH_SPECIALITIES_FALIURE',

  GET_DETAILS_SUCCESS: 'GET_DETAILS_SUCCESS',
  GET_DETAILS_FAILURE: 'GET_DETAILS_FAILURE',
  GET_RELATED_VIDEO_LIST_SUCCESS: 'GET_RELATED_VIDEO_LIST_SUCCESS',
  GET_RELATED_VIDEO_LIST_FAILURE: 'GET_RELATED_VIDEO_LIST_FAILURE'
};
