import { appTourConstants } from '../constants';
let initialState = {
  app_tour_modal_status: false,
  app_tour_data: null,
  isViewTourClicked: false
};

export const appTourReducer = (state = initialState, action) => {
  switch (action.type) {
    case appTourConstants.GET_APP_TOUR_DATA_SUCCESS:
      return {
        ...state,
        app_tour_data: action.payload.data
      };
    case appTourConstants.GET_APP_TOUR_DATA_FALIURE:
      return {
        ...state,
        app_tour_data: []
      };
    case appTourConstants.TOOGLE_APP_TOUR_MODAL_VIEW:
      return {
        ...state,
        app_tour_modal_status: true,
        isViewTourClicked: action.payload
      };
    case appTourConstants.CLOSE_APP_TOUR_MODAL:
      return {
        ...state,
        app_tour_modal_status: false
      };
    case appTourConstants.RESET_APP_STORE:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
