import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import TagManager from 'react-gtm-module';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Bounce, ToastContainer, Zoom, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../src/assets/css/common/common.scss';
import '../src/assets/icons/font/flaticon_clirnet_v3_for_speciality.css';
import '../src/assets/sass/main.scss';
import { fetchAutomatedCtaInfo, getPlatformSpecialities, getUserDetails } from './Store/actions';
import { updateUserViewSpeciality } from './Store/actions/dashboard.action';
import { HelmetProvider } from 'react-helmet-async';

import {
  fetchUnreadCount,
  loginToCrm,
  readNotification
} from './Store/actions/notification.action';
import { changeBaseClass } from './Store/actions/settings.action';
import {
  generateUUID,
  getLocalStorage,
  isMobileApp,
  log,
  sanitizeLocalStorage,
  setSessionStorage
} from './common/common';
import {
  addEventListenerForDisplay,
  addEventListenerForSubscription,
  addListenerForOpenNotification,
  getOneSignalPlayerId,
  runOneSignal
} from './common/onesignal.utils';
import useOneSignalPlayerId from './components/customHooks/useOneSignalPlayerId';
import { FiWifi, FiWifiOff } from './components/icons';
// import { loadPolyfills } from './helper/pollyfill/loadPolyfills';
import IndexRouter from './router/IndexRouter';
import { getUserRegion } from './Store/actions/shareActions';
import { SplashScreen } from '@capacitor/splash-screen';

const queryClient = new QueryClient();

function MyApp() {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.common.user_details);
  const user_speciality_viewing = useSelector((state) => state.dashboard.user_speciality_viewing);
  const is_speciality_called = useSelector((state) => state.automatedCta.is_speciality_called);
  const automated_cta_called = useSelector((state) => state.automatedCta.automated_cta_called);
  const postPlayerId = useOneSignalPlayerId();
  const isLoggedInUser = useSelector((state) => state.login.isLogin);
  const current_menu = useSelector((state) => state.common.current_menu);
  const notifications = [];
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setSessionStorage('sessionId', generateUUID());
  }, []);

  const setBaseClass = (baseClass) => {
    dispatch(changeBaseClass(baseClass));
  };

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.innerHTML = `
  //     !(function (e, t, n, o, p, i, a) {
  //       e[o] ||
  //         (((p = e[o] =
  //           function () {
  //             p.process ? p.process.apply(p, arguments) : p.queue.push(arguments);
  //           }).queue = []),
  //         (p.t = +new Date()),
  //         ((i = t.createElement(n)).async = 1),
  //         (a = t.getElementsByTagName(n)[0]).parentNode.insertBefore(i, a));
  //     })(window, document, 'script', 'opix'),
  //     opix('init', '${process.env.REACT_APP_OPIX_PROPERTY_ID}'),
  //     opix('event', 'pageload');
  //   `;
  //   document.body.appendChild(script);

  //   // Cleanup function to remove the script if needed
  //   return () => document.body.removeChild(script);
  // }, []);

  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTAG_ID,
    auth: 'GPNhQU9jmS2zt0ZE32b5pw',
    dataLayer: {
      userDetailsChild: getLocalStorage('user', {})
    }
  };
  TagManager.initialize(tagManagerArgs);

  useEffect(() => {
    dispatch(getUserRegion());
    const userDetailsJson = getLocalStorage('user', null);
    console.log('my user details app in local storage===>', userDetailsJson);
    if (userDetailsJson) {
      dispatch(getUserDetails(() => {}));
    }
  }, []);

  const hideAppSplashScreen = async () => {
    await SplashScreen.hide();
  };
  //use effect for menu selection
  useEffect(() => {
    // alert("location in app.js" + JSON.stringify(window.location, null, 2));
    if (isMobileApp()) {
      hideAppSplashScreen();
    }
    // loadPolyfills();
    log('User Login Status', isLoggedInUser);
    switch (current_menu) {
      case 'share':
        setBaseClass(
          isMobile ? 'w-100 mblscreen sharePageBody' : 'w-100 dskScreen desktopBody sharePageBody'
        );
        break;

      case 'login':
        setBaseClass(
          isMobile
            ? 'w-100 mblscreen sharePageBody loginPageBody'
            : 'w-100 dskScreen desktopBody sharePageBody loginPageBody'
        );
        break;

      case 'share_new':
      case '404':
      case 'session_waiting':
      case 'error':
        setBaseClass('w-100');
        break;
      default:
        setBaseClass(
          isMobile ? 'w-100 d-flex flex-column mblscreen' : 'w-100 dskScreen desktopBody'
        );
        break;
      // isMobile ? 'w-100 d-flex flex-column mblscreen' : 'w-100 dskScreen desktopBody'
    }
  }, [isLoggedInUser, current_menu]);

  // useEffect(()=>{
  //   alert('app'+ baseClass)

  // },[baseClass])

  const getUnreadCount = () => {
    dispatch(
      loginToCrm((res) => {
        const { access_token } = res;
        dispatch(fetchUnreadCount(access_token, (res) => {}));
      })
    );
  };

  const postReadNotification = (temp_id) => {
    if (notifications?.accessToken) {
      dispatch(readNotification(notifications?.accessToken, temp_id));
    } else {
      dispatch(
        loginToCrm((res) => {
          const { access_token } = res;
          dispatch(readNotification(access_token, temp_id));
        })
      );
    }
  };

  const handleOnline = async () => {
    // const { FiWifi } = await import('./components/icons/iconComponents');
    toast.success('Back Online', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: 'colored',
      transition: Bounce,
      icon: <FiWifi />
    });
    // setIsOnline(true);
  };

  const handleOffline = async () => {
    // const { FiWifiOff } = await import('./components/icons/iconComponents');
    toast.error('You are Offline', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: 'colored',
      transition: Bounce,
      icon: <FiWifiOff />
    });
    // toast.warn('You are Offline');
    // setIsOnline(false);
  };

  useEffect(() => {
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    if (!isMobileApp()) {
      runOneSignal(() => {
        console.log('one signal initiated successfully');
        getOneSignalPlayerId();
      });
    } else {
      console.log('one signal initiated app');
      // OneSignalInit()
    }

    if (!isMobileApp()) {
      addEventListenerForSubscription((isSubscribed) => {
        if (isSubscribed) {
          postPlayerId();
        }
      });
      addListenerForOpenNotification((data) => {
        console.log('DATA FROM NOTIFICATION', data);
        getUnreadCount();
        postReadNotification(data?.data?.temp_id);
      });
      addEventListenerForDisplay((e) => {
        getUnreadCount();
      });
      // document.addEventListener('message', function (event) {
      //   try {
      //     const { type, payload } = JSON.parse(event.data);
      //     console.log({ type, payload });
      //     if (type == 'ACCEPT_FILE') {
      //       dispatch(saveFileObject(payload));
      //     } else if (type == 'ACCEPT_FILE_CONSENT') {
      //       dispatch(saveFileConsent(payload));
      //     } else if (type == 'ACCEPT_FILE_PRESCRIPTION') {
      //       dispatch(saveFilePrescription(payload));
      //     }
      //   } catch (error) {
      //     console.log('Error', error);
      //   }
      // });
    }
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    const htmlElement = document.querySelector('html');
    const selectedSpeciality = getLocalStorage('selectedSpeciality', null);
    if (userDetails?.spec_dashboard_eligibility_status) {
      htmlElement.classList.remove('old-user-font-size');
      htmlElement.classList.add('speciality-user-font-size');
      if (userDetails?.specility_update_status == '1') {
        if (selectedSpeciality) {
          dispatch(updateUserViewSpeciality([selectedSpeciality]));
          document.body.setAttribute('data-bs-theme', selectedSpeciality.specialities_name);
        } else {
          const speciality = JSON.parse(userDetails?.speciality)[0];
          document.body.setAttribute('data-bs-theme', speciality.specialities_name);
        }
      } else {
        document.body.setAttribute('data-bs-theme', 'speciality');
      }
    } else {
      htmlElement.classList.remove('speciality-user-font-size');
      htmlElement.classList.add('old-user-font-size');
      document.body.setAttribute('data-bs-theme', 'All');
    }
  }, [userDetails]);

  console.log('my user details app.js===>', userDetails);
  console.log('my location in app==>', window.location.href);

  useEffect(() => {
    if (user_speciality_viewing.length > 0) {
      if (user_speciality_viewing[0].specialities_name) {
        document.body.setAttribute('data-bs-theme', user_speciality_viewing[0].specialities_name);
      }
    }
  }, [user_speciality_viewing]);

  useEffect(() => {
    if (isLoggedInUser) {
      !automated_cta_called && dispatch(fetchAutomatedCtaInfo((res) => {}));
      !is_speciality_called && dispatch(getPlatformSpecialities((res) => {}));
    }
  }, [isLoggedInUser]);

  // const handleRefresh = (event) => {
  //   console.log('handleRefresh ionic ==>');
  //   console.log('handleRefresh ionic event ==>', event);
  //   alert("handleRefresh" + JSON.stringify(event)        );
  //   setTimeout(() => {
  //     // Any calls to load data go here
  //     alert('impletement refresh');
  //     event.detail.complete();
  //   }, 2000);
  // }
  useEffect(() => {
    sanitizeLocalStorage();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <div className="App">
          <IndexRouter />
          {/* <SmartBanner /> */}
          <ToastContainer
            position="bottom-center"
            transition={Zoom}
            autoClose={3000}
            closeOnClick
            rtl={false}
            pauseOnHover
            limit={1}
          />
        </div>
      </HelmetProvider>
      {/* <OtaUpdateModal
        title={'Enjoy While Our App Is Updating!'}
        message={'Spreading happiness while your App updates itself'}
        fileUrl={
          'https://img-cdn.clirnet.com/background-graphics/update_h85kzpV5Ah.jpeg?updatedAt=1704463636346'
        }
        show={true}
        // handleClose={closeJokeModal}
      /> */}

      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}

export default MyApp;
