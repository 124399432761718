import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { getLocalStorage } from '../common/common';
import { useDispatch, useSelector } from 'react-redux';
import { loginFailure } from '../Store/actions/login.action';
const PublicRoute = ({ children }) => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.common.user_details);

  useEffect(() => {
    if (!getLocalStorage('isLoggedIn', false)) {
      dispatch(loginFailure());
    }
  }, []);

  return getLocalStorage('isLoggedIn', false) && userDetails ? (
    <Navigate to="/dashboard" state={{ from_login: true }} replace={true} />
  ) : (
    children
  );
};
export default PublicRoute;
