import { settingsConstant } from '../constants/settings.constants';
export const changeTheme = (payload) => {
  return {
    type: settingsConstant.CHAGE_THEME,
    payload
  };
};

export const changeRoute = (payload) => {
  return {
    type: settingsConstant.UPDATE_IS_SPECIALITY,
    payload
  };
};

export const changeBaseClass = (payload)=>{
  return {
    type: settingsConstant.CHANGE_BASE_CLASS,
    payload

  }
}
