export const shareConstants = {
  GET_CLINICAL_VIDEOS_SHARE_SUCCESS: 'GET_CLINICAL_VIDEOS_SHARE_SUCCESS',
  GET_CLINICAL_VIDEOS_SHARE_FALIURE: 'GET_CLINICAL_VIDEOS_SHARE_FALIURE',

  GET_SESSION_SHARE_SUCCESS: 'GET_SESSION_SHARE_SUCCESS',
  GET_SESSION_SHARE_FALIURE: 'GET_SESSION_SHARE_FALIURE',

  GET_MEDWIKI_SHARE_SUCCESS: 'GET_MEDWIKI_SHARE_SUCCESS',
  GET_MEDWIKI_SHARE_FALIURE: 'GET_MEDWIKI_SHARE_FALIURE',

  GET_CHANNEL_SHARE_SUCCESS: 'GET_CHANNEL_SHARE_SUCCESS',
  GET_CHANNEL_SHARE_FALIURE: 'GET_CHANNEL_SHARE_FALIURE',

  GET_GR_SHARE_SUCCESS: 'GET_GR_SHARE_SUCCESS',
  GET_GR_SHARE_FALIURE: 'GET_GR_SHARE_FALIURE',

  GET_SPQ_SHARE_SUCCESS: 'GET_SPQ_SHARE_SUCCESS',
  GET_SPQ_SHARE_FALIURE: 'GET_SPQ_SHARE_FALIURE',

  GET_EBOOK_SHARE_SUCCESS: 'GET_EBOOK_SHARE_SUCCESS',
  GET_EBOOK_SHARE_FALIURE: 'GET_EBOOK_SHARE_FALIURE',

  GET_OBSERVERSHIP_SHARE_SUCCESS: 'GET_OBSERVERSHIP_SHARE_SUCCESS',
  GET_OBSERVERSHIP_SHARE_FALIURE: 'GET_OBSERVERSHIP_SHARE_FALIURE'

};
