import { setSessionStorage } from '../../common/common';
import { GET_POSITION_LIST, REACHED_SUMMARY } from '../constants/election.constant';

let initialState = {
  positionList: [],
  votedPositionList: [],
  reachedSummary: false
};

export const electionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_POSITION_LIST:
      setSessionStorage('positionList', JSON.stringify(action.payload));
      return {
        ...state,
        positionList: action.payload
      };

    case REACHED_SUMMARY:
      return {
        ...state,
        reachedSummary: action.payload
      };
    default:
      return state;
  }
};
