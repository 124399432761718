import React from 'react';

export const MdOutlineStarRate = ({ customClass }) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 24 24"
    className={customClass}
  >
    <path fill="none" d="M0 0h24v24H0z"></path>
    <path d="M12 8.89l.94 3.11h2.82l-2.27 1.62.93 3.01L12 14.79l-2.42 1.84.93-3.01L8.24 12h2.82L12 8.89M12 2l-2.42 8H2l6.17 4.41L5.83 22 12 17.31 18.18 22l-2.35-7.59L22 10h-7.58L12 2z"></path>
  </svg>
);
export const BsCameraVideo = ({ customClass }) => (
  <i className={`flaticon-video-camera ${customClass}`}></i>
  // svg line-icon old
);
export const MdOutlineRecommend = ({ customClass }) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 24 24"
    className={customClass}
  >
    <path fill="none" d="M0 0h24v24H0z"></path>
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
    <path d="M17 10h-4.59l.58-3.41v-.2c-.01-.26-.12-.51-.3-.7L12 5l-4.6 5c-.27.26-.42.62-.4 1v5c0 1.1.9 2 2 2h5.5c.56.03 1.08-.29 1.3-.8l2.1-4.9c.08-.15.12-.33.1-.5V11c0-.55-.45-1-1-1z"></path>
  </svg>
);
export const MdOutlineMedicalServices = ({ customClass }) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 24 24"
    className={customClass}
  >
    <path fill="none" d="M0 0h24v24H0z"></path>
    <path d="M20 6h-4V4c0-1.1-.9-2-2-2h-4c-1.1 0-2 .9-2 2v2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zM10 4h4v2h-4V4zm10 16H4V8h16v12z"></path>
    <path d="M13 10h-2v3H8v2h3v3h2v-3h3v-2h-3z"></path>
  </svg>
);
export const BsGraphUp = ({ customClass }) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 16 16"
    className={customClass}
  >
    <path
      fillRule="evenodd"
      d="M0 0h1v15h15v1H0V0zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5z"
    ></path>
  </svg>
);
export const CgProfile = ({ customClass }) => (
  <svg
    stroke="currentColor"
    fill="none"
    strokeWidth="0"
    viewBox="0 0 24 24"
    className={customClass}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 9C16 11.2091 14.2091 13 12 13C9.79086 13 8 11.2091 8 9C8 6.79086 9.79086 5 12 5C14.2091 5 16 6.79086 16 9ZM14 9C14 10.1046 13.1046 11 12 11C10.8954 11 10 10.1046 10 9C10 7.89543 10.8954 7 12 7C13.1046 7 14 7.89543 14 9Z"
      fill="currentColor"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 12C3 14.0902 3.71255 16.014 4.90798 17.5417C6.55245 15.3889 9.14627 14 12.0645 14C14.9448 14 17.5092 15.3531 19.1565 17.4583C20.313 15.9443 21 14.0524 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12ZM12 21C9.84977 21 7.87565 20.2459 6.32767 18.9878C7.59352 17.1812 9.69106 16 12.0645 16C14.4084 16 16.4833 17.1521 17.7538 18.9209C16.1939 20.2191 14.1881 21 12 21Z"
      fill="currentColor"
    ></path>
  </svg>
);
export const TbCertificate = ({ customClass }) => (
  <svg
    stroke="currentColor"
    fill="none"
    strokeWidth="2"
    viewBox="0 0 24 24"
    className={customClass}
  >
    <desc></desc>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <circle cx="15" cy="15" r="3"></circle>
    <path d="M13 17.5v4.5l2 -1.5l2 1.5v-4.5"></path>
    <path d="M10 19h-5a2 2 0 0 1 -2 -2v-10c0 -1.1 .9 -2 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -1 1.73"></path>
    <line x1="6" y1="9" x2="18" y2="9"></line>
    <line x1="6" y1="12" x2="9" y2="12"></line>
    <line x1="6" y1="15" x2="8" y2="15"></line>
  </svg>
);
export const TbListDetails = ({ customClass }) => (
  <svg
    stroke="currentColor"
    fill="none"
    strokeWidth="2"
    viewBox="0 0 24 24"
    className={customClass}
  >
    <desc></desc>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M13 5h8"></path>
    <path d="M13 9h5"></path>
    <path d="M13 15h8"></path>
    <path d="M13 19h5"></path>
    <rect x="3" y="4" width="6" height="6" rx="1"></rect>
    <rect x="3" y="14" width="6" height="6" rx="1"></rect>
  </svg>
);
export const RiSlideshowLine = ({ customClass }) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 24 24"
    className={customClass}
  >
    <g>
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path d="M13 21v2h-2v-2H3a1 1 0 0 1-1-1V6h20v14a1 1 0 0 1-1 1h-8zm-9-2h16V8H4v11zm9-9h5v2h-5v-2zm0 4h5v2h-5v-2zm-4-4v3h3a3 3 0 1 1-3-3zM2 3h20v2H2V3z"></path>
    </g>
  </svg>
);
export const MdOutlineVolunteerActivism = ({ customClass }) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 24 24"
    className={customClass}
  >
    <path fill="none" d="M0 0h24v24H0z"></path>
    <path d="M16 13c3.09-2.81 6-5.44 6-7.7C22 3.45 20.55 2 18.7 2c-1.04 0-2.05.49-2.7 1.25C15.34 2.49 14.34 2 13.3 2 11.45 2 10 3.45 10 5.3c0 2.26 2.91 4.89 6 7.7zm-2.7-9c.44 0 .89.21 1.18.55L16 6.34l1.52-1.79c.29-.34.74-.55 1.18-.55.74 0 1.3.56 1.3 1.3 0 1.12-2.04 3.17-4 4.99-1.96-1.82-4-3.88-4-4.99 0-.74.56-1.3 1.3-1.3zM19 16h-2c0-1.2-.75-2.28-1.87-2.7L8.97 11H1v11h6v-1.44l7 1.94 8-2.5v-1c0-1.66-1.34-3-3-3zM3 20v-7h2v7H3zm10.97.41L7 18.48V13h1.61l5.82 2.17c.34.13.57.46.57.83 0 0-1.99-.05-2.3-.15l-2.38-.79-.63 1.9 2.38.79c.51.17 1.04.26 1.58.26H19c.39 0 .74.23.9.56l-5.93 1.84z"></path>
  </svg>
);
export const BiBuildings = ({ customClass }) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 24 24"
    className={customClass}
  >
    <path d="M19 2H9c-1.103 0-2 .897-2 2v6H5c-1.103 0-2 .897-2 2v9a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V4c0-1.103-.897-2-2-2zM5 12h6v8H5v-8zm14 8h-6v-8c0-1.103-.897-2-2-2H9V4h10v16z"></path>
    <path d="M11 6h2v2h-2zm4 0h2v2h-2zm0 4.031h2V12h-2zM15 14h2v2h-2zm-8 .001h2v2H7z"></path>
  </svg>
);
export const MdOutlineFeaturedPlayList = ({ customClass }) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 24 24"
    className={customClass}
  >
    <path fill="none" d="M0 0h24v24H0V0z"></path>
    <path d="M21 3H3c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H3V5h18v14zM5 10h9v2H5zm0-3h9v2H5z"></path>
  </svg>
);
export const GoGraph = ({ customClass }) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 16 16"
    className={customClass}
  >
    <path
      fillRule="evenodd"
      d="M16 14v1H0V0h1v14h15zM5 13H3V8h2v5zm4 0H7V3h2v10zm4 0h-2V6h2v7z"
    ></path>
  </svg>
);
export const MdOutlineQuiz = ({ customClass }) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 16 16"
    className={customClass}
  >
    <path
      fillRule="evenodd"
      d="M16 14v1H0V0h1v14h15zM5 13H3V8h2v5zm4 0H7V3h2v10zm4 0h-2V6h2v7z"
    ></path>
  </svg>
);
export const MdOutlineUpcoming = ({ customClass }) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 24 24"
    className={customClass}
  >
    <path fill="none" d="M0 0h24v24H0z"></path>
    <path d="M17.6 10.81L16.19 9.4l3.56-3.55 1.41 1.41c-.11.03-3.56 3.55-3.56 3.55zM13 3h-2v5h2V3zm-6.6 7.81L7.81 9.4 4.26 5.84 2.84 7.26c.11.03 3.56 3.55 3.56 3.55zM20 14h-3.42c-.77 1.76-2.54 3-4.58 3s-3.81-1.24-4.58-3H4v5h16v-5m0-2c1.1 0 2 .9 2 2v5c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2v-5c0-1.1.9-2 2-2h5c0 1.66 1.34 3 3 3s3-1.34 3-3h5z"></path>
  </svg>
);
export const RiReservedLine = ({ customClass }) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 24 24"
    className={customClass}
  >
    <g>
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path
        fillRule="nonzero"
        d="M13 15v4h3v2H8v-2h3v-4H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-7zm-8-2h14V5H5v8zm3-5h8v2H8V8z"
      ></path>
    </g>
  </svg>
);
export const MdOutlineVideoCall = ({ customClass }) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 24 24"
    className={customClass}
  >
    <path fill="none" d="M0 0h24v24H0V0z"></path>
    <path d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4zM15 16H5V8h10v8zm-6-1h2v-2h2v-2h-2V9H9v2H7v2h2z"></path>
  </svg>
);
export const FaRegUserCircle = ({ customClass }) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 496 512"
    className={customClass}
  >
    <path d="M248 104c-53 0-96 43-96 96s43 96 96 96 96-43 96-96-43-96-96-96zm0 144c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm0-240C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-49.7 0-95.1-18.3-130.1-48.4 14.9-23 40.4-38.6 69.6-39.5 20.8 6.4 40.6 9.6 60.5 9.6s39.7-3.1 60.5-9.6c29.2 1 54.7 16.5 69.6 39.5-35 30.1-80.4 48.4-130.1 48.4zm162.7-84.1c-24.4-31.4-62.1-51.9-105.1-51.9-10.2 0-26 9.6-57.6 9.6-31.5 0-47.4-9.6-57.6-9.6-42.9 0-80.6 20.5-105.1 51.9C61.9 339.2 48 299.2 48 256c0-110.3 89.7-200 200-200s200 89.7 200 200c0 43.2-13.9 83.2-37.3 115.9z"></path>
  </svg>
);
export const BsCircle = ({ customClass }) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 16 16"
    className={customClass}
  >
    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
  </svg>
);
export const TbBadge = ({ customClass }) => (
  <svg
    stroke="currentColor"
    fill="none"
    strokeWidth="2"
    viewBox="0 0 24 24"
    className={customClass}
  >
    <desc></desc>
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M17 17v-13l-5 3l-5 -3v13l5 3z"></path>
  </svg>
);
export const RiBookmarkLine = ({ customClass }) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 24 24"
    className={customClass}
  >
    <g>
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path d="M5 2h14a1 1 0 0 1 1 1v19.143a.5.5 0 0 1-.766.424L12 18.03l-7.234 4.536A.5.5 0 0 1 4 22.143V3a1 1 0 0 1 1-1zm13 2H6v15.432l6-3.761 6 3.761V4z"></path>
    </g>
  </svg>
);
export const BsCardText = ({ customClass }) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 16 16"
    className={customClass}
  >
    <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"></path>
    <path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"></path>
  </svg>
);
export const BsArrowRight = ({ customClass }) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 16 16"
    className={customClass}
  >
    <path
      fillRule="evenodd"
      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
    ></path>
  </svg>
);
export const AiOutlineQuestionCircle = ({ customClass, id = '', gtmTagVariable = '' }) => (
  <i className={`flaticon-question ${customClass} ${gtmTagVariable}`} id={id}></i>
  // <svg
  //   id={id}
  //   stroke="currentColor"
  //   fill="currentColor"
  //   strokeWidth="0"
  //   viewBox="0 0 1024 1024"
  //   className={`${customClass} ${gtmTagVariable}`}
  // >
  //   <path
  //     className={gtmTagVariable}
  //     d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"
  //   ></path>
  //   <path
  //     className={gtmTagVariable}
  //     d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0 1 30.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1 0 80 0 40 40 0 1 0-80 0z"
  //   ></path>
  // </svg>
);
export const BsHeart = ({ customClass }) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 16 16"
    className={customClass}
  >
    <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"></path>
  </svg>
);
export const BsPencilSquare = ({ customClass }) => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 16 16"
    className={customClass}
  >
    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"></path>
    <path
      fillRule="evenodd"
      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
    ></path>
  </svg>
);
export const GrCertificate = ({ customClass }) => (
  <svg
    stroke="currentColor"
    fill="none"
    strokeWidth="0"
    viewBox="0 0 24 24"
    className={customClass}
  >
    <path
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      d="M15 19H2V1h16v4m0 0a5 5 0 110 10 5 5 0 010-10zm-3 9v8l3-2 3 2v-8M5 8h6m-6 3h5m-5 3h2M5 5h2"
    ></path>
  </svg>
);


export const ChangeSpeciality = ({ customClass }) => (
  <svg id="Glyph" height="512" viewBox="0 0 64 64" width="512" className={customClass}>
    <path d="m45 45.22v-4.44c-.0092-.80657-.717-1.02606-1.40006-1.0799a1.66119 1.66119 0 0 1 -1.05994-2.5701c.425-.49215.78913-1.20534.21993-1.75l-3.14-3.14c-.55408-.56206-1.2447-.21535-1.75.22a1.66491 1.66491 0 0 1 -2.57-1.0601c-.05541-.67047-.26693-1.39587-1.08008-1.3999h-4.43985a.99929.99929 0 0 0 -.99005.84l-.09.56a1.661 1.661 0 0 1 -2.57 1.06c-.49244-.42481-1.20506-.78918-1.75-.21987l-3.14 3.14a1.00154 1.00154 0 0 0 -.11 1.29 1.77065 1.77065 0 0 1 -1.29 3.11994.99938.99938 0 0 0 -.84.99v4.44c.00936.80727.717 1.02568 1.40006 1.08a1.64971 1.64971 0 0 1 1.05999 2.56993c-.426.49642-.78084 1.1914-.22 1.75l3.14 3.14a1.00162 1.00162 0 0 0 1.29.11l.46-.33a1.6649 1.6649 0 0 1 2.57 1.06c.05566.67165.26683 1.39538 1.08008 1.4h4.43992c.80683-.00908 1.026-.71724 1.08-1.40006a1.66116 1.66116 0 0 1 2.57-1.05994c.49266.42532 1.205.78891 1.75.21993l3.14-3.14c.55392-.55644.213-1.24984-.22009-1.75a1.658 1.658 0 0 1 1.06009-2.56993c.671-.05549 1.39578-.26707 1.4-1.08zm-13 3.28c-7.26779-.23477-7.26607-10.76636.00012-11 7.26766.23473 7.26596 10.76632-.00012 11z" />
    <path d="m4 47a3.00883 3.00883 0 0 0 3 3h11.96a2.91961 2.91961 0 0 1 .53-1.82 2.9793 2.9793 0 0 1 -2.49-2.96v-4.44a2.99634 2.99634 0 0 1 2.49-2.96 2.99574 2.99574 0 0 1 .36-3.88l3.12-3.11a3.023 3.023 0 0 1 3.85-.34 2.97929 2.97929 0 0 1 2.96-2.49h4.44a2.99636 2.99636 0 0 1 2.96 2.49 3.03362 3.03362 0 0 1 3.88.36l3.11 3.12a2.97438 2.97438 0 0 1 .34 3.85 2.97924 2.97924 0 0 1 2.49 2.96v4.44a2.99636 2.99636 0 0 1 -2.49 2.96 2.95632 2.95632 0 0 1 .53 1.82h11.96a3.00883 3.00883 0 0 0 3-3v-29h-56z" />
    <path d="m57 8h-50a3.00879 3.00879 0 0 0 -3 3v5h56v-5a3.00879 3.00879 0 0 0 -3-3zm-39 5h-8a1.00011 1.00011 0 0 1 0-2h8a1.00011 1.00011 0 0 1 0 2zm28 0a1.0002 1.0002 0 0 1 .00006-2 1.0002 1.0002 0 0 1 -.00006 2zm4 0a1.0002 1.0002 0 0 1 .00006-2 1.0002 1.0002 0 0 1 -.00006 2zm4 0a1.0002 1.0002 0 0 1 .00006-2 1.0002 1.0002 0 0 1 -.00006 2z" />
    <path d="m32 39.5a3.50022 3.50022 0 0 0 .00011 7 3.50022 3.50022 0 0 0 -.00011-7z" />
  </svg>
);

export const MenuTab = ({ customClass }) => (
  <svg
    id="Capa_1"
    enableBackground="new 0 0 512 512"
    height="512"
    viewBox="0 0 512 512"
    width="512"
    className={customClass}
  >
    <g id="Content_Manager_1_">
      <g>
        <path d="m120 345.022v63.956l47.959-31.978z" />
        <path d="m0 242v270h270v-270zm90 223.022v-176.044l132.041 88.022z" />
      </g>
      <path d="m302 182v330h210v-330zm150 270h-90v-30h90zm0-60h-90v-30h90zm0-60h-90v-30h90zm0-60h-90v-30h90z" />
      <g>
        <path d="m75.044 83.833 29.956 29.956 60-60 105 105v-158.789h-270v158.298z" />
        <path d="m165 96.211-60 60-30.044-30.044-74.956 74.387v9.446h270v-8.789z" />
      </g>
      <g>
        <path d="m512 150h-210v-150h210z" />
      </g>
    </g>
  </svg>
);

export const ExploreMenu = ({ customClass }) => (
  <svg height="512" viewBox="0 0 24 24" width="512" className={customClass}>
    <g id="Layer_2" data-name="Layer 2">
      <path d="m2.81 2.81c-1.42 1.41-1.42 6 0 7.38a5.58 5.58 0 0 0 3.69 1.06 5.58 5.58 0 0 0 3.69-1.06c1.42-1.41 1.42-6 0-7.38s-5.97-1.42-7.38 0z" />
      <path d="m2.81 13.81c-1.42 1.41-1.42 6 0 7.38a5.58 5.58 0 0 0 3.69 1.06 5.58 5.58 0 0 0 3.69-1.06c1.42-1.41 1.42-6 0-7.38s-5.97-1.42-7.38 0z" />
      <path d="m17.5 11.25a5.58 5.58 0 0 0 3.69-1.06c1.42-1.41 1.42-6 0-7.38s-6-1.42-7.38 0-1.42 6 0 7.38a5.58 5.58 0 0 0 3.69 1.06z" />
      <path d="m13.81 13.81c-1.42 1.41-1.42 6 0 7.38a5.58 5.58 0 0 0 3.69 1.06 5.58 5.58 0 0 0 3.69-1.06c1.42-1.41 1.42-6 0-7.38s-5.97-1.42-7.38 0z" />
    </g>
  </svg>
);