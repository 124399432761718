const reportWebVitals = (onPerfEntry) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals')
      .then(({ onCLS, onINP, onLCP, onTTFB, onFCP }) => {
        onCLS(onPerfEntry);
        onINP(onPerfEntry);
        onLCP(onPerfEntry);
        onTTFB(onPerfEntry);
        onFCP(onPerfEntry);
      })
      .catch((e) => console.log(e));
  }
};

export default reportWebVitals;
