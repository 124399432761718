import React, { useRef, useEffect, useState } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { Modal } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import './css/joyRide.scss';
import introDoc from './images/introDoc.png';
import { BsEmojiSmileFill, MdKeyboardArrowLeft, MdKeyboardArrowRight } from '../icons';
import {
  getCurrentTime,
  timeDifference,
  getSessionStorage,
  setSessionStorage,
  setLocalStorage,
  getLocalStorage
} from '../../common/common';
import ReactHtmlParser from 'react-html-parser';
import { useLocation } from 'react-router-dom';
import {
  getAppTourData,
  openModalStatus,
  submitAppTourAnalytics,
  resetAppTourReducer,
  closeModalStatus
} from '../../Store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { JoyrideIcons } from './JoyrideIcons';
import { settings } from './db.json';
import LazyImage from '../CustomLibraries/LazyImage';

let stepAnalysis = [];
let currentTime = '';
const JoyRide = () => {
  const dispatch = useDispatch();
  // const app_tour_data = {
  //   data: {
  //     tour_id: '14',
  //     steps: [
  //       {
  //         target: 'training_landing_featured',
  //         content: {
  //           icon: 'GrView',
  //           text: ' th', //landing hero card
  //           color: 'colorPurple'
  //         },
  //         placement: 'auto'
  //       },
  //       {
  //         target: 'training_Landing_featured_button',
  //         content: {
  //           icon: 'GrView',
  //           text: ' th', //landing hero card
  //           color: 'colorPurple'
  //         },
  //         placement: 'auto'
  //       },
  //       {
  //         target: 'training_landing_speciality',
  //         content: {
  //           icon: 'GrView', //specialitybox
  //           text: ' th',
  //           color: 'colorPurple'
  //         },
  //         placement: 'auto'
  //       },
  //       {
  //         target: 'training_landing_complete_card',
  //         content: {
  //           icon: 'GrView', //coursebox
  //           text: ' th',
  //           color: 'colorPurple'
  //         },
  //         placement: 'auto'
  //       },
  //       {
  //         target: 'training_landing_incomplete_card',
  //         content: {
  //           icon: 'GrView', //coursebox
  //           text: ' th',
  //           color: 'colorPurple'
  //         },
  //         placement: 'auto'
  //       },
  //       {
  //         target: 'training_landing_popular',
  //         content: {
  //           icon: 'GrView', //speciality content slider
  //           text: ' th',
  //           color: 'colorPurple'
  //         },
  //         placement: 'auto'
  //       },
  //       {
  //         target: 'training_landing_complete',
  //         content: {
  //           icon: 'GrView', //speciality content slider
  //           text: ' th',
  //           color: 'colorPurple'
  //         },
  //         placement: 'auto'
  //       },
  //       {
  //         target: 'training_landing_incomplete',
  //         content: {
  //           icon: 'GrView', //speciality content slider
  //           text: ' th',
  //           color: 'colorPurple'
  //         },
  //         placement: 'auto'
  //       }
  //     ],
  //     maxtoursession: '3'
  //   },
  //   user_status: '1'
  // };
  let app_tour_data = useSelector((state) => state.appTour.app_tour_data);
  let app_tour_modal_status = useSelector((state) => state.appTour.app_tour_modal_status);
  const isViewTourClicked = useSelector((state) => state.appTour.isViewTourClicked);
  const userDetails = useSelector((state) => state.common.user_details);

  let tourIdRef = useRef(0);
  const { pathname } = useLocation();
  const pageName = pathname.slice(1);
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);
  const [run, setRun] = useState(false);
  const [incData, setIncData] = useState([]);
  const [currentPageName, setCurrentPageName] = useState('');
  // const [currentTime, setCurrentTime] = useState('');
  const [tourId, setTourId] = useState(0);
  const view_count_on_current_session = () =>
    parseInt(getSessionStorage('view_count_on_current_session', 0));

  const reportTourStatus = (action = 0, step = [], tour_id = 0) => {
    let tempTourStatus = {
      tour_id: tour_id,
      user_view_step: step,
      action: action
    };
    //console.log('TEMP_TOUR_STATUS', tempTourStatus?.user_view_step?.length);
    tempTourStatus?.user_view_step?.length > 0 &&
      dispatch(
        submitAppTourAnalytics(tempTourStatus, (response) => {
          if (response != 'error') {
            //console.log('app tour data reported');
            setSessionStorage('view_count_on_current_session', view_count_on_current_session() + 1);
            stepAnalysis.length = 0;
          }
        })
      );
  };

  const setCurrentTime = (tempTime) => {
    currentTime = tempTime;
  };

  // page name chnage observer start
  useEffect(() => {
    const isSpeciality =
      userDetails &&
      userDetails?.spec_dashboard_eligibility_status &&
      userDetails?.specility_update_status === '1'
        ? true
        : false;

    if (!pageName?.toLowerCase()?.includes('share')) {
      //console.log('PAGE_NAME', pageName);
      if (pageName.toLowerCase()?.includes('dashboard')) {
        setCurrentPageName(!isSpeciality ? 'dashboard' : 'dashboard_speciality');
      } else if (pageName.toLowerCase() == 'medwiki') {
        setCurrentPageName(!isSpeciality ? 'comp' : 'comp_speciality');
      } else if (pageName.toLowerCase()?.includes('clinicalvideolanding')) {
        setCurrentPageName(!isSpeciality ? 'video_archive' : 'video_archive_speciality');
      } else if (pageName.toLowerCase()?.includes('sessions')) {
        setCurrentPageName(!isSpeciality ? 'sessions' : 'sessions_speciality');
      } else if (pageName.toLowerCase()?.includes('sessionsdetails')) {
        setCurrentPageName(!isSpeciality ? 'sessions_details' : 'sessions_details_speciality');
      } else if (pageName.toLowerCase()?.includes('spqlanding')) {
        setCurrentPageName(!isSpeciality ? 'survey' : 'survey_speciality');
      } else if (pageName.toLowerCase()?.includes('userprofile')) {
        setCurrentPageName(!isSpeciality ? 'profile' : 'profile_speciality');
      } else if (pageName.toLowerCase()?.includes('discussrefer')) {
        setCurrentPageName(!isSpeciality ? 'discussRefer' : 'discussRefer_speciality');
      } else if (pageName.toLowerCase()?.includes('telemed')) {
        setCurrentPageName(!isSpeciality ? 'telemed' : 'telemed_speciality');
      } else if (pageName.toLowerCase()?.includes('managechannel')) {
        setCurrentPageName(!isSpeciality ? 'manage_channel' : 'manage_channel_speciality');
      } else if (pageName.toLowerCase()?.includes('channel')) {
        setCurrentPageName(!isSpeciality ? 'channel' : 'channel_speciality');
      } else if (pageName.toLowerCase()?.includes('medwikidetails')) {
        setCurrentPageName(!isSpeciality ? 'comp_details' : 'comp_details_speciality');
      } else if (pageName.toLowerCase()?.includes('hospitalprofile')) {
        setCurrentPageName(!isSpeciality ? 'hospital_profile' : 'hospital_profile_speciality');
      } else if (pageName.toLowerCase()?.includes('spqdetails')) {
        setCurrentPageName(!isSpeciality ? 'survey_details' : 'survey_details_speciality');
      } else if (pageName.toLowerCase()?.includes('speakerprofile')) {
        setCurrentPageName(!isSpeciality ? 'speaker_profile' : 'speaker_profile_speciality');
      } else if (pageName.toLowerCase()?.includes('cliniacalvideosdetails')) {
        setCurrentPageName(
          !isSpeciality ? 'video_archive_details' : 'video_archive_details_speciality'
        );
      } else if (pageName.toLowerCase()?.includes('spqdetails')) {
        setCurrentPageName(!isSpeciality ? 'survey_details' : 'survey_details_speciality');
      } else if (pageName.toLowerCase()?.includes('ebook')) {
        setCurrentPageName(!isSpeciality ? 'ebook' : 'ebook_speciality');
      } else if (pageName.toLowerCase()?.includes('clinicalvideodetails')) {
        setCurrentPageName(
          !isSpeciality ? 'video_archive_details' : 'video_archive_details_speciality'
        );
      } else if (pageName.toLowerCase()?.includes('sessiondetails')) {
        setCurrentPageName(!isSpeciality ? 'session_details' : 'session_details_speciality');
      } else if (pageName.toLowerCase()?.includes('traininglanding')) {
        setCurrentPageName(!isSpeciality ? 'training_landing' : 'training_landing_speciality');
      } else if (pageName.toLowerCase()?.includes('trainingcontent')) {
        setCurrentPageName(!isSpeciality ? 'training_content' : 'training_content_speciality');
      } else {
        setCurrentPageName('');
      }
    } else {
      setCurrentPageName('');
    }
    //console.log('APP_TOUR_PAGE_NAME', pageName.toLocaleLowerCase()); //discussrefer

    return () => {
      dispatch(resetAppTourReducer());
    };
  }, [pageName]);

  const tourPlacementHandler = (event) => {
    switch (event) {
      case 'deskMenuToggle':
        return 'top-end';
      case 'search':
      case 'deskNavProfilePic':
      case 'reservedCmeDb':
      case 'exploreMoreTour':
      case 'dashboard_reserved_cme':
      case 'dashboard_reserved_cme_arrow':
      case 'dashboard_trending_live_cme_explore_more':
      case 'dashboard_trending_clinical_video_explore_more':
      case 'dashboard_trending_medwiki_explore_more':
      case 'dashboard_trending_quizzes_explore_more':
      case 'dashboard_trending_grand_rounds_explore_more':
      case 'medwiki_popular_box_view_all':
      case 'medwiki_popular_speciality_view_all':
      case 'medwiki_top_rated_box_view_all':
      case 'dashboard_profile':
      case 'dashboard_search':
      case 'medwiki_detail_popular_speciality_view_all':
      case 'discuss_refer_explore_more':
      case 'discuss_refer_activity_completed':
      case 'discuss_refer_activity_rejected':
      case 'channel_followed_manage':
      case 'training_landing_complete_card':
      case 'session_reserved_explore_more':
        return 'bottom-end';
      case 'cerificateBoxDb':
        return 'bottom-start';
      default:
        return 'auto';
    }
  };

  useEffect(() => {
    if (app_tour_data && pageName) {
      let { user_status } = app_tour_data;
      if (
        (user_status == 0 &&
          (view_count_on_current_session() < app_tour_data?.data?.maxtoursession ??
            settings.VIEW_PER_SESSION)) ||
        isViewTourClicked
      ) {
        let temp_steps = [];
        tourIdRef.current = app_tour_data?.data?.tour_id;
        setTourId(app_tour_data?.data?.tour_id);
        temp_steps = app_tour_data?.data?.steps?.map((response) => {
          return {
            disableBeacon: true,
            placement: 'auto',
            target: `#${response.target}`,
            spotlightPadding: 10,
            content: (
              <div className="tourContent_WithIcon">
                <div
                  className={`pulseBorder appTourIcon d-flex align-items-center justify-content-center ${response.content.color}`}
                >
                  {
                    JoyrideIcons.find((singleIcon) => singleIcon.name == response.content.icon)
                      ?.icon
                  }
                </div>
                {ReactHtmlParser(response.content.text)}
              </div>
            )
          };
        });
        setSteps(temp_steps);

        if (!isViewTourClicked) {
          dispatch(openModalStatus());
        }
      }
    }
  }, [app_tour_data, isViewTourClicked]);

  useEffect(() => {
    if (currentPageName) {
      dispatch(
        getAppTourData(currentPageName, (response) => {
          if (response !== 'error') {
          }
        })
      );
    }
    return () => {
      currentTime = '';
    };
  }, [currentPageName]);

  useEffect(() => {
    let tempCurrentTime = getCurrentTime();
    setCurrentTime(tempCurrentTime);
    if (run) {
      document.body.classList.add('appTourBody');
    } else {
      document.body.classList.remove('appTourBody');
    }
  }, [run]);

  useEffect(() => {
    let tempCurrentTime = getCurrentTime();
    setCurrentTime(tempCurrentTime);
  }, [stepIndex]);

  const pushToStepAnalysisArray = () => {
    let localData = {
      Start_Time: currentTime,
      End_Time: getCurrentTime(),
      Duration: timeDifference(currentTime),
      Current_steps: stepIndex,
      Remaining_steps: steps.length - stepIndex - 1
    };
    // //console.log('LOCAL DATA', localData);
    if (!stepAnalysis.some((_step) => _step.End_Time == localData.End_Time)) {
      stepAnalysis.push(localData);
    }

    console.group('step analysis');
    console.table('stapAnalysis', stepAnalysis);
    console.groupEnd('step analysis');
  };
  //joyride callback function
  const handleJoyrideCallback = (data) => {
    //console.log('CURRENT STEP', data);
    // currentTime = getCurrentTime();
    const { action, index, status, type } = data;

    if (action == ACTIONS.NEXT || action == ACTIONS.PREV) {
      // document.getElementsByClassName(app_tour_data.data.steps[index].target)[0].scrollIntoView();
      // document.getElementsByClassName('.' + app_tour_data.data.steps[index].target);
      //console.log('MOVE_TO_NEXT_PREV');
      pushToStepAnalysisArray();
    }

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      //if skip or finish
      setRun(false);
      window.scrollTo(0, 0);

      console.group('step analysis');
      console.table(stepAnalysis);
      console.groupEnd('step analysis');
      reportTourStatus(action === ACTIONS.SKIP ? 2 : 1, stepAnalysis, tourId);
    }
  };

  const buttonReset = {
    backgroundColor: '#eeeeee',
    width: '3.6rem',
    height: '3.6rem',
    border: 0,
    borderRadius: '1.8rem',
    color: '#080808',
    outline: 'none',
    lineHeight: 1,
    padding: '0 1rem',
    WebkitAppearance: 'none',
    fontSize: '1.4rem'
  };
  const spotlight = {
    borderRadius: '1rem',
    position: 'absolute'
  };

  const tooltipStyle = {
    tooltip: {
      backgroundColor: '#ffffff',
      borderRadius: '1rem',
      boxSizing: 'border-box',
      fontSize: '1.6rem',
      padding: isMobile ? '1.2rem' : '2rem',

      position: 'relative'
    },
    spotlight: {
      ...spotlight
    },
    spotlightLegacy: {
      ...spotlight
    },
    tooltipContainer: {
      textAlign: 'left'
    },
    tooltipTitle: {
      fontSize: 14,
      margin: '0 0 10px 0'
    },
    tooltipContent: {
      padding: isMobile ? '0 0 1rem' : '0 0 2rem'
    },
    tooltipFooter: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      marginTop: 0
    },
    buttonNext: {
      ...buttonReset
    },
    buttonBack: {
      ...buttonReset,
      marginLeft: 'auto',
      marginRight: '1rem'
    },
    buttonClose: {
      ...buttonReset,
      //color: options.textColor,
      height: 14,
      padding: 5,
      position: 'absolute',
      right: '5px',
      top: '5px',
      width: 14
    },
    buttonSkip: {
      ...buttonReset
    },
    options: {
      arrowColor: '#ffffff',
      arrowSize: 10,
      borderRadius: 10,
      backgroundColor: '#ffffff',
      overlayColor: 'rgba(0, 0, 0, 0.9)',
      primaryColor: '#F00',
      textColor: '#080808',
      spotlightShadow: '5px 5px 20px 0 rgba(0, 0, 0, 0.8)',
      zIndex: 9999
    }
  };

  const onTakeTourClick = () => {
    setRun(true);
    dispatch(closeModalStatus());
  };
  const onSkipClick = () => {
    setRun(false);
    reportTourStatus(2, [null], tourId);
    dispatch(closeModalStatus());
  };
  return (
    <>
      <Modal
        className="takeTourModal"
        size="lg"
        show={app_tour_modal_status}
        // show={true}
        centered
        onHide={onSkipClick}
      >
        <Modal.Body>
          <div className="row">
            <div className="col-sm-6 col-12 align-items-center justify-content-center d-flex mb-sm-0 mb-5">
              <div className="introPopLeft align-items-center justify-content-center d-flex">
                <img
                  src={
                    'https://img-cdn.clirnet.com/background-graphics/app_tour_background_W9jypLyTZ.svg'
                  }
                  className="introDoc"
                />
                {/* <div className="introPopAnimation"></div>
                <div className="introPopAnimation introPopAnimation2"></div> */}
              </div>
            </div>
            <div className="col-sm-6 col-12 align-items-center fs-16 text-black d-flex ps-sm-4 ps-0 text-center text-sm-start justify-content-center justify-content-sm-start">
              <div>
                <h1 className="text-black fw-bold fs-1 mb-sm-3 mb-2">
                  {/* <span className="text-secondary text-uppercase fw-medium">welcomes</span> */}
                  {app_tour_data?.data?.welcome_text ?? ''}
                </h1>
                <p>{app_tour_data?.data?.welcome_subtext ?? ''}</p>
                <div className="d-block mt-20">
                  <button
                    className=" fs-4 btn btn-light me-3"
                    type="button"
                    onClick={onSkipClick}
                    aria-label="Skip"
                  >
                    Skip
                  </button>
                  <button
                    className="fs-4 btn btn-primary"
                    type="button"
                    onClick={onTakeTourClick}
                    aria-label="Take the Tour"
                  >
                    Take the Tour
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Joyride
        scrollToFirstStep
        continuous={true}
        disableScrolling={false}
        showProgress={true}
        hideCloseButton={true}
        showSkipButton={true}
        disableOverlayClicks={true}
        locale={{
          back: <MdKeyboardArrowLeft customClass="fs-3" />,
          next: <MdKeyboardArrowRight customClass="fs-3 me-1" />,
          last: <BsEmojiSmileFill customClass="fs-3 me-2 text-secondary" />
        }}
        scrollDuration={10}
        spotlightPadding={0}
        steps={steps}
        styles={tooltipStyle}
        scrollOffset={isMobile ? 120 : 350}
        run={run}
        callback={handleJoyrideCallback}
      />
    </>
  );
};

export default JoyRide;
// import React from 'react';

// const JoyRide = () => {
//   return <div>JoyRide</div>;
// };

// export default JoyRide;
