import axios from 'axios';
import {
  log,
  setLocalStorage,
  removeLocalStorage,
  removeSessionStorage,
  setSessionStorage,
  getLocalStorage,
  isMobileApp
} from '../../common/common';
import { loginConstants } from '../constants/login.constant';
import packageJson from '../../../package.json';
import { commonConstants } from '../constants/commonConstants';
import { updateUserViewSpeciality } from './dashboard.action';
import { shareActions } from './shareActions';
import { shareConstants } from '../constants/shareConstants';
import { QueryClient } from 'react-query';
import { GET_GLOBAL_DATA } from '../../components/banner/apis/banner.apis';
import axiosInstance from '../helper/axiosInstance';
import axiosOpenInstance from '../helper/axiosOpenInstance';
const url = process.env.REACT_APP_API_URL;
const apiVersion = packageJson.version; //
const userDetailsSuccess = (payload) => {
  return {
    type: loginConstants.USER_DETAILS_SUCCESS,
    payload
  };
};
const userDetailsFailure = (payload) => {
  return {
    type: loginConstants.USER_DETAILS_FAILURE,
    payload
  };
};
export const loginSuccess = () => {
  return {
    type: loginConstants.LOGIN_SUCCESS
  };
};
export const loginFailure = (payload) => {
  return {
    type: loginConstants.LOGIN_FAILURE,
    payload
  };
};
export const logoutUser = () => {
  localStorage.clear();
  sessionStorage.clear();
  ///////////////////////////////////
  return {
    type: loginConstants.LOG_OUT
  };
};
// export const logoutFrom = () => {
//   return {
//     type: loginConstants.LOG_OUT_FROM,
//   };
// };
const fetchGlobalCampaign = ({ queryKey }) => {
  return axiosInstance.get(GET_GLOBAL_DATA);
};

export const authenticateUser = (token, callback, share_type = '') => {
  console.log('authenticateUser', share_type);
  return async (dispatch) => {
    axiosOpenInstance({
      method: 'GET',
      url: `${url}user/detail`,
      headers: {
        Authorization: token,
        version: isMobileApp() ? `rjsa ${apiVersion}` : `rjsw ${apiVersion}`
      }
    })
      .then(async (response) => {
        if (share_type != 'doctube') {
          dispatch(loginSuccess());
          setLocalStorage('isLoggedIn', true);
        }
        if (response.data) {
          callback(response.data);
          dispatch(userDetailsSuccess(response.data));
          // await QueryClient.prefetchQuery([GET_GLOBAL_DATA], fetchGlobalCampaign, {
          //   cacheTime: cacheTime,
          //   staleTime: staleTime,
          //   refetchOnWindowFocus: false
          // });
        }

        if (response?.data?.data) {
          const selectedSpeciality = getLocalStorage('selectedSpeciality', null);
          if (!selectedSpeciality) {
            if (parseInt(response?.data?.data?.master_user_type_id) != 1) {
              dispatch(
                updateUserViewSpeciality([
                  {
                    master_specialities_id: '0',
                    specialities_name: 'All',
                    icon: 'https://storage.googleapis.com/clirnet-crm-store/speciality_icon/all_speciality.svg',
                    status: '1'
                  }
                ])
              );
              setLocalStorage('selectedSpeciality', {
                master_specialities_id: '0',
                specialities_name: 'All',
                icon: 'https://storage.googleapis.com/clirnet-crm-store/speciality_icon/all_speciality.svg',
                status: '1'
              });
            }
          }
          dispatch(
            shareActions(
              'login',
              0,
              shareConstants.GET_SESSION_SHARE_SUCCESS,
              shareConstants.GET_SESSION_SHARE_FALIURE,
              '',
              '',
              response?.data?.data?.master_user_id,
              '',
              (response) => {
                console.log(response);

                if (response.status_code == 200) {
                  //   setLoader(true)
                }
              }
            )
          );

          dispatch({
            type: commonConstants.GET_USER_DETAILS_SUCCESS,
            payload: response?.data?.data
          });
        }
      })
      .catch((error) => {
        log('error', error);
        callback('error');
        dispatch(userDetailsFailure(error));
      });
  };
};
export const loginWithEmail = (formData, callback) => {
  return async (dispatch) => {
    axiosOpenInstance({
      method: 'POST',
      url: url + 'Authrjs/loginEmail',
      headers: {
        version: isMobileApp() ? `rjsa ${apiVersion}` : `rjsw ${apiVersion}`
      },
      data: formData
    })
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        log('error', error);
        callback('error');
        dispatch(loginFailure(error));
      });
  };
};
export const otpVerification = (formData, callback) => {
  return async (dispatch) => {
    axiosOpenInstance({
      method: 'POST',
      url: `${url}Authrjs/login`,
      headers: {
        version: isMobileApp() ? `rjsa ${apiVersion}` : `rjsw ${apiVersion}`
      },
      data: formData
    })
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        log('error', error);
        callback('error');
      });
  };
};

export const verifyTheOtp = (formData, callback) => {
  return async (dispatch) => {
    axiosOpenInstance({
      method: 'POST',
      url: `${url}Authrjs/loginotpverify`,
      headers: {
        version: isMobileApp() ? `rjsa ${apiVersion}` : `rjsw ${apiVersion}`
      },
      data: formData
    })
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
        // dispatch(loginSuccess(response.data));
      })
      .catch((error) => {
        log('Error', error);
        callback('error');
        dispatch(loginFailure(error));
      });
  };
};
export const getOtpViaCall = (formData, callback) => {
  return async (dispatch) => {
    axiosOpenInstance({
      method: 'POST',
      url: `${url}Authrjs/resendotp`,
      headers: {
        version: isMobileApp() ? `rjsa ${apiVersion}` : `rjsw ${apiVersion}`
      },
      data: formData
    })
      .then((response) => {
        if (response.data) callback(response.data);
      })
      .catch((error) => {
        log('error', error);
        callback('error');
      });
  };
};

export const checkEmail = (formData, callback) => {
  return async (dispatch) => {
    axiosOpenInstance({
      method: 'POST',
      url: url + 'Authrjs/Emailduplicate',
      headers: {
        version: isMobileApp() ? `rjsa ${apiVersion}` : `rjsw ${apiVersion}`
      },
      data: formData
    })
      .then((response) => {
        console.log(response.data);
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        log('error', error);
        callback('error');
      });
  };
};
//Authrjs/Phoneduplicate
export const checkMobileNumber = (formData, callback) => {
  return async (dispatch) => {
    axiosOpenInstance({
      method: 'POST',
      url: url + 'Authrjs/Phoneduplicate',
      headers: {
        version: isMobileApp() ? `rjsa ${apiVersion}` : `rjsw ${apiVersion}`
      },
      data: formData
    })
      .then((response) => {
        console.log(response.data);
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        log('error', error);
        callback(null);
      });
  };
};
export const phoneVerification = (formData, callback) => {
  return async (dispatch) => {
    axiosOpenInstance({
      method: 'POST',
      url: `${url}Authrjs/loginphoneverify`,
      headers: {
        version: isMobileApp() ? `rjsa ${apiVersion}` : `rjsw ${apiVersion}`
      },
      data: formData
    })
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        log('error', error);
        callback('error');
      });
  };
};

export const postResetPasswordRequest = (formData, callback) => {
  return async (dispatch) => {
    axiosOpenInstance({
      method: 'POST',
      url: url + 'resetpassword/request',
      headers: {
        version: isMobileApp() ? `rjsa ${apiVersion}` : `rjsw ${apiVersion}`
      },
      data: formData
    })
      .then((response) => {
        console.log(response.data);
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        log('error', error);
        callback('error');
      });
  };
};
export const validateSecretKey = (secretkey, callback) => {
  return async (dispatch) => {
    axiosOpenInstance({
      method: 'GET',
      url: url + 'resetpassword/checktime?token=' + secretkey,
      headers: {
        version: isMobileApp() ? `rjsa ${apiVersion}` : `rjsw ${apiVersion}`
      }
    })
      .then((response) => {
        console.log(response.data);
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        log('error', error);
        callback('error');
      });
  };
};
///resetpassword/update

export const updatePassword = (formData, callback) => {
  return async (dispatch) => {
    axiosOpenInstance({
      method: 'POST',
      url: url + 'resetpassword/update',
      headers: {
        version: isMobileApp() ? `rjsa ${apiVersion}` : `rjsw ${apiVersion}`
      },
      data: formData
    })
      .then((response) => {
        console.log(response.data);
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        log('error', error);
        callback('error');
      });
  };
};
