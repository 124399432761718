export const bannerConstants = {
  GET_EXCLUTION_LIST_DATA_FALIURE: 'GET_EXCLUTION_LIST_DATA_FALIURE',
  GET_EXCLUTION_LIST_DATA_SUCCESS: 'GET_EXCLUTION_LIST_DATA_SUCCESS',
  GET_BANNER_SETTING_FALIURE: 'GET_BANNER_SETTING_FALIURE',
  GET_BANNER_SETTING_SUCCESS: 'GET_BANNER_SETTING_SUCCESS',
  GET_BANNER_DATA_FALIURE: 'GET_BANNER_DATA_FALIURE',
  GET_BANNER_DATA_SUCCESS: 'GET_BANNER_DATA_SUCCESS',
  GET_CONTENT_BANNER_DATA_SUCCESS: 'GET_CONTENT_BANNER_DATA_SUCCESS',
  GET_CONTENT_BANNER_DATA_FALIURE: 'GET_CONTENT_BANNER_DATA_FALIURE',
  SYNC_LOCAL_DATA_WITH_STORE_SUCCESS: 'SYNC_LOCAL_DATA_WITH_STORE_SUCCESS',
  SYNC_LOCAL_DATA_WITH_STORE_FALIURE: 'SYNC_LOCAL_DATA_WITH_STORE_FALIURE',

  SYNC_BANNER_SETTINGS_WITH_STORE_SUCCESS: 'SYNC_BANNER_SETTINGS_WITH_STORE_SUCCESS',
  SYNC_BANNER_SETTINGS_WITH_STORE_FALIURE: 'SYNC_BANNER_SETTINGS_WITH_STORE_FALIURE',

  UPDATE_BANNER_REPORT: 'UPDATE_BANNER_REPORT',
  REPORT_BANNER_DATA_SUCCESS: 'REPORT_BANNER_DATA_SUCCESS',
  REPORT_BANNER_DATA_FALIURE: 'REPORT_BANNER_DATA_FALIURE',
  CLEAR_BANNER_REPORT_DATA: 'CLEAR_BANNER_REPORT_DATA',
  SYNC_EXCLUTION_LIST_WITH_STORE_SUCCESS: 'SYNC_EXCLUTION_LIST_WITH_STORE_SUCCESS',
  SYNC_EXCLUTION_LIST_WITH_STORE_FALIURE: 'SYNC_EXCLUTION_LIST_WITH_STORE_FALIURE',
  ADD_BANNER_CLICK_REPORT_SUCCESS: 'ADD_BANNER_CLICK_REPORT_SUCCESS',
  ADD_BANNER_CLICK_REPORT_FALIURE: 'ADD_BANNER_CLICK_REPORT_FALIURE',
  CLEAR_CONTENT_BANNER_DATA: 'CLEAR_CONTENT_BANNER_DATA',
  CLEAR_GLOBAL_BANNER_DATA: 'CLEAR_GLOBAL_BANNER_DATA',
  CONTENT_BANNER_DATA_FETCHED: 'CONTENT_BANNER_DATA_FETCHED',
  CLEAR_CONTENT_BANNER_DATA_FETCHED: 'CLEAR_CONTENT_BANNER_DATA_FETCHED',
  UPDATE_BANNER_CARD_INDEX: 'UPDATE_BANNER_CARD_INDEX',
  UPDATE_BANNER_DATA: 'UPDATE_BANNER_DATA',
  UPDATE_CURRENT_QUEUE: 'UPDATE_CURRENT_QUEUE',
  CLEAN_CURRENT_QUEUE: 'CLEAN_CURRENT_QUEUE',
  TOOGLE_INTERSTITAL_ADS: 'TOOGLE_INTERSTITAL_ADS',
  SHOW_INTERSTITAL_ADS: 'SHOW_INTERSTITAL_ADS',
  HIDE_INTERSTITAL_ADS: 'HIDE_INTERSTITAL_ADS',
  ENABLE_INTERSTITIAL_ADS: 'ENABLE_INTERSTITIAL_ADS',
  DISABLE_INTERSTITIAL_ADS: 'DISABLE_INTERSTITIAL_ADS',
  UPDATE_BANNER_SEQUENCE: 'UPDATE_BANNER_SEQUENCE',
  CAMPAIGN_PLAYED: 'CAMPAIGN_PLAYED',
  SET_CONSENT_TEXT: 'SET_CONSENT_TEXT',
  SET_CONSENT_STATUS: 'SET_CONSENT_STATUS',
  TOGGLE_CONSENT_MODAL: 'TOGGLE_CONSENT_MODAL',
  REMOVE_SPONSOR_ADS: 'REMOVE_SPONSOR_ADS',
  UPDATE_BANNER_FETCH_STATUS: 'UPDATE_BANNER_FETCH_STATUS',
  RESET_SPONSOR_ADS_SHOW_STATUS: 'RESET_SPONSOR_ADS_SHOW_STATUS'
};
