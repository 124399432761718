import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { FirebaseDynamicLinks } from '@pantrist/capacitor-firebase-dynamic-links';
import React, { useEffect, useRef, useState } from 'react';
import { json, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getLocalStorage, isMobileApp, openNewTab, setLocalStorage } from '../common/common';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { toast } from 'react-toastify';
import currentPackage from '../../package.json';
import ConfirmationModal from '../components/modals/ConfirmationModal';
import appConfig from '../config/config';
import { useDispatch } from 'react-redux';
import { validateAutoAuthToken } from '../Store/actions/autoAuthActions';
import OtaUpdateModal from '../components/modals/OtaUpdateModal';

const MobileNativeEventsListener = () => {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const apiVersion = currentPackage.version;
  const isAndroid = Capacitor.getPlatform() == 'android';
  const isIos = Capacitor.getPlatform() == 'ios';
  const [appUpdateTitle, setAppUpdateTitle] = useState('');
  const [openAppUpdateModal, setOpenAppUpdateModal] = useState(false);
  const maxUpdateCancelDelay = useRef(0);
  const myDownloadVersion = useRef('');
  const apiUrl = appConfig.apiLoc;
  const [openJokeModal, setOpenJokeModal] = useState(false);
  const [jokeMessage, setJokeMessage] = useState('');
  const [jokeUrl, setJokeUrl] = useState('');
  let OtaTimeOut = null;

  const onClose = () => {
    setOpenAppUpdateModal(false);
  };

  const closeJokeModal = () => {
    setOpenJokeModal(false);
  };

  const cancelClick = () => {
    const cancleCount = getLocalStorage('update_calcle_count', 0);
    if (+cancleCount >= 0 && cancleCount <= maxUpdateCancelDelay.current) {
      const newCount = cancleCount + 1;
      setLocalStorage('update_calcle_count', newCount);
      setOpenAppUpdateModal(false);
    } else {
      toast.warning('You Have To Update Your App');
    }
  };

  const confirmClick = () => {
    if (isAndroid) {
      // console.log("inside android redirect");
      let url = 'https://play.google.com/store/apps/details?id=app.clirnet.com.clirnetapp&pli=1';
      openNewTab(url, (e) => {
        console.log('error', e);
      });
    }
    if (isIos) {
      // console.log("inside ios redirect");
      let url = 'https://apps.apple.com/us/app/clirnet/id1450672654?ls=1';
      openNewTab(url, (e) => {
        console.log('error', e);
      });
    }
  };

  let supportedStartUrls = [
    'https://doctor.clirnet.com/index.html#/',

    'https://doctor.clirnet.com/#/',

    'https://doctor.clirnet.com/services/#/',

    'https://doctor.clirnet.com/services/index.html#/',

    'clirnet://'
  ];

  const getSubstringToReplace = (url) => {
    let substringToReplace = '';
    supportedStartUrls.forEach((element) => {
      if (url.startsWith(element)) {
        substringToReplace = element;
      }
    });
    return substringToReplace;
  };

  const removeSubstringFromURL = (url, substringToRemove) => {
    return url.replace(substringToRemove, '');
  };

  const checkAppVersion = async () => {
    if (process.env.REACT_APP_OTA_STATUS == '0') {
      return;
    }
    try {
      axios({
        baseURL: apiUrl,
        url: getLocalStorage('accessToken', '') ? 'settings/version' : 'openapi/version',
        method: 'GET',
        headers: {
          Authorization: getLocalStorage('accessToken', ''),
          version: isMobileApp() ? `rjsa ${apiVersion}` : `rjsw ${apiVersion}`
        }
      })
        .then(async (resp) => {
          let res = resp.data;
          console.log('my api response', JSON.stringify(res));
          setAppUpdateTitle(res.data.text);
          const appInfo = await App.getInfo();
          let currentAppVersion = appInfo?.version; // Play_Store / Apple_Store version
          maxUpdateCancelDelay.current = res.data.maxUpdateCount && res.data.maxUpdateCount; // Max cancle delay count for getting store update
          let supportedStoreVersionAndroid =
            res.data.supported_playstore_version && res.data.supported_playstore_version;
          let supportedStoreVersionIos =
            res.data.supported_applestore_version && res.data.supported_applestore_version;
          let joke_text = res.data.jokes_text && res.data.jokes_text;
          let joke_image = res.data.jokes_image && res.data.jokes_image;
          setJokeMessage(joke_text);
          setJokeUrl(joke_image);

          // play store update
          if (isAndroid && currentAppVersion < res.data.appVersionAndroid) {
            setOpenAppUpdateModal(true);
          }
          // apple store update
          if (isIos && currentAppVersion < res.data.appVersionIos) {
            setOpenAppUpdateModal(true);
          }

          // live app update android
          if (isAndroid) {
            if (
              currentPackage.version !== res.data.version &&
              currentAppVersion >= supportedStoreVersionAndroid
            ) {
              setOpenJokeModal(true);
              myDownloadVersion.current = await CapacitorUpdater.download({
                url: res.data.link,
                version: res.data.version
              });
            }
            if (
              myDownloadVersion.current &&
              currentPackage.version !== res.data.version &&
              currentAppVersion >= supportedStoreVersionAndroid
            ) {
              try {
                await CapacitorUpdater.set({ id: myDownloadVersion.current.id });
                CapacitorUpdater.notifyAppReady();
                setLocalStorage('ota_Update', true);
                setOpenJokeModal(false);
                if (OtaTimeOut) {
                  clearTimeout(OtaTimeOut);
                }
              } catch (err) {
                setOpenJokeModal(false);
                if (OtaTimeOut) {
                  clearTimeout(OtaTimeOut);
                }
              }
            }
          }

          // live app update ios
          if (isIos) {
            if (
              currentPackage.version !== res.data.versionIos &&
              currentAppVersion >= supportedStoreVersionIos
            ) {
              setOpenJokeModal(true);
              myDownloadVersion.current = await CapacitorUpdater.download({
                url: res.data.linkIos,
                version: res.data.versionIos
              });
            }
            if (
              myDownloadVersion.current &&
              currentPackage.version !== res.data.versionIos &&
              currentAppVersion >= supportedStoreVersionIos
            ) {
              try {
                await CapacitorUpdater.set({ id: myDownloadVersion.current.id });
                CapacitorUpdater.notifyAppReady();
                setLocalStorage('ota_Update', true);
                setOpenJokeModal(false);
                if (OtaTimeOut) {
                  clearTimeout(OtaTimeOut);
                }
              } catch (err) {
                setOpenJokeModal(false);
                if (OtaTimeOut) {
                  clearTimeout(OtaTimeOut);
                }
              }
            }
          }
        })
        .catch((e) => console.log(e));
    } catch (err) {
      // console.log("Something went wrong",err);
    }
  };

  const checkLinkToRedirectOta = () => {
    const Ota_Update = getLocalStorage('ota_Update', '');
    const Universal_Link = getLocalStorage('universalLinkApp', '');
    const Deep_link = getLocalStorage('deepLinkApp', '');

    if (Ota_Update == true) {
      if (Universal_Link) {
        setLocalStorage('ota_Update', false);
        setLocalStorage('universalLinkApp', '');
        navigate(Universal_Link);
      }
      if (Deep_link) {
        setLocalStorage('ota_Update', false);
        setLocalStorage('universalLinkApp', '');
        navigate(Deep_link);
      }
    }
  };

  const addCapacitorListeners = () => {
    // universal link listener for android
    App.addListener('appUrlOpen', (event) => {
      let url = event.url;
      // console.log("universal link inside app====>", url);
      if (isIos && url.includes('app.clirnet.com.clirnetapp')) {
        return false;
      } else {
        const IsDeepLinkUrl = url.includes('clirnet.page.link');
        const autoAuthUrl = url.includes('autoauth');
        if (autoAuthUrl) {
          setLocalStorage('autoAuthFromAppUrlListener', true);
        }
        if (!IsDeepLinkUrl) {
          let substringToRemove = getSubstringToReplace(url);
          const modifiedURL = removeSubstringFromURL(url, substringToRemove);
          setLocalStorage('universalLinkApp', modifiedURL);
          if (modifiedURL == 'https://doctor.clirnet.com/') {
            navigate(`/Dashboard`);
          } else {
            navigate(`/${modifiedURL}`);
          }
          setTimeout(() => {
            // console.log('inside universal link reload');
            window.location.reload();
          }, 0);
        }
      }
    });

    // deep link listener for android
    FirebaseDynamicLinks.addListener('deepLinkOpen', (data) => {
      // console.log("my event deepLinkOpen for FirebaseDynamicLinks==>", JSON.stringify(data));
      let url = data.url;
      let content_type = '';
      let content_id = '';
      let urlObject = new URL(url);
      const searchParams = urlObject.searchParams;
      let token = searchParams.get('tk');
      let utm = searchParams.get('utm');

      if (!token && !utm) {
        searchParams.forEach((value, key) => {
          if (!isNaN(value)) {
            content_type = key;
            content_id = value;
          }
        });
        let link = `/autoauth/${content_type}/${content_id}/0`;
        // console.log("my link",link);
        setLocalStorage('deepLinkApp', link);
        navigate(link);
        setTimeout(() => {
          // console.log('inside deep link reload if');
          window.location.reload();
        }, 10);
      } else if (!token && utm) {
        setLocalStorage('utm_source', utm);
        searchParams.forEach((value, key) => {
          if (!isNaN(value)) {
            content_type = key;
            content_id = value;
          }
        });
        let link = `/autoauth/${content_type}/${content_id}/${utm}`;
        // console.log("my link",link);
        setLocalStorage('deepLinkApp', link);
        navigate(link);
        setTimeout(() => {
          // console.log('inside deep link reload else if');
          window.location.reload();
        }, 10);
      } else if (token && utm) {
        setLocalStorage('utm_source', utm);
        searchParams.forEach((value, key) => {
          if (!isNaN(value)) {
            content_type = key;
            content_id = value;
          }
        });
        let decoded = jwtDecode(token);
        let userId = decoded?.userdetail.user_master_id;
        dispatch(
          validateAutoAuthToken(token, (response) => {
            if (response == 'error') {
              navigateToSharePage(content_type, content_id, utm);
            } else {
              let user_origin = response.data.origin;
              if (user_origin) {
                let link = `/autoauth/${content_type}/${content_id}/${utm}/${user_origin}/${userId}/${token}`;
                console.log('my link', link);
                setLocalStorage('deepLinkApp', link);
                navigate(link);
                setTimeout(() => {
                  // console.log('inside deep link reload else');
                  window.location.reload();
                }, 10);
              }
            }
          })
        );
      }
    });

    // Auto app update using CapGo
    checkAppVersion();

    checkLinkToRedirectOta();
  };

  function navigateToSharePage(content_type, content_id, utm_source) {
    if (
      content_type == 'session' ||
      content_type == 'medwiki' ||
      content_type == 'feed' ||
      content_type == 'archived_video' ||
      content_type == 'video_archive' ||
      content_type == 'survey' ||
      content_type == 'gr' ||
      content_type == 'channel' ||
      content_type == 'connect' ||
      content_type == 'epub' ||
      content_type == 'training' ||
      content_type == 'mentor' ||
      content_type == 'observership' ||
      content_type == 'claim_mentorship'
    ) {
      navigate(`/share/${content_type}/${content_id}/${utm_source}`);
    } else if (content_type == 'profile') {
      navigate(`/share/${content_type}/${utm_source}`);
    } else {
      navigate(`/`);
    }

    // setTimeout(() => {
    //   window.location.reload();
    // }, 10);
  }

  const removedCapacitorListeners = () => {
    App.removeAllListeners();
  };

  useEffect(() => {
    // console.log('my location at url listener ===>', JSON.stringify(location));
    addCapacitorListeners();
    return () => {
      removedCapacitorListeners();
    };
  }, [location]);

  useEffect(() => {
    // console.log('my show data value ==>', openJokeModal);
    OtaTimeOut = setTimeout(() => {
      setOpenJokeModal(false);
    }, 15000);
    return () => {
      clearTimeout(OtaTimeOut);
    };
  }, [openJokeModal]);

  return (
    <>
      <ConfirmationModal
        title={'App Update'}
        message={appUpdateTitle}
        handleClose={onClose}
        open={openAppUpdateModal}
        onConfirm={confirmClick}
        onCancel={cancelClick}
      />
      <OtaUpdateModal
        title={'Updating...'}
        message={jokeMessage}
        fileUrl={jokeUrl}
        show={openJokeModal}
        handleClose={closeJokeModal}
      />
    </>
  );
};

export default MobileNativeEventsListener;
