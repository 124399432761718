import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Workbox } from 'workbox-window';
import App from './App';
import store from './Store/store';
import './index.css';
import reportWebVitals from './reportWebVitals';
import packageJSON from '../package.json';

if (isMobileApp()) {
  const { CapacitorUpdater } = require('@capgo/capacitor-updater');
  CapacitorUpdater?.notifyAppReady();
}

import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';
import packageJson from '../package.json';
import { getCurrentTime, getSessionStorage, getUtmSource, isMobileApp } from './common/common';

Sentry.init({
  release: packageJson.version,
  environment: process.env.NODE_ENV,
  dsn: 'https://0b1141fa1c76c4dfa96af3473db3ecda@o4505725323706368.ingest.sentry.io/4505725326917632',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    }),
    Sentry.replayIntegration()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['https://doctor.clirnet.com', 'https://uat-doctorgl.clirnet.com/'],
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: process.env.NODE_ENV === 'production'
});
// store.subscribe(() => {
//   console.log('store::', store.getState());
//   let storeState = store.getState();
// });
// const updateSW = registerSW({
//   onNeedRefresh() {},
//   onOfflineReady() {},
//   onRegisteredSW(swUrl, r) {}
// });

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals((e) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  fetch(process.env.REACT_APP_ANALYTICS_REPORTING_ENDPOINT, {
    method: 'POST',
    body: JSON.stringify({
      page: 'service_worker',
      page_id: '0',
      referrer: window.location.href,
      activity_events: [{ type: 'SERVICE_WORKER_WEB_VITALS', e }],
      start_time: getCurrentTime(),
      end_time: getCurrentTime(),
      utm: getUtmSource(),
      platform: 'service_worker',
      version: packageJSON.version,
      token: '0',
      network_info: '',
      type: 'SERVICE_WORKER_WEB_VITALS',
      sessionId: getSessionStorage('sessionId', 'null'),
      pageSessionId: ''
    })
  });
});

if (!isMobileApp()) {
  if ('serviceWorker' in navigator) {
    const wb = new Workbox('/service-worker.js');
    let registration;

    const showSkipWaitingPrompt = async (event) => {
      // Assuming the user accepted the update, set up a listener
      // that will reload the page as soon as the previously waiting
      // service worker has taken control.
      wb.addEventListener('controlling', () => {
        // At this point, reloading will ensure that the current
        // tab is loaded under the control of the new service worker.
        // Depending on your web app, you may want to auto-save or
        // persist transient state before triggering the reload.
        window.location.reload();
      });

      // When `event.wasWaitingBeforeRegister` is true, a previously
      // updated service worker is still waiting.
      // You may want to customize the UI prompt accordingly.

      let result = confirm('New version available, reload?');
      console.log('user has accepted the update', result);
      wb.messageSkipWaiting();
    };

    // Add an event listener to detect when the registered
    // service worker has installed but is waiting to activate.
    wb.addEventListener('waiting', (event) => {
      showSkipWaitingPrompt(event);
    });

    wb.register()
      .then((reg) => {
        console.log('service worker registered', reg);
      })
      .catch((err) => {
        console.log('service worker registration failed', err);
      });
  }
}
