import { trackingConstant } from '../constants/tracking.constant';

const initialState = {
  activity_event: [],
  tracking_data: {},
  opix_data: []
};
export const trackingReducer = (state = initialState, action) => {
  switch (action.type) {
    case trackingConstant.OPIX_EVENT:
      if (action.payload) {
        return {
          ...state,
          opix_data: [...state.opix_data, action.payload]
        };
      }
    case trackingConstant.TRACK_EVENT:
      if (action.payload) {
        return {
          ...state,
          activity_event: [...state.activity_event, action.payload]
        };
      }

    case trackingConstant.ADD_TRACKING_DATA:
      return {
        ...state,
        tracking_data: action.payload
      };
    case trackingConstant.REMOVE_TRACKING_DATA:
      return { ...state, activity_event: [], tracking_data: {} };
    case trackingConstant.REMOVE_OPIX_DATA:
      return { ...state, opix_data: [] };
    default:
      return state;
  }
};
