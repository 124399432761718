export const medwikiConstants = {
  GET_MEDWIKI_LIST_SUCCESS: 'GET_MEDWIKI_LIST_SUCCESS',
  GET_MEDWIKI_LIST_FALIURE: 'GET_MEDWIKI_LIST_FALIURE',

  GET_MEDWIKI_LIST_WITH_VIDEO_SUCCESS: 'GET_MEDWIKI_LIST_WITH_VIDEO_SUCCESS',
  GET_MEDWIKI_LIST_WITH_VIDEO_FALIURE: 'GET_MEDWIKI_LIST_WITH_VIDEO_FALIURE',

  GET_MEDWIKI_LIST_WITH_SPECIALITIES_SUCCESS: 'GET_MEDWIKI_LIST_WITH_SPECIALITIES_SUCCESS',
  GET_MEDWIKI_LIST_WITH_SPECIALITIES_FALIURE: 'GET_MEDWIKI_LIST_WITH_SPECIALITIES_FALIURE',

  GET_ALL_SPECILAITIES_SUCCESS: 'GET_ALL_SPECILAITIES_SUCCESS',
  GET_ALL_SPECILAITIES_FALIURE: 'GET_ALL_SPECILAITIES_FALIURE',

  GET_MEWIKI_DETAIL_SUCCESS: 'GET_MEWIKI_DETAIL_SUCCESS',
  GET_MEWIKI_DETAIL_FALIURE: 'GET_MEWIKI_DETAIL_FALIURE',

  GET_RELATED_MEDWIKI_SUCCESS: 'GET_RELATED_MEDWIKI_SUCCESS',
  GET_RELATED_MEDWIKI_FALIURE: 'GET_RELATED_MEDWIKI_FALIURE'
};
