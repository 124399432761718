import React from 'react';
import {
  Navigate,
  Outlet,
  Route,
  createHashRouter,
  createRoutesFromElements
} from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';
import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';
import PublicRoute from '../components/PublicRoute';
import ShareRoute from '../components/ShareRoute';
import RootLayout from '../layout/RootLayout';
import { layoutedRoutes } from './routes/layoutedRoutes';
import { newPrivateRoute } from './routes/newPrivateRoute';
import { newRoutes } from './routes/newRoutes';
import { PrivateRoutes } from './routes/privateRoutes';
import { publicRoutes } from './routes/publicRoutes';
import { shareRoutes } from './routes/shareRoutes';
import { ErrorFallback } from '../pages/error';
import { neutralRoutes } from './routes/neutralRoutes';
import OpixWrapper from './OpixWrapper';
import LoaderLine from '../components/LoaderLine';
import AuthRouter from './AuthRouter';
import { autoAuthRoutes } from './routes/autoAuthRoutes';
export function useDefaultRouter() {
  const userDetails = useSelector((state) => state.common.user_details);

  const activeModules = {
    medwiki: true,
    spq: true,
    generic: true,
    clinicalVideo: true,
    session: true,
    election: true,
    doctube: true,
    epub: true,
    gr: true,
    dnr: true,
    courses: true,
    speciality: true,
    channel: true,
    telemed: true
  };

  return createHashRouter(
    createRoutesFromElements(
      <Route
        element={
          <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
            <OpixWrapper>
              <LoaderLine />
              <RootLayout />
            </OpixWrapper>
          </Sentry.ErrorBoundary>
        }
      >
        {publicRoutes.map((elem) => {
          if ('ShareRoute' in elem) {
            if ('subPath' in elem) {
              return (
                <Route
                  path={elem.path}
                  element={
                    <>
                      <LoaderLine />
                      <Outlet />
                    </>
                  }
                >
                  {elem.subPath &&
                    elem.subPath.map((key) => (
                      <Route
                        key={key}
                        path={key}
                        element={
                          <ShareRoute redirectTypeRoute={elem.redirectTypeRoute}>
                            {elem.component}
                          </ShareRoute>
                        }
                      />
                    ))}
                </Route>
              );
            } else {
              return (
                <Route
                  path={elem.path}
                  element={
                    <ShareRoute redirectTypeRoute={elem.redirectTypeRoute}>
                      <LoaderLine />
                      {elem.component}
                    </ShareRoute>
                  }
                />
              );
            }
          } else {
            if ('subPath' in elem) {
              return (
                <Route
                  exact
                  path={elem.path}
                  element={
                    <>
                      <LoaderLine />
                      <Outlet />
                    </>
                  }
                >
                  {elem.subPath &&
                    elem.subPath.map((subPath) => (
                      <Route
                        exact
                        path={subPath}
                        key={subPath}
                        element={<PublicRoute>{elem.component}</PublicRoute>}
                      />
                    ))}
                </Route>
              );
            } else {
              return (
                <Route
                  path={elem.path}
                  element={
                    <PublicRoute>
                      <LoaderLine />
                      {elem.component}
                    </PublicRoute>
                  }
                />
              );
            }
          }
        })}
        {neutralRoutes.map((elem) => {
          if ('subPath' in elem) {
            return (
              <Route exact path={elem.path}>
                {elem.subPath &&
                  elem.subPath.map((subPath) => (
                    <Route exact path={subPath} key={subPath} element={elem.component} />
                  ))}
              </Route>
            );
          } else {
            return <Route path={elem.path} element={elem.component} />;
          }
        })}
        {shareRoutes.map((elem) => {
          if ('subPath' in elem) {
            return (
              <Route
                path={elem.path}
                element={
                  <>
                    <LoaderLine />
                    <Outlet />
                  </>
                }
              >
                {elem.subPath &&
                  elem.subPath.map((subPath) => (
                    <Route
                      key={subPath}
                      path={subPath}
                      element={
                        <ShareRoute redirectTypeRoute={elem.redirectTypeRoute}>
                          {elem.component}
                        </ShareRoute>
                      }
                    />
                  ))}
              </Route>
            );
          } else {
            return (
              <Route
                path={elem.path}
                element={
                  <ShareRoute redirectTypeRoute={elem.redirectTypeRoute}>
                    <LoaderLine />
                    {elem.component}
                  </ShareRoute>
                }
              />
            );
          }
        })}
        {layoutedRoutes.map((elem) => {
          if ('subpath' in elem) {
            return (
              <Route
                key={elem.subpath[0].path}
                element={
                  userDetails?.spec_dashboard_eligibility_status
                    ? userDetails.specility_update_status !== '0'
                      ? elem.SpecialityLayout
                      : null
                    : elem.layout
                }
              >
                {elem.subpath &&
                  elem.subpath.map((item, index) => {
                    if (item.module in activeModules && !activeModules[item.module]) {
                      <Navigate to="/unavailable" />;
                      return <></>;
                    } else {
                      if ('paramPath' in item) {
                        return (
                          <Route
                            path={item.path}
                            key={item.path}
                            element={
                              <>
                                <LoaderLine />
                                <Outlet />
                              </>
                            }
                          >
                            {item.paramPath &&
                              item.paramPath.map((_elem, idx) => {
                                return (
                                  <Route
                                    path={_elem}
                                    key={_elem}
                                    element={
                                      <AuthRouter>
                                        {userDetails?.spec_dashboard_eligibility_status &&
                                        userDetails.specility_update_status !== '0' &&
                                        'specialityComponent' in item
                                          ? item.specialityComponent
                                          : item.component}
                                      </AuthRouter>
                                    }
                                  />
                                );
                              })}
                          </Route>
                        );
                      } else
                        return (
                          <Route
                            key={index}
                            path={item.path}
                            element={
                              <AuthRouter>
                                {userDetails?.spec_dashboard_eligibility_status &&
                                userDetails.specility_update_status !== '0' &&
                                'specialityComponent' in item
                                  ? item.specialityComponent
                                  : item.component}
                              </AuthRouter>
                            }
                          />
                        );
                    }
                  })}
              </Route>
            );
          }
        })}
        {newRoutes.map((elem) => {
          if ('subpath' in elem) {
            return (
              <Route
                element={
                  userDetails?.spec_dashboard_eligibility_status
                    ? userDetails.specility_update_status !== '0'
                      ? elem.SpecialityLayout
                      : null
                    : elem.layout
                }
              >
                {elem.subpath &&
                  elem.subpath.map((item, index) => {
                    if (item.module in activeModules && !activeModules[item.module]) {
                      <Navigate to="/unavailable" />;
                      return <></>;
                    } else {
                      if ('paramPath' in item) {
                        return (
                          <Route path={item.path} key={item.path} element={<Outlet />}>
                            {item.paramPath &&
                              item.paramPath.map((_elem, idx) => {
                                return (
                                  <Route
                                    path={_elem}
                                    key={_elem}
                                    element={
                                      <AuthRouter>
                                        {userDetails?.spec_dashboard_eligibility_status &&
                                        userDetails.specility_update_status !== '0' &&
                                        'specialityComponent' in item
                                          ? item.specialityComponent
                                          : item.component}
                                      </AuthRouter>
                                    }
                                  />
                                );
                              })}
                          </Route>
                        );
                      } else
                        return (
                          <Route
                            key={index}
                            path={item.path}
                            element={
                              <AuthRouter>
                                {userDetails?.spec_dashboard_eligibility_status &&
                                userDetails.specility_update_status !== '0' &&
                                'specialityComponent' in item
                                  ? item.specialityComponent
                                  : item.component}
                              </AuthRouter>
                            }
                          />
                        );
                    }
                  })}
              </Route>
            );
          }
        })}

        {newPrivateRoute.map((elem) => {
          if ('ShareRoute' in elem) {
            if ('subPath' in elem) {
              return (
                <Route path={elem.path}>
                  {elem.subPath &&
                    elem.subPath.map((subPath) => (
                      <Route
                        path={subPath}
                        key={subPath}
                        element={
                          <ShareRoute redirectTypeRoute={elem.redirectTypeRoute}>
                            {elem.component}
                          </ShareRoute>
                        }
                      />
                    ))}
                </Route>
              );
            } else {
              return (
                <Route
                  path={elem.path}
                  element={
                    <ShareRoute redirectTypeRoute={elem.redirectTypeRoute}>
                      {elem.component}
                    </ShareRoute>
                  }
                />
              );
            }
          } else {
            if ('subPath' in elem) {
              return (
                <Route path={elem.path}>
                  {elem.subPath &&
                    elem.subPath.map((subPath) => (
                      <Route
                        path={subPath}
                        key={subPath}
                        element={<AuthRouter>{elem.component}</AuthRouter>}
                      />
                    ))}
                </Route>
              );
            } else {
              if ('paramPath' in elem) {
                return (
                  <Route path={elem.path} element={<Outlet />}>
                    {elem.paramPath &&
                      elem.paramPath.map((_elem, idx) => {
                        return (
                          <Route
                            path={_elem}
                            key={_elem}
                            element={
                              <AuthRouter>
                                {userDetails?.spec_dashboard_eligibility_status &&
                                userDetails.specility_update_status !== '0' &&
                                'specialityComponent' in elem
                                  ? elem.specialityComponent
                                  : elem.component}
                              </AuthRouter>
                            }
                          />
                        );
                      })}
                  </Route>
                );
              }

              return <Route path={elem.path} element={<AuthRouter>{elem.component}</AuthRouter>} />;
            }
          }
        })}
        {PrivateRoutes.map((elem) => {
          if ('ShareRoute' in elem) {
            if ('subPath' in elem) {
              return (
                <Route path={elem.path}>
                  {elem.subPath &&
                    elem.subPath.map((subPath) => (
                      <Route
                        path={subPath}
                        key={subPath}
                        element={
                          <ShareRoute redirectTypeRoute={elem.redirectTypeRoute}>
                            {elem.component}
                          </ShareRoute>
                        }
                      />
                    ))}
                </Route>
              );
            } else {
              return (
                <Route
                  path={elem.path}
                  element={
                    <ShareRoute redirectTypeRoute={elem.redirectTypeRoute}>
                      {elem.component}
                    </ShareRoute>
                  }
                />
              );
            }
          } else {
            if ('subPath' in elem) {
              return (
                <Route path={elem.path}>
                  {elem.subPath &&
                    elem.subPath.map((subPath) => (
                      <Route
                        path={subPath}
                        key={subPath}
                        element={<AuthRouter>{elem.component}</AuthRouter>}
                      />
                    ))}
                </Route>
              );
            } else {
              return <Route path={elem.path} element={<AuthRouter>{elem.component}</AuthRouter>} />;
            }
          }
        })}

        {/* autoauth url set */}

        {autoAuthRoutes.map((elem) => {
          if ('subPath' in elem) {
            return (
              <Route exact path={elem.path}>
                {elem.subPath &&
                  elem.subPath.map((subPath) => (
                    <Route
                      exact
                      path={subPath}
                      key={subPath}
                      element={<AuthRouter autoAuth={true}>{elem.component} </AuthRouter>}
                    />
                  ))}
              </Route>
            );
          } else {
            return (
              <Route
                path={elem.path}
                element={<AuthRouter autoAuth={true}>{elem.component}</AuthRouter>}
              />
            );
          }
        })}
      </Route>
    ),
    {
      basename: '/'
    }
  );
}
