export const grandRoundConstants = {
  GET_GRANDROUND_LIST_SUCCESS: 'GET_GRANDROUND_LIST_SUCCESS',
  GET_GRANDROUND_LIST_FAILURE: 'GET_GRANDROUND_LIST_FAILURE',

  GET_GRANDROUND_DETAILS_SUCCESS: 'GET_GRANDROUND_DETAILS_SUCCESS',
  GET_GRANDROUND_DETAILS_FAILURE: 'GET_GRANDROUND_DETAILS_FAILURE',

  GET_GRANDROUND_SESSION_SUCCESS: 'GET_GRANDROUND_SESSION_SUCCESS',
  GET_GRANDROUND_SESSION_FAILURE: 'GET_GRANDROUND_SESSION_FAILURE',

  GET_GRANDROUND_MEDWIKI_SUCCESS: 'GET_GRANDROUND_MEDWIKI_SUCCESS',
  GET_GRANDROUND_MEDWIKI_FAILURE: 'GET_GRANDROUND_MEDWIKI_FAILURE',

  GET_GRANDROUND_ARCHIVE_SUCCESS: 'GET_GRANDROUND_ARCHIVE_SUCCESS',
  GET_GRANDROUND_ARCHIVE_FAILURE: 'GET_GRANDROUND_ARCHIVE_FAILURE',

  GET_GRANDROUND_SURVEY_SUCCESS: 'GET_GRANDROUND_SURVEY_SUCCESS',
  GET_GRANDROUND_SURVEY_FAILURE: 'GET_GRANDROUND_SURVEY_FAILURE'
};
