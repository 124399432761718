export const discussReferConstants = {
  FETCH_LANDING_SUCCESS: 'FETCH_LANDING_SUCCESS',
  FETCH_LANDING_FAILURE: 'FETCH_LANDING_FAILURE',

  GET_HOSPITAL_PROFILE_SUCCESS: 'GET_HOSPITAL_PROFILE_SUCCESS',
  GET_HOSPITAL_PROFILE_FAILURE: 'GET_HOSPITAL_PROFILE_FAILURE',

  GET_RECOMMENDED_SUCCESS: 'GET_RECOMMENDED_SUCCESS',
  GET_RECOMMENDED_FAILURE: 'GET_RECOMMENDED_FAILURE',

  GET_ACTIVITIES_SUCCESS: 'GET_ACTIVITIES_SUCCESS',
  GET_ACTIVITIES_FAILURE: 'GET_ACTIVITIES_FAILURE'
};
