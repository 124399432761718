export const sessionConstants = {
  GET_SESSION_LIST_DATA_SUCCESS: 'GET_SESSION_LIST_DATA_SUCCESS',
  GET_SESSION_LIST_DATA_FALIURE: 'GET_SESSION_LIST_DATA_FALIURE',

  GET_SESSION_DETAILS_DATA_SUCCESS: 'GET_SESSION_DETAILS_DATA_SUCCESS',
  GET_SESSION_DETAILS_DATA_FALIURE: 'GET_SESSION_DETAILS_DATA_FALIURE',

  CLEAR_SESSION_LIST_DATA: 'CLEAR_SESSION_LIST_DATA',
  CLEAR_SESSION_DETAILS_DATA: 'CLEAR_SESSION_DETAILS_DATA',

  GET_UPCOMING_SESSION_DATA_SUCCESS: 'GET_UPCOMING_SESSION_DATA_SUCCESS',
  GET_UPCOMING_SESSION_DATA_FALIURE: 'GET_UPCOMING_SESSION_DATA_FALIURE',

  GET_RESERVED_SESSION_DATA_SUCCESS: 'GET_RESERVED_SESSION_DATA_SUCCESS',
  GET_RESERVED_SESSION_DATA_FALIURE: 'GET_RESERVED_SESSION_DATA_FALIURE',

  GET_FEATURED_SESSION_DATA_SUCCESS: 'GET_FEATURED_SESSION_DATA_SUCCESS',
  GET_FEATURED_SESSION_DATA_FALIURE: 'GET_FEATURED_SESSION_DATA_FALIURE',

  GET_RECENT_SESSION_DATA_SUCCESS: 'GET_RECENT_SESSION_DATA_SUCCESS',
  GET_RECENT_SESSION_DATA_FALIURE: 'GET_RECENT_SESSION_DATA_FALIURE',

  GET_FEATURED_MASTER_DOC_DATA_SUCCESS: 'GET_FEATURED_MASTER_DOC_DATA_SUCCESS',
  GET_FEATURED_MASTER_DOC_DATA_FALIURE: 'GET_FEATURED_MASTER_DOC_DATA_FALIURE',

  RESERVE_SESSION_SUCCESS: 'RESERVE_SESSION_SUCCESS',
  RESERVE_SESSION_FALIURE: 'RESERVE_SESSION_FALIURE',

  CANCEL_SESSION_SUCCESS: 'CANCEL_SESSION_SUCCESS',
  CANCEL_SESSION_FALIURE: 'CANCEL_SESSION_FALIURE',

  UPDATE_SESSION_DETAILS_DATA: 'UPDATE_SESSION_DETAILS_DATA',
  GET_DOCTOR_DATA_SUCCESS: 'GET_DOCTOR_DATA_SUCCESS',
  GET_DOCTOR_DATA_FALIURE: 'GET_DOCTOR_DATA_FALIURE',

  GET_UPCOMING_SESSIONS_BY_DOCTOR_SUCCESS: 'GET_UPCOMING_SESSIONS_BY_DOCTOR_SUCCESS',
  GET_UPCOMING_SESSIONS_BY_DOCTOR_FALIURE: 'GET_UPCOMING_SESSIONS_BY_DOCTOR_FALIURE',

  GET_PREVIOUS_SESSION_BY_DOCTOR_SUCCESS: 'GET_PREVIOUS_SESSION_BY_DOCTOR_SUCCESS',
  GET_PREVIOUS_SESSION_BY_DOCTOR_FALIURE: 'GET_PREVIOUS_SESSION_BY_DOCTOR_FALIURE',

  SET_REDIRECT_URL: 'SET_REDIRECT_URL'
};
